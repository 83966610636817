import {OngoingChange} from './OngoingChange.js';
import {_updateFragments} from './utilities.js';
import * as positions from '../positions.js';
import * as gained_ids from '../gained_ids.js';
import * as messages from '../messages';

export class ScaleChange extends OngoingChange {
  constructor(fragments, factor, point, timeout) {
    super(fragments, timeout);
    let self = this;
    self.diff = {};
    let changeId = 0;
    fragments.forEach(function(frag) {
      let id = frag.id;
      self.diff[id] = {};
      self.diff[id].x = 0;
      self.diff[id].y = 0;
      self.diff[id].scale = 1;
    });

    self.scale(factor, point);
  }
  getAccumulatedDiff(id) {
    if(!this.diff[id]) {
      if(gained_ids.has(id))
      {
        let temp_id = gained_ids.get(id);
        this.diff[id] = this.diff[temp_id];
      }
      else
      {
        let err = 'can not deal with undo stack, please report to developers.';
        messages.error(err);
        throw err;
      }
    }
    return this.diff[id];
  }
  progress(factor, point) {
    let self = this;
    self.scale(factor, point);
  }
  s_finalize() {
    super.s_finalize();
    let self = this;
    _updateFragments(this._fragments);
  }
  undo() {
    let self = this;
    this._fragments.forEach(function(frag) {
      let d = self.getAccumulatedDiff(frag.id);
      frag.setIndividualScale(frag.getIndividualScale() / d.scale);
      frag.x += d.x;
      frag.y += d.y;
    });
    _updateFragments(this._fragments);
  }
  redo() {
    let self = this;
    this._fragments.forEach(function(frag) {
      let d = self.getAccumulatedDiff(frag.id);
      frag.setIndividualScale(frag.getIndividualScale() * d.scale);
      frag.x -= d.x;
      frag.y -= d.y;
    });
    _updateFragments(this._fragments);
  }
  scale(factor, point) {
    this.resetTimer();
    let self = this;
    self._fragments.forEach(function(frag) {
      let diff = positions.scaleFragment(frag, factor, point);
      let d = self.getAccumulatedDiff(frag.id);
      d.x += diff.x;
      d.y += diff.y;
      d.scale *= factor;
    });
  }
}
