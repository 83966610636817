/*
You are reading the 'base_fragment' module

A fragment is any text or media (audio, video, image) that has a position in notas space
This module defines and administrates fragment functionalities that are common among multiple fragment types

But there are also common functionalities you will not find in this module but in the 'knots' module

Everything in nota that has some position in space is a 'knot', but not all 'knots' are fragments
For example a play/pause button attached to a video may be a knot

So: For most things that have to do with a fragments position go to the 'knots' module

*/
import {getFragment, getTypename} from './fragment_production.js';
import {recommendFunction, expectFunction} from '../utilities/utilities.js';
// needed for side effects>>>>>
import * as sketch from '../main.js';
import {renderer} from '../main.js';
// <<<<<needed for side effects
import * as positions from '../positions.js';
import * as changes from '../changes/utilities.js';
import * as settings from '../settings.js';
import * as login from '../api/login.js';
import * as api_space from '../api/space.js';
import * as api_fragments from '../api/fragments.js';
import * as selection from '../selection.js';
import * as messages from '../messages.js';
import * as ui from '../userinterface/ui.js';
import * as manipulate from '../manipulations.js';
import * as input from '../input.js';
import * as space from '../space.js';
import Knot from './knot.js';
import * as audio from '../audio.js';
import * as multiuser from '../multiuser.js';

// MONSTER
// This function is just there to show the limit of the module
// We call it a monster function because monsters are also demonstrating the limits of systems
export function whereIsTheFragmentPosition( ) {
  throw Error(`the x, y and scale of a fragment used to be in the 'base_fragment' module, but
  they were moved to the 'knots' module (learn more about 'knots' here: https://pre.nota.space/)`);
}

let Decimal = settings.Decimal;

/**
 * BaseFragment, the base class for all fragments
 */
export class BaseFragment extends Knot {
  userscriptEditorOverlay = null;
  /**
   * constructor not to be called directly; use fragment_production module
   * @param {processing sketch} p - The sketch object from p5js
   */
  constructor(p, x, y, type, data, persistent = false) {
    super(p, data.scale, x, y, data.width, data.height);
    if(this.constructor === BaseFragment) {
      console.error('BaseFragment constructor should not be called directly!');
    }
    // signals for userscripts
    // these signals are only true for one frame after an event has happened
    this.signals = {
      fragmentSetup: true,
      scriptSetup: false,
    };
    this.removed_from_space = false;
    this.clickListeners = [];
    this.namedClickListeners = {};
    this._synced = true;
    this.sx = 0;
    this.sy = 0;
    this.text = "";
    this._persistent = persistent;
    this.floating_z = -1;
    this.boxed = false;
    expectFunction('typeDraw', this);
    recommendFunction('typeClick', this);
    this.maxRes = settings.MAX_RESOLUTION;
    this.maxW = data.maxW || data.width;
    this.maxH = data.maxH || data.height;
    this.w = data.width; // TODO: find a way to save enteredW and enteredH
    this.h = data.height;
    this.tmpMem = {};
    this.userscript = {
      scriptSourceString: ''
    };
    this.hovered = false;
    this.mouseX = null;
    this.mouseY = null;
    // fixed flag for deactivating fragment interaction
    this.fixed = false;
    this.p = p;
    this._borderWidth = 30;
    this._alpha = 255;
    this._borderShown = true;
    this._hasBorder = false;
    this._hasImageTransparency = false;
    this._lastRelativeOsScreenArea = 0;
    this._selected = false;
    this.setPlaying(false);
    // text input for changing value
    this.inp = null;
    this.setReady(false);
    if(data.name) {
      this.name = data.name;
    }
    this.drawGray = false;

    this.url = data.url || '';
    if(BaseFragment.nextID === undefined) {
      BaseFragment.nextID = 0;
    }
    let id = -1;
    this.id = id;
  }

  getTypename() {
    return getTypename(this.constructor);
  }
  set synced(val) {
    this._synced = val;
  }
  get synced() {
    return this._synced;
  }
  getAlpha() {
    return this._alpha;
  }
  setAlpha(alpha) {
    this._alpha = alpha;
  }
  isReady() {
    return this._ready;
  }
  // This should only be done once, not on loading from database, etc.
  scaleFragmentToMax() {
    // calc size (images, videos must load first)
    let self = this;
    let curW = self.w;
    let curH = self.h;
    if(curW > self.maxW || curH > self.maxH) {
      let factor = Math.min(self.maxW / curW, self.maxH / curH);
      self._scale *= factor;
    }
    else if(curW < self.maxW && curH < self.maxH) {
      let factor = Math.min(self.maxW / curW, self.maxH / curH);
      self._scale *= factor;
    }
    //self.x += (self.maxW - self.w*self._scale) / 2;
    //self.y += (self.maxH - self.h*self._scale) / 2;
  }
  setReady(val) {
    let self = this;
    this._ready = val;
    self._borderShown = self._hasBorder;
  }
  typeIsPlaying() {
    return null;
  }
  isPlaying() {
    let playing = this.typeIsPlaying();
    if(playing !== null) {
      return playing && this._playing;
    }
    else {
      return this._playing;
    }
  }
  setPlaying(val) {
    this._playing = val;
  }
  isSmall() {
    return this._small;
  }
  download() {
    console.warn("download() not implemented for this fragment type: " + this.type);
  }
  setSmall(val) {
    if(val != this._small) {
      this._small = val;
      this.typeSetSmall(this._small);
    }
  }
  typeSetSmall() {
  }
  typePause() {
  }
  pause() {
    // dont try to pause if not ready
    if(!this.isReady()) {
      return;
    }
    if(this.isPlaying()) {
      this.typePause();
    }
  }
  typeLoop() {
  }
  loop() {
    // dont try to play if not ready
    if(!this.isReady()) {
      return;
    }
    if(!this.isPlaying()) {
      this.typeLoop();
    }
  }

  manageState(potentialHover) {
    if(potentialHover === undefined) {
      console.error("doTestHover is undefined");
    }
    // pre-calculate important numbers of high detail
    // due to expensive toNumber function of Decimal
    // and other non-performant calculations
    if(potentialHover) {
      this.hover(this.p.mouseX, this.p.mouseY);
    }
    else {
      this.hovered = false;
      this.isWithinBorder = false;
    }
    // reset steady every frame
    this.steady = false;

    this.typeManageState();

    this.callUserScript();
  }
  typeManageState() {
  }
  get scriptSourceString() {
    return this.userscript.scriptSourceString;
  }
  activateScript() {
    this.buildUserScript();
  }
  buildUserScript() {
    // only build userscript, if it should also
    // be called
    if(this.shouldBuildUserScript()) {
      try {
        this.tmpScriptCompileError = false;
        let fun = new Function(
          'fragment',
          'sketch',
          'modules',
          'signals',
          this.userscript.scriptSourceString
        );
        this.tmpScript = fun;
        this.warnedTmpScript = false;
      }
      catch(e) {
        console.warn("Syntax error in your temporary script:");
        console.warn(e);
        this.tmpScriptCompileError = true;
      }
    }
  }
  shouldBuildUserScript() {
    let userId = login.getCreds().id;
    let userscriptByCurrentUser = '' + this.userscriptAuthor === userId;
    let userscriptByStaff = this.userscriptByStaff;
    let userscriptTrusted = this.userscriptTrusted;

    let shouldBuild = userscriptByStaff === true;
    shouldBuild = shouldBuild || userscriptByCurrentUser === true;
    shouldBuild = shouldBuild || userscriptTrusted === true;
    // allow scripts when no one is logged in. this can be used to mess up
    // user settings and space positions kept in localStorage, though
    shouldBuild = shouldBuild || login.loggedIn() === false;
    return shouldBuild && !this.tmpScriptCompileError;
  }
  shouldExecuteUserScript() {
    return this.shouldBuildUserScript() && settings.USER_SCRIPTS_ACTIVE;
  }
  setTmpScript(sourceString, sync) {
    this.signals.scriptSetup = true;
    this.userscript.scriptSourceString = sourceString;
    this.tmpScriptCompileError = false;
    this.warnedTmpScript = false;
    this.buildUserScript();
    if(sync === true) {
      this.sync();
    }
  }
  setScriptAuthorToCurrectUser() {
    let userCreds = login.getCreds();
    this.userscriptAuthor = userCreds.id;
    // userCreds contain strings, so 'true' needs to converted to a boolean
    this.userscriptByStaff = userCreds.is_staff === 'true';
  }
  callUserScript() {
    if(this.shouldExecuteUserScript()) {
      if(typeof this.tmpScript !== 'function') {
        this.buildUserScript();
      }
      if(typeof this.tmpScript === 'function') {
        try {
          let modules = {
            messages: messages,
            positions: positions,
            changes: changes,
            settings: settings,
            selection: selection,
            ui: ui,
            api_fragments: api_fragments,
            main: sketch,
            input: input,
            manipulations: manipulate,
            _server_api_space: api_space,
            space: space,
            audio: audio,
          };
          this.tmpScript(this, sketch.renderer, modules, this.signals);
        }
        catch (e) {
          if(!this.warnedTmpScript) {
            console.warn("Execution error in your temporary script:");
            console.warn(e);
            this.warnedTmpScript = true;
          }
        }
        finally {
          // reset all signals
          for(const signal in this.signals) {
            this.signals[signal] = false;
          }
        }
      }
    }
  }
  typeDraw() {
    const rend = this.renderer;
    const w = this.w;
    const h = this.h;

    // draw border
    if(!this.synced) {
      this.renderer.noFill();
      this.renderer.stroke(settings.COLORS.WARN);
      this.renderer.strokeWeight(3 / this.screenScale());
      rend.rect(-7, -7, w+14, h+14);
    }
    if(this._piggy) {
      rend.strokeWeight(2 / this.screenScale());
      rend.stroke(settings.COLORS.BAG);
      rend.noFill();
      rend.rect(-4, -4, w+8, h+8);
    }
    // selected
    if(this._selected) {
      rend.strokeWeight(2 / this.screenScale());
      rend.stroke(settings.COLORS.FOREGROUND);
      rend.noFill();
      rend.rect(-2, -2, w+4, h+4);
    }
    // default border
    else if(this.borderShown()) {
      rend.strokeWeight(1 / this.screenScale());
      rend.stroke(settings.COLORS.WEAK_FOREGROUND);
      rend.noFill();
      rend.rect(0, 0, w, h);
    }
    // default background
    else if(this.borderShown()) {
      rend.noStroke();
      if(this.isReady()) {
        rend.fill(55, 55, 55, 100);
      }
      else {
        rend.fill(settings.COLORS.LOADING);
      }
      rend.rect(0, 0, w, h);
    }

    // performance ... dont draw small fragments
    if(this.red()) {
      this.drawPlaceholder(0, 0, w, h);
    }
    else {
      this.fragmentTypeDraw(0, 0, w, h);
    }
    if(this.warnedTmpScript && settings.getUserScriptRuntimeErrorDisplay()) {
      const spacing = 4;
      rend.stroke(220, 120, 0, 240);
      rend.noFill();
      rend.strokeWeight(4);
      rend.rect(
        -spacing, -spacing,
        w + 2 * spacing, h + 2 * spacing
      );
    }
    if(this.tmpScriptCompileError && settings.getUserScriptCompileErrorDisplay()) {
      const spacing = 4;
      rend.stroke(250, 0, 0, 240);
      rend.noFill();
      rend.strokeWeight(4);
      rend.rect(
        -spacing, -spacing,
        w + 2 * spacing, h + 2 * spacing
      );
    }
  }
  getImage() {
    if(this.typeGetImage) {
      return this.typeGetImage();
    }
    else {
      return null;
    }
  }
  setGray(drawGray) {
    this.drawGray = drawGray;
  }
  /**
   * pass in the string name of the cursor that
   * should be drawn, when fragment is hovered,
   * f.i. 'grab'
   * use p5js's cursor variables, if possible
   */
  setHoverCursor(hoverCursor) {
    this.cursorString = hoverCursor;
  }
  drawPlaceholder(x, y, w, h) {
    if(this.typeDrawRed) {
      this.typeDrawRed(x, y, w, h);
    }
    else {
      this.p.fill(settings.COLORS.LOADING);
      this.p.noStroke(255);
      this.p.rect(x, y, w, h);
    }
  }
  // the state which will prevent native drawing of fragment type
  // and show some placeholder (red box..)
  red() {
    return this.isOnScreen() &&
      (this.disabledOnScreen() || this.typeRed());
  }
  onRemove() {
    this.typeOnRemove();
  }
  typeOnRemove() {
  }
  typeRed() {
    return false;
  }
  typeDisabledOnScreen() {
    return false;
  }
  disabledOnScreen() {
    return this.typeDisabledOnScreen();
  }
  move(x, y) {
    throw Error('use positions to move fragment');
  }
  drag(pmouseX, pmouseY, mouseX, mouseY) {
    if(this.fixed) {
      this.grabbed = false;
      return false;
    }
    // change position
    else if(this.hovered) {
      this.grabbed = true;
    }
    else {
      this.grabbed = false;
    }
    return this.grabbed;
  }
  isTransparentAt(mouseX, mouseY) {
    if(this.typeTransparentAt !== undefined) {
      return this.typeTransparentAt(mouseX, mouseY);
    }
    else {
      return false;
    }
  }
  click() {
    const self = this;
    // if preventNotaDefault is true, the default typeClick function will be
    // omitted
    let preventNotaDefault = false;
    try {
      this.clickListeners.forEach(function(listener) {
        preventNotaDefault = preventNotaDefault || listener();
      });
      Object.keys(this.namedClickListeners).forEach(function(listenerName) {
        const listener = self.namedClickListeners[listenerName];
        preventNotaDefault = preventNotaDefault || listener();
      });
    }
    catch(exception) {
      this.warnedTmpScript = false;
      console.error(exception);
      messages.error(
        "Error in a click listener from user scripts. " +
        "Please check the browser's dev console for details."
      );
    }
    if(!preventNotaDefault) {
      this.typeClick();
    }
  }
  typeClick() {
  }
  typeWheel() {
  }
  wheel(dir) {
    this.typeWheel(dir);
  }

  borderShown() {
    return this._hasBorder || this._borderShown;
  }
  toggleBorderShown() {
    this._hasBorder = !this._hasBorder;
  }
  getUnscaledBorderWidth() {
    return Math.min(20, Math.max(this.h, this.w) * 0.2);
  }
  getUnscaledBorder() {
    var bx = this.x - this.getUnscaledBorderWidth();
    var by = this.y - this.getUnscaledBorderWidth();
    var bw = this.w + 2 * this.getUnscaledBorderWidth();
    var bh = this.h + 2 * this.getUnscaledBorderWidth();
    return {x: bx, y: by, w: bw, h: bh};
  }
  static checkWithin(x, y, thisx, thisy, w, h, rotation) {
    if(rotation === undefined) {
      alert("checkWithin error. check console.");
      console.error("error from BaseFragment.checkWithin(...)");
    }
    var l = thisx;
    var r = l + w;
    var t = thisy;
    var b = t + h;
    return x > l && x < r && y > t && y < b;
  }
  setSelected(val) {
    this._selected = val;
  }
  getSelected() {
    return this._selected;
  }

  sync() {
    if(this.canSync()) {
      api_fragments.update(this);
      multiuser.sendFragmentUpdatedInfoByIDs([this.id]);
    }
  }
  push() {
    if(this.canSync()) {
      changes._updateFragments([this]);
    }
  }
  get persistent() {
    return this._persistent && login.loggedIn();
  }
  set persistent(val) {
    this._persistent = val;
  }
  canSync() {
    return this.persistent && this.id !== undefined &&
      this.id !== null && this.id !== -1 &&
      login.loggedIn();
  }
  pushRes() {
    let self = this;
    if(
      !this.persistent ||
      self.sizeIsStored ||
      !login.loggedIn() ||
      this.isDeleted
    ) {
      return;
    }
    if(
      self.id !== undefined &&
      self.id !== -1
    ) {
      self.sizeIsStored = true;
      self.push();
    }
    else {
      // retry after short period to allow fragment
      // POST to finish
      setTimeout(function() {
        self.pushRes();
      }, 500);
    }
  }
  getPersistence() {
    // ('x', 'y', 'width', 'height', 'scale', 'content')

    let content = {};
    var persistence = {
      x: this.x, y: this.y, scale: this._scale, type: this.getTypename(), url: this.url,
      rotation: this.rotation,
      hasBorder: this._hasBorder, fixed: this.fixed, textSize: this.textSize,
      width: this.w, height: this.h, sx: this.sx, sy: this.sy, text: this.text,
      content: content, persistent: this.persistent, floating_z: this.floating_z,
      boxed: this.boxed, size_is_stored: this.sizeIsStored,
      created_by: this.created_by,
      created_by_staff: this.created_by_staff,
      userscript_author: this.userscriptAuthor,
      userscript_by_staff: this.userscriptByStaff,
      userscript_trusted: this.userscriptTrusted,
      removed_from_space: this.removed_from_space
    };
    // deep clone, to avoid shared script editing after copying fragment
    persistence.userscript = JSON.parse(JSON.stringify(this.userscript));
    if(
      this.filehashes !== '' && this.filehashes !== null &&
      this.filehashes !== undefined
    ) {
      persistence.file_hashes = this.filehashes;
    }
    if(this.name) {
      persistence.name = this.name;
    }
    return persistence;
  }

  static setFragmentData(fragment, persistence, initCall = false, sync=true) {
    // the 'data' part ...
    // all fragment constructors rely on data object
    // but when setting the values of an existing fragment,
    // these values also need to be set
    fragment.removed_from_fragment = persistence.removed_from_fragment;
    fragment.maxW = persistence.width;
    fragment.maxH = persistence.height;
    fragment.w = persistence.width;
    fragment.h = persistence.height;
    if(fragment.text !== persistence.text) {
      fragment.text = persistence.text;
      // TODO: store this in backend
      fragment.textSize = 60;
      if(fragment.type === 'text' || fragment.type === 'file') {
        fragment.buildTextSvg();
      }
    }
    fragment.url = persistence.url || '';
    // end datapart

    if(persistence.name) {
      fragment.name = persistence.name;
    }

    fragment.created = new Date(persistence.created);
    fragment.changed = new Date(persistence.changed);
    fragment.created_by = persistence.created_by;
    fragment.created_by_staff = persistence.created_by_staff;
    if(fragment.floating_z != persistence.floating_z) {
      fragment.floating_z = persistence.floating_z;
      if(!initCall) {
        positions.getAll().some((frag, idx) => {
          if(frag === fragment) {
            positions.getAll().splice(idx, 1);
            positions.insertFragment(fragment, sync);
            return true;
          }
        });
        return;
      }
    }
    fragment.id = persistence.id;
    if(persistence.file_hashes || persistence.filehashes) {
      fragment.filehashes = persistence.file_hashes || persistence.filehashes;
    }
    fragment.text = persistence.text;
    fragment.type = persistence.type;
    fragment.x = persistence.x;
    fragment.y = persistence.y;
    fragment._scale = persistence.scale;
    fragment.rotation = persistence.rotation;
    //fragment.w = persistence.w;
    //fragment.h = persistence.h;
    fragment.url = persistence.url;
    fragment.fixed = persistence.fixed || false;
    fragment.sx = persistence.sx || 0;
    fragment.sy = persistence.sy || 0;
    fragment.boxed = persistence.boxed || false;
    fragment.sizeIsStored = persistence.size_is_stored || false;
    // whether the fragment is supposed to be persistent beyond reload
    // the persistent property is a hack and not stored on the server,
    // but set in different places where fragment data are retrieved
    fragment.persistent = persistence.persistent || true;
    // if nothing has been stored, use the current default initialization
    
    fragment.userscript = persistence.userscript || fragment.userscript;
    fragment.userscriptAuthor = persistence.userscript_author;
    fragment.userscriptByStaff = persistence.userscript_by_staff;
    fragment.userscriptTrusted = persistence.userscript_trusted;
    fragment.setTmpScript(fragment.userscript.scriptSourceString, false);
    fragment.textSize = persistence.textSize || fragment.textSize;

    if(!isNaN(persistence.relative_rotation_point_x)) {
      fragment._relativeRotationPointX = persistence.relative_rotation_point_x;
    }
    if(!isNaN(persistence.relative_rotation_point_y)) {
      fragment._relativeRotationPointY = persistence.relative_rotation_point_y;
    }

    return fragment;
  }

  static restore(p, persistence) {
    var x = persistence.x;
    var y = persistence.y;
    var type = persistence.type;

    // handling legacy data for type
    if(type === 'IM') {
      persistence.type = 'image';
    }
    if(type === 'AU') {
      persistence.type = 'audio';
    }
    if(type === 'VI') {
      persistence.type = 'video';
    }
    if(type === 'TX') {
      persistence.type = 'text';
    }

    let data = {
      width: persistence.width, height: persistence.height,
      text: persistence.text, url: persistence.url,
      textSize: 60
    }
    var fragment = getFragment(type, x, y, data);

    fragment = BaseFragment.setFragmentData(fragment, persistence, true);
    return fragment;
  }
}
