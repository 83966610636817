import * as main from '../main.js';
import * as space from '../space.js';
import * as settings from '../settings.js';
import * as positions from '../positions.js';
import * as selection from '../selection.js';
import * as manipulate from '../manipulations.js';
import * as input from '../input.js';
import * as collision from '../utilities/collision.js';
import {Matrix} from '../utilities/matrix.js';

let Decimal = settings.Decimal;

export default class Knot {

  _superknot = null;
  _subknots = []

  _scale = null;

  _x = null;
  _y = null;
  _w = null;
  _h = null;

  // rotation, degrees
  rotation = 0;
  _relativeRotationPointX = 0.5;
  _relativeRotationPointY = 0.5;

  _transformationMatrix = new Matrix();

  _superknotScale = null;
  _individualScale = null;

  _screenX = null;
  _screenY = null;
  _screenW = null;
  _screenH = null;
  // the 4 points of the rectangle of  this knot on screen
  // starting at a=_screenX/_screenY, b, c, and d are the points
  // that follow clockwise
  _a = null;
  _b = null;
  _c = null;
  _d = null;

  _relativeRotationPointX = 0.5;
  _relativeRotationPointY = 0.5;

  _relativeScalePointX = 0.0;
  _relativeScalePointY = 0.0;

  // for temporary scripting
  _tempScaleW = 1; // renamed from w_scale TODO: user scripts (use getter/setter)
  _tempScaleH = 1; // renamed from h_scale TODO: user scripts (use getter/setter)
  _tempRelShiftX = 0; // renamed from x_drift TODO: user scripts (use getter/setter)
  _tempRelShiftY = 0; // renamed from y_drift TODO: user scripts (use getter/setter)

  tempX = 0; // TODO: check and remove
  tempY = 0; // TODO: check and remove

  _isOnScreen = null;

   tintOpacityAbsoluteMin = 30;
   tintOpacityAbsoluteMax = 255;
   tintFadeFramesCount = 5; // must never be 0!
   _tintStepLen = null;
   _tintOpacity = 0;
   _tintOpacityCurrentMax = 75;


  constructor(renderer, scale, x, y, w, h, superknot) {
    this.renderer = renderer;
    this.inputDriver = main.getSketch();
    this.x = x;
    this.y = y;
    this.w = w; // TODO: find a way to save enteredW and enteredH
    this.h = h;
    this._scale = scale || 1
    if(superknot) {
      this._superknot = superknot
    }
  }
  resetTransformationMatrix() {
    // this._transformationMatrix = null;
    // reset to identity transformation matrix
    this._transformationMatrix.set();
    this._transformationMatrix.isReset = true;
  }
  set transformationMatrix(matrix) {
    this._transformationMatrix = matrix;
  }
  get accumulatedSuperknotMatrix() {
    if(this._superknot) {
      return this._superknot.accumulatedTransformationMatrix;
    }
    else {
      return positions.getGlobalMatrix();
    }
  }
  get accumulatedTransformationMatrix() {
    return this.accumulatedSuperknotMatrix.mult(this.transformationMatrix);
  }
  get transformationMatrix() {
    if(this._transformationMatrix.isReset) {
      //TODO: should x_drift movement be based on temp_w ?
      const scale = this.getIndividualScale(true);
      const w = this.temp_w * scale;
      const h = this.temp_h * scale;
      const x = this.partial_x + w * this.x_drift;
      const y = this.partial_y + w * this.y_drift;
      this._transformationMatrix.translate(x, y);

      const rotation_point_x = this._relativeRotationPointX * w;
      const rotation_point_y = this._relativeRotationPointY * h;

      this._transformationMatrix.translate(rotation_point_x, rotation_point_y);
      this._transformationMatrix.rotate(this.rotation);
      this._transformationMatrix.translate(-rotation_point_x, -rotation_point_y);

      const scale_point_x = this._relativeScalePointX * this.w;
      const scale_point_y = this._relativeScalePointY * this.h;

      this._transformationMatrix.translate(scale_point_x, scale_point_y);
      this._transformationMatrix.axis_scale(this.w_scale, this.h_scale);
      this._transformationMatrix.scale(scale);
      this._transformationMatrix.translate(-scale_point_x, -scale_point_y);

      this._transformationMatrix.isReset=false;
    }
    return this._transformationMatrix;
  }
  get superknot_scale() {
    if(this._superknotScale === null) {
      if(this._superknot !== null) {
        this._superknotScale = this._superknot.getPartialScale();
      }
      else {
        this._superknotScale = 1;
      }
    }
    return this._superknotScale;
  }
  // getters / setters for fragment's horizontal position
  set x(val) {
    this._x = val;
    this.setChanged(true);
  }
  get superknot_x() {
    if(this._superknot !== null) {
      return this._superknot.x;
    }
    else {
      return 0;
    }
  }
  get partial_x() {
    return this._x;
  }
  get x() {
    return this._x + this.superknot_x;
  }
  get x_drift() {
    return this._tempRelShiftX;
  }
  set x_drift(val) {
    this._tempRelShiftX = val;
  }
  getTempX() {
    return this.x + this.getTempW() * this.x_drift;
  }
  // getters / setters for fragment's vertical position
  set y(val) {
    this._y = val;
    this.setChanged(true);
  }
  get superknot_y() {
    if(this._superknot !== null) {
      return this._superknot.y;
    }
    else {
      return 0;
    }
  }
  get partial_y() {
    return this._y;
  }
  get y() {
    return this._y + this.superknot_y;
  }
  get y_drift() {
    return this._tempRelShiftY;
  }
  set y_drift(val) {
    this._tempRelShiftY = val;
  }
  getTempY() {
    return this.y + this.getTempH() * this.y_drift;
  }
  // getters / setters for fragment's width
  set w(val) {
    if(val === 0) {
      console.warn("Fragment width set to 0");
    }
    this._w = val;
    this.setChanged(true);
  }
  get temp_w() {
    return this.w * this.tempScaleW;
  }
  get w() {
    return this._w;
  }
  get w_scale() {
    return this.tempScaleW; // TODO filter
  }
  set w_scale(val) {
    this.tempScaleW = val; //TODO filter
  }
  get h_scale() {
    return this.tempScaleH; // TODO filter
  }
  set h_scale(val) {
    this.tempScaleH = val; //TODO filter
  }
  get tempScaleW() {
    return this._tempScaleW;
  }
  set tempScaleW(val) {
    this._tempScaleW = val;
  }
  getTempW() {
    return this.w * this.tempScaleW * this.getIndividualScale();
  }
  // getters / setters for fragment's height
  set h(val) {
    if(val === 0) {
      console.warn("Fragment height set to 0");
    }
    this._h = val;
    this.setChanged(true);
  }
  get temp_h() {
    return this.h * this.tempScaleH;
  }
  get h() {
    return this._h;
  }
  get tempScaleH() {
    return this._tempScaleH;
  }
  set tempScaleH(val) {
    this._tempScaleH = val;
  }
  getTempH() {
    return this._h * this.tempScaleH * this.getIndividualScale();
  }
  screenX() {
    return this._screenX;
  }
  screenY() {
    return this._screenY;
  }
  screenW(scale) {
    return this._screenW;
  }
  screenH(scale) {
    return this._screenH;
  }
  // this is just a redirect
  getScale() {
    return this.screenScale(); // TODO make filter
  }
  getPartialScale() {
    return this._scale;
  }
  screenScale() { // renamed from getScale() TODO: user scripts
    return (
      positions.getGlobalScaleLowPrec() 
          * this.getPartialScale()
          * this.superknot_scale
    );
  }
  getIndividualScale(refresh) {
    if(this._individualScale === null || refresh) {
      this._individualScale = this._scale * this.superknot_scale;
    }
    return this._individualScale;
  }
  setScale(scale) {
    this.setIndividualScale(scale); // TODO make filter
  }
  setIndividualScale(scale) { // renamed from setScale(scale) TODO: user scripts
    for(let i = 0; i < this._subknots.length; i++) {
      //this.subknots[i].setIndividualScale(scale);
    }
    this._scale = scale;
  }
  /**
   * return this knot's vertices in clockwise order, starting with
   * the topmost vertex
   */
  getSortedVertices() {
    const verts = [this._a, this._b, this._c, this._d];
    let startIdx = 0;
    for(let i = 1; i < verts.length; i++) {
      if(verts[i].y < verts[startIdx].y) {
        startIdx = i;
      }
    }
    const sorted = [];
    for(let i = 0; i < verts.length; i++) {
      sorted.push(verts[(startIdx + i) % verts.length]);
    }
    return sorted;
  }
  /**
   * get the area of this knot's surface that is on screen,
   * unit is pixels.. the area should be used relatively to the
   * whole screen's area, such that the fraction will not change when
   * the screen's resolution changes.
   */
  getOnScreenArea() {
    // get polygon of on-screen surface
    const verts = this.getSortedVertices();
    const insideScreenVerts = collision.getInsideAreaVerts(
      verts, 0, 0, this.renderer.width, this.renderer.height
    );
    this.insideScreenVerts = insideScreenVerts;
    if(insideScreenVerts.length > 2) {
      // add all triangle areas that constitute the polygon's area,
      // building all triangles from the first vertex. this works cause
      // the polygon is concave.
      const refVert = insideScreenVerts[0];
      let area = 0;
      for(let i = 1; i < insideScreenVerts.length - 1; i++) {
        const vert2 = insideScreenVerts[i];
        const vert3 = insideScreenVerts[i + 1];
        // calc area using heron's formula
        const a = Math.sqrt(
          Math.pow(vert2.x - refVert.x, 2) +
          Math.pow(vert2.y - refVert.y, 2)
        );
        const b = Math.sqrt(
          Math.pow(vert3.x - vert2.x, 2) +
          Math.pow(vert3.y - vert2.y, 2)
        );
        const c = Math.sqrt(
          Math.pow(refVert.x - vert3.x, 2) +
          Math.pow(refVert.y - vert3.y, 2)
        );
        const s = Math.max(0, (a + b + c) / 2);
        area += Math.sqrt(Math.max(0,
          s * (s - a) * (s - b) * (s - c)
        ));
      }
      return area;
    }
    else {
      return 0;
    }
  }
  getRelativeOnScreenArea() {
    return this.getOnScreenArea() / main.getArea();
  }
  getArea() {
    return this.screenH() * this.screenW();
  }
  getRelativeArea() {
    return this.getArea() / main.getArea();
  }
  screenCenterX() {
    return (this.screenX() + 0.5 * this.screenW());
  }
  screenCenterY() {
    return (this.screenY() + 0.5 * this.screenH());
  }
  screenDist(x, y) {
    var dx = x - this.screenX()
    var dy = y - this.screenY()
    var d = Math.sqrt(dx * dx + dy * dy);
    return d;
  }
  screenCenterDist(x, y) {
    var dx = x - this.screenCenterX()
    var dy = y - this.screenCenterY()
    var d = Math.sqrt(dx * dx + dy * dy);
    return d;
  }

  draw(matrix) {
    this.resetTransformationMatrix();
    if(!this.isOnScreen()) {
      // dont do anything, if not on screen and not always active
      return;
    }
    let rend = this.renderer;

    for(let i = 0; i < this._subknots.length; i++) {
      this._subknots[i].draw(this.accumulatedTransformationMatrix);
    }

    this.renderer.push();
    this.accumulatedTransformationMatrix.apply(this.renderer);

    if(this.inp) {
      // TODO
      // only needs to be called on changes... but who cares right now?
      this.setLayout(this.inp);
      let textSize = this.textSize;
      // if textarea has scrollbar
      if(this.inp.elt.scrollHeight != this.inp.elt.offsetHeight) {
        // scroll bar
      }
      else {
      }
    }

    this.typeDraw(this.partial_x, this.partial_y, this.screenW(), this.screenH());

    this.calculateFadeProgress();
    if(this._tintStepLen !== null && !this._hasImageTransparency) {
      this.drawTintHighlight(rend, this.screenW(), this.screenH(), this._tintOpacity);
    }
    if(!space.isGrounded() && this.hovered) {
      this.drawHover();
    }

    this.renderer.resetMatrix();
    this.renderer.pop();
    /*
    // TODO remove this commented drawing of vertices
    // when the knots branch is merging

    if(!this.insideScreenVerts) {
      this.insideScreenVerts = [];
    }
    this.getOnScreenArea();
    this.insideScreenVerts.forEach((v, i)=>{
      let b = i / this.insideScreenVerts.length;
      main.getSketch().push();
      main.getSketch().textSize(20);
      main.getSketch().stroke(255);
      main.getSketch().fill(0, b*255, 0);
      main.getSketch().ellipse(v.x, v.y, 15, 15);
      main.getSketch().fill(0, 255, 0);
      main.getSketch().stroke(0, 255, 0);
      main.getSketch().text(''+i, v.x - 20, v.y);
      main.getSketch().text(''+i, v.x - 0, v.y-20);
      main.getSketch().text(''+i, v.x + 20, v.y);
      main.getSketch().text(''+i, v.x - 0, v.y+20);
      main.getSketch().pop();
    });
    main.getSketch().push();
    main.getSketch().textSize(40);
    main.getSketch().text(''+this.insideScreenVerts.length, 0, main.renderer.height - 30);
    main.getSketch().pop();
    */
  }

  drawHover() {
    let overridableCursor = this.selectHoverCursor();
    if(this.typeSelectHoverCursor) {
      this.typeSelectHoverCursor(overridableCursor);
    }
    if(this.typeDrawHover) {
      this.typeDrawHover();
    }
  }

  /**
   * Tint the fragment to indicate it is highlighted
   */
  drawTintHighlight(rend, w, h, tintOpacity) {
    rend.noStroke();
    const r = settings.COLORS.TINT[0];
    const g = settings.COLORS.TINT[1];
    const b = settings.COLORS.TINT[2];
    rend.fill(rend.color(r, g, b, tintOpacity));
    rend.rect(0, 0, this.w, this.h);
  }

  /**
   * returns overrideableCursor = false, if cursor should not be overridden by specific
   * cursor function
   * */
  selectHoverCursor() {
    if(input.onTool(settings.TOOLS.FRAGMENT) && !manipulate.isDraggingFragments()) {
      this.p.cursor("grab");
      return false;
    }
    else if(input.onTool(settings.TOOLS.COPY)) {
      this.p.cursor('copy');
      return false;
    }
    else if(input.onTool(settings.TOOLS.SELECT)) {
      this.p.cursor('crosshair');
      return false;
    }
    else if(input.onTool(settings.TOOLS.OVER)
        || input.onTool(settings.TOOLS.UNDER)
        || input.onTool(settings.TOOLS.SCRIPT)) {
      this.p.cursor('pointer');
      return false;
    }
    else if(input.onTool(settings.TOOLS.DOWNLOAD)) {
      this.p.cursor('pointer');
      return false;
    }
    else if(manipulate.isDraggingFragments()) {
      this.p.cursor('grabbing');
      return false;
    }
    else if(this.cursorString) {
      this.p.cursor(this.cursorString);
      return false;
    }
    return true;
  }

  // precalc first resets variables that may change every frame and
  // then calculates their values.
  // This is done, because the calculations are not performant and
  // some of those values are required more than once per frame.
  precalc(potentialHover) {
    this.resetTransformationMatrix();
    for(let i = 0; i < this._subknots.length; i++) {
      this._subknots[i].precalc(potentialHover);
    }
    this.precalced = false;
    const accumulatedMatrix = this.accumulatedTransformationMatrix;
    // the four points of the rectangle around this knot
    this._a = accumulatedMatrix.mult_point({
      x: 0,
      y: 0
    });
    this._b = accumulatedMatrix.mult_point({
      x: this.w,
      y: 0
    });
    this._c = accumulatedMatrix.mult_point({
      x: this.w,
      y: this.h
    });
    this._d = accumulatedMatrix.mult_point({
      x: 0,
      y: this.h
    });
    this._screenX = this._a.x;
    this._screenY = this._a.y;
    this._screenW = this.temp_w * this.screenScale();
    this._screenH = this.temp_h *this.screenScale();
    this._individualScale = null;
    this._superknotScale = null;
    this.calcOnScreen();
    this.calcSmall();
    if(potentialHover) {
      this.hover(this.renderer.mouseX, this.renderer.mouseY);
    }
    else {
      this.hovered = false;
      this.isWithinBorder = false;
    }
    this.precalced = true;
  }

  /**
   * check whether given x, y (screen coordinates)
   * are within this fragment
   */
  within(x, y) {
    // get global coordinates point
    const gp = this.accumulatedTransformationMatrix.inverse().mult_point({
      x: x,
      y: y
    });
    // left, right, top, bottom coordinates
    const l = 0;
    const r = l + this.w;
    const t = 0;
    const b = t + this.h;
    const within = gp.x > l && gp.x < r && gp.y > t && gp.y < b;
    return within;
  }

  typePointCollide(mouseX, mouseY) {
    return this.within(mouseX, mouseY);
  }
  pointCollide(mouseX, mouseY) {
    return this.typePointCollide(mouseX, mouseY);
  }

  hover(mouseX, mouseY) {
    if(!this.precalced) {
      this.mouseX = mouseX;
      this.mouseY = mouseY;
      this.hovered = this.pointCollide(mouseX, mouseY);
    }
    return this.hovered;
  }

  calcSmall() {
    if(!this.precalced) {
      let small = Math.abs(this.screenW()) < 3 && Math.abs(this.screenH()) < 3;
      small = small || this.typeCalcSmall();
      if(small) {
        this.setSmall(true);
      }
      else {
        this.setSmall(false);
      }
    }
    else {
      console.warn("calcSmall() called more than once");
    }
  }
  typeCalcSmall() {
    return false;
  }
  setSmall(val) {
    if(val != this._small) {
      this._small = val;
      this.typeSetSmall(this._small);
    }
  }
  typeSetSmall() {
  }

  isOnScreen() {
    return this._onScreen;
  }
  calcOnScreen() {
    if(!this.precalced) {
      const within = collision.rectPoly(
        [this._a, this._b, this._c, this._d],
        0, 0, this.renderer.width, this.renderer.height
      );
      this.setOnScreen(within);
    }
    else {
      console.warn("calcOnScreen more than once");
    }
  }
  setOnScreen(onScreen) {
    if(this._onScreen != onScreen) {
      if(onScreen) {
        this.movesOnScreen();
      }
      else {
        this.movesOffScreen();
      }
    }
    this._onScreen = onScreen;
  }
  movesOnScreen() {
    this.typeMovesOnScreen();
  }
  typeMovesOnScreen() {
  }
  movesOffScreen() {
    this.typeMovesOffScreen();
  }
  typeMovesOffScreen() {
  }

  // not related to position / size
  typeSetChanged(val) {
  }
  setChanged(val) {
    this.typeSetChanged(val);
  }

  calculateTintOpacity() {
    this._lastRelativeOsScreenArea = this.getRelativeOnScreenArea();
    const growth = Math.pow(this.getRelativeOnScreenArea(), 1/5); // sqrt
    return this.tintOpacityAbsoluteMax - (this.tintOpacityAbsoluteMax - this.tintOpacityAbsoluteMin) * growth;
  }

  /**
   * Checks if a fragment should be highlighted
   * 
   * @returns True if the fragment should be highlighted
   */
  showSteady() {
    let tool = input.onTool(settings.TOOLS.FRAGMENT)
      || input.onTool(settings.TOOLS.SELECT)
      || input.onTool(settings.TOOLS.COPY)
      || input.onTool(settings.TOOLS.OVER)
      || input.onTool(settings.TOOLS.UNDER)
      || input.onTool(settings.TOOLS.SCRIPT);
    let showSteady = tool && (this.steady || this.hovered);
    showSteady = showSteady && !selection.isPerformingSelection();
    return showSteady;
  }
  calculateFadeProgress() {
    if (this.showSteady()) {
      //restart the animation when size changed
      if(this._lastRelativeOsScreenArea !== this.getRelativeOnScreenArea()) {
        this._tintStepLen = null;
      }
      // highlight with fade in animation...
      if (this._tintStepLen === null || this._tintStepLen < 0) {
        this._tintOpacityCurrentMax = this.calculateTintOpacity();
        this._tintStepLen = Math.max(((this._tintOpacityCurrentMax - this._tintOpacity) / this.tintFadeFramesCount), 0);
      } 
      if (this._tintOpacity < this._tintOpacityCurrentMax) {
        this._tintOpacity += this._tintStepLen;
      }
      if (this._tintOpacity >= this._tintOpacityCurrentMax) {
        this._tintOpacity = this._tintOpacityCurrentMax;
      }
    } else {
      // ...and fade out.
      if (this._tintStepLen !== null) {
        if (this._tintStepLen >= 0) { // was still fading in
          this._tintOpacityCurrentMax = this.calculateTintOpacity();
          this._tintStepLen = -this._tintOpacity / this.tintFadeFramesCount; // start fading out
        }
        if (this._tintOpacity > 0) {
          this._tintOpacity += this._tintStepLen;
        }
        if (this._tintOpacity <= 0) {
          this._tintOpacity = 0;
          this._tintStepLen = null;
        }
      }
    }
  }
}
