import * as api from './api.js';
import * as space from './space.js';
import * as msg from '../messages.js';
import * as gained_ids from '../gained_ids.js';

let inProgress = 0;
let dirty = false;
export function addProgress() {
  inProgress += 1;
}
export function removeProgress() {
  inProgress -= 1;
}
export function syncError() {
  return dirty;
}
export function isPushed() {
  return inProgress === 0;
}

export async function get_list(fragments) {
  let fragment_ids = [];
  fragments.forEach(function(frag) {
    if(frag.canSync() && !frag.tempid) {
      fragment_ids.push(frag.id);
    }
  });
  return get_list_by_ids(fragment_ids);
}

export async function get_list_by_ids(fragment_ids) {
  if(!fragment_ids.length > 0) {
    return {};
  }
  try {
    let url = 'room/fragments/';
    // wrap the list in another list, for query parameters
    let query_params = {
      fragment_ids: fragment_ids,
      room_id: space.getSpaceId()
    };
    let res = await api.auth_get(url, query_params);
    if(res.res.ok) {
      let ret = {};
      let frags = res.json.fragments;
      frags.forEach(function(frag) {
        ret[frag.id] = frag;
      });
      return ret;
    }
    else {
      console.error(res.json || res.text);
      return {};
    }
  }
  catch(e) {
    //return updateErr(e);
    console.log(e);
  }
}

export async function del(fragments) {
  if(!fragments)
    return true;

  addProgress();

  function onSuccess() {
    removeProgress();
    return true
  }

  function onError(e) {
    console.error(e);
    dirty = true;
    msg.error('Could not save fragment(s). Check developer console of your browser.');
    return true;
  }
  let fragments_json = [];
  for(let i = 0; i < fragments.length; i++) {
    let f = fragments[i];
    if(f.tempid) {
      f.delete_after_gaining_id = true;
    } else {
      fragments_json.push({id: f.id});
    }
  }
  if (fragments_json.length > 0) {
    try {
      const url = 'room/fragment/remove_from_room/';
      const body = {
        fragments: fragments_json,
        space_id: space.getSpace().id
      };
      const res = await api.auth_delete_req(url, body);
      if (res.res.ok) {
        return onSuccess();
      } else {
        return onError(res.json || res.text);
      }
    } catch (e) {
      return onError(e);
    }
  }
  else {
    return true;
  }
}

export async function batch_update(fragments) {
  addProgress();
  function onSuccess() {
    removeProgress();
    return true
  }
  function onError(e) {
    dirty = true;
    console.error(e);
    msg.error('Could not save fragment(s). Check developer console of your browser.');
    return true;
  }
  let cur_space = space.getSpace();
  if (!cur_space || !cur_space.id) {
    return onError('Could not save fragment, because no space is loaded.');
  }
  const fragments_json = [];
  for(let i = 0; i < fragments.length; i++)
  {
    let f = fragments[i];
    if(f.tempid) {
      f.sync_after_gaining_id = true;
    } else {
      const json = f.getPersistence();
      json.id = f.id;
      fragments_json.push(json);
    }
  }
  if(fragments_json.length < 1) {
    return true;
  }

  const valid = fragments_json.every(fragment_json => validate(fragment_json));

  if(!valid)
    return;

  try {
    let res = await api.auth_put('room/fragments/', {fragments: fragments_json, room: cur_space.id});
    if(res.res.ok) {
      return onSuccess();
    } else {
      return onError(res.json || res.text);
    }
  }
  catch(e) {
    return onError(e);
  }
}

export async function update(fragment) {
  addProgress();
  function updateSuccess() {
    removeProgress();
    return true
  }
  function updateErr(e) {
    dirty = true;
    msg.error('Could not save fragment(s). Check developer console of your browser.');
    console.error(e);
    return true;
  }

  if(fragment.tempid) {
    fragment.sync_after_gaining_id = true;
    return true;
  }

  let json = await prepareFragmentJson(fragment);
  if(
    json !== null &&
    fragment &&
    fragment.id !== null &&
    fragment.id !== undefined
  ) {
    try {
      let url = 'room/fragment/' + fragment.id;
      let res = await api.auth_put(url, json);
      if(res.res.ok) {
        return updateSuccess();
      }
      else {
        return updateErr(res.json || res.text);
      }
    }
    catch(e) {
      return updateErr(e);
    }
  }
}

export async function create(fragments) {
  // report success, if no fragments are to be synced
  if(fragments.length === 0) {
    return true;
  }
  addProgress();
  function onSuccess(fragment_ids) {
    let temporaries_to_sync = [];
    let temporaries_to_delete = [];
    for(let idx = 0; idx < fragment_ids.length; idx++) {
      let frag_id = fragment_ids[idx];
      let f = fragments[idx];
      gained_ids.new_gain(f.id, frag_id);
      f.id = frag_id;
      f.tempid = false;
      if(f.delete_after_gaining_id) {
        temporaries_to_delete.push(f);
        delete f.delete_after_gaining_id;
      }
      else if(f.sync_after_gaining_id) {
        temporaries_to_sync.push(f);
        delete f.sync_after_gaining_id;
      }
    }

    if(temporaries_to_sync.length > 0) {
      batch_update(temporaries_to_sync);
    }
    if(temporaries_to_delete.length > 0) {
      del(temporaries_to_delete);
    }

    removeProgress();
    return true
  }
  function onError(e) {
    dirty = true;
    msg.error('Could not save fragment(s). Check developer console of your browser.');
    console.error(e);
    return true;
  }
  let cur_space = space.getSpace();
  if (!cur_space || !cur_space.id) {
    return onError('Could not save fragment, because no space is loaded.');
  }
  const fragments_json = fragments.map(fragment => fragment.getPersistence());
  const valid = fragments_json.every(fragment_json => validate(fragment_json));
  if(valid) {
    try {
      let res = await api.auth_post('room/fragments/', { fragments: fragments_json, room: cur_space.id });
      if(res.res.ok) {
        return onSuccess(res.json);
      } else {
        return onError(res.json || res.text);
      }
    }
    catch(e) {
      return onError(e);
    }
  }
  else {
    return onError("Invalid fragment data passed");
  }
}

async function prepareFragmentJson(fragment) {
  function jsonSuccess() {
    return json;
  }
  function jsonErr(e) {
    console.error(e);
    msg.error('Could not save fragment(s). Check developer console of your browser.');
    return null;
  }
  let space_id = null;
  let json = fragment.getPersistence();
  let valid = validate(json);
  if(valid) {
    try {
      let cur_space = space.getSpace();
      if(cur_space !== null) {
        space_id = cur_space.id;
        json.room = space_id;
        return jsonSuccess();
      }
      else {
        return jsonErr('Could not save fragment, because no space is loaded.');
      }
    }
    catch(e) {
      return jsonErr(e);
    }
  }
  else {
    return jsonErr("Invalid fragment data passed");
  }
}

function validate(json) {
  function present(key) {
    let valid = json[key] !== undefined && json[key] !== null;
    if(!valid) {
      console.error(key + ' is not present in fragment!');
    }
    return valid;
  }
  let valid = true;
  valid = valid && present('x');
  valid = valid && present('y');
  valid = valid && present('width');
  valid = valid && present('height');
  valid = valid && present('scale');
  valid = valid && present('content');
  valid = valid && present('url');
  if(json.url !== '') {
    valid = valid && present('file_hashes');
  }

  return valid;
}
