import * as api_login from './api/login.js';
import * as api_space from './api/space.js';
import * as main from './main.js';
import * as fragment_production from './fragment/fragment_production.js';
import * as ui from './userinterface/ui.js';
import * as positions from './positions.js';
import * as selection from './selection.js';
import * as settings from './settings.js';
import * as multiuser from './multiuser.js';
import * as messages from './messages.js';
import * as audio from './audio.js';
import {BaseFragment} from './fragment/base_fragment.js';

// MONSTER
// This is a function that is not part of the module, it is just there to show the limit of the module
// We call it a monster function because monsters are also demonstrating the limits of systems
function getFragments() {
  throw Error('fragments are held by positions module');
}

// the maximum length of image width/height allowed in the current
// space; may be changed using scripts
export let _maxImageLength = null;
export function setMaxImageLength(val) {
  _maxImageLength = val;
}
export function getMaxImageLength() {
  return _maxImageLength;
}

export let spaceIsLoading = false;
export let loadingSince = 0;
let _loading = 0;
let _spaceIsInitialized = false;
let _firstFrameFinished = false;
let _firstFrameFinishedHandled = false;
export function spaceLoadingStarted() {
  spaceIsLoading = true;
}
export function spaceLoadingStopped() {
  spaceIsLoading = false;
}
function spaceInitStarted() {
  _spaceIsInitialized = false;
  _firstFrameFinished = false;
  _firstFrameFinishedHandled = false;
}
function spaceInitFinished() {
  _spaceIsInitialized = true;
}
export function isInitialized() {
  return _spaceIsInitialized;
}
export function firstFrameFinished(value) {
  if(typeof value === 'boolean') {
    _firstFrameFinished = value;
  }
  return _firstFrameFinished;
}
export function firstFrameFinishedHandled(value) {
  if(typeof value === 'boolean') {
    _firstFrameFinishedHandled = value;
  }
  return _firstFrameFinishedHandled;
}
export function addLoading() {
  if(_loading <= 0) {
    loadingSince = new Date().getTime();
  }
  _loading++;
}
export function popLoading() {
  _loading--;
}
export function isLoading() {
  return _loading > 0;
}

let _grounded = false;
export function isGrounded() {
  return _grounded;
}

export function loadThenInitSpace(username, spacename, pushState=true) {
  api_space.loadByName(username, spacename).then(function(success) {
    if(success) {
      let space = api_space.getSpace();
      if(space !== null) {
        initSpace(space, pushState);
      }
      else {
        messages.error("Could not load the space " +
          spacename + " of user " +
          username);
      }
    }
    else {
      messages.error("Could not load the space " +
        spacename + " of user " +
        username);
    }
  });
}

export let spaceInitialisingCallbacks = {};
export function initSpace(space, pushState=true) {
  spaceInitStarted();

  let is_admin = space.permissions.indexOf('room_admin') > -1;
  let is_monteur = space.permissions.indexOf('modify_room_fragments') > -1;
  _grounded = space.grounded && !(is_admin || is_monteur);

  settings.COLORS.BACKGROUND =  settings.COLORS.BACKGROUND_DEFAULT;
  settings.COLORS.FOREGROUND =  settings.COLORS.FOREGROUND_DEFAULT;
  settings.COLORS.WEAK_FOREGROUND =  settings.COLORS.WEAK_FOREGROUND_DEFAULT;
  setMaxImageLength(null);
  Object.keys(spaceInitialisingCallbacks).forEach(function(key) {
    let cb = spaceInitialisingCallbacks[key];
    if((typeof cb) === 'function') {
      cb();
    }
  });
  audio.resetAudio();
  selection.resetSelection();
  let jsonFragments = space.fragment_set; 
  positions.reset();
  let sketch = main.getSketch();
  try {
    jsonFragments.forEach(function(fragJson) {
      var fragment = BaseFragment.restore(sketch, fragJson);
      // use unshift instead of positions.addBelow(...)
      // to avoid syncing the fragment
      positions.unshift(fragment);
    });
  }
  catch(err) {
    console.error('Error initializing space:');
    console.error(err);
  }
  manageSpecialSpaces(space);
  let curSpace = api_space.getSpace();
  if(pushState) {
    window.history.pushState(
      {
        spacename : space.name,
        spaceusername : space.username
      },
      space.name,
      "/?user=" + space.username + "&room=" + space.name 
    );
  }
  multiuser.init(space.id);
  window.document.title = space.username + "->" + space.name;
  spaceInitFinished();
}

function addSpaceIndexFragments(spaces, title, xoff=0) {
  let p = main.getSketch();
  let persistent = false;
  // textSize was 20 before
  // using 60 for better resolution but compensating using scale = 1/3 !!
  // compensation only neccesary to protect existing space indexes
  let textSize = 60;
  p.textSize(textSize);
  let displayW = main.renderer.w;
  let displayH = main.renderer.h;
  let x_g = 100 + xoff;
  let y_g = 100;
  let w = p.textWidth(title + "  ");
  let hoff = 1/3 * p.textLeading();

  let frag = fragment_production.getFragment(
    "text", x_g, y_g,
    {
      text: title, width: w, height: 3*hoff, scale: 1/3,
      maxW: w, maxH: hoff,
      textSize: 60
    },
    persistent
  );
  frag.typeClick = function() {}
  frag.textSize = textSize;
  // without z-index
  positions.addOnTop(frag);

  let widest = w;
  spaces.forEach(function(space, idx) {
    let text = space.name + "   (" + space.username + ")";
    let w = p.textWidth(text + "  ");
    widest = Math.max(w, widest);
    let h = 1.0 * p.textLeading();
    let x = x_g;
    let y = y_g + 1.4*hoff + idx * hoff;
    let maxW = w;
    let maxH = h;
    let frag = fragment_production.getFragment(
      "text", x, y,
      {
        text: text, width: w, height: h, scale: 1/3,
        maxW: maxW, maxH: maxH,
        textSize: 60
      },
      persistent
    );
    frag.textSize = textSize;
    frag.ontexteditend = function() {
      console.log("now TODO: nothing");
    }
    frag.spacefragusername = space.username;
    frag.spacefragspacename = space.name;
    let hoverFun = frag.drawHover;
    frag.typeSelectHoverCursor = null;
    frag.drawHover = function(x,y,w,h) {
      p.cursor('pointer');
      p.push();
      p.noFill();
      p.strokeWeight(2);
      p.stroke(settings.COLORS.INTERACTION);
      p.rect(0,0,frag.w,frag.h);
      p.pop();
      hoverFun.apply(frag, [x, y, w, h]);
    }
    frag.typeClick = async function() {
      let spaceusername = this.spacefragusername;
      let spacename = this.spacefragspacename;
      console.log("loading space..");
      spaceLoadingStarted();
      api_space.loadByName(spaceusername, spacename).then(function(success) {
        spaceLoadingStopped();
        if(success) {
          console.log("loaded");
          let space = api_space.getSpace();
          initSpace(space);
          console.log("done");
        }
      });
    }
    positions.addOnTop(frag);
  });
  return widest / 3;
}

async function manageSpecialSpaces(space) {
  addLoading();
  let loggedIn = api_login.loggedIn();
  let username = api_login.getUsername();
  let spaceusername = space.username;
  if(loggedIn && username === spaceusername && space.name === "default") {
    let xoff = 0;
    let spaces = await api_space.space_list();
    let public_spaces = await api_space.public_space_list();
    let your_spaces = spaces.filter(function(space) {
      return space.username === username;
    });
    let other_spaces = spaces.filter(function(space) {
      return space.username !== username;
    });
    let yourwidth = addSpaceIndexFragments(your_spaces, 'your spaces', xoff);
    xoff += yourwidth + 100;
    let otherwidth = addSpaceIndexFragments(other_spaces, 'other spaces', xoff);
    xoff += otherwidth + 100;
    addSpaceIndexFragments(public_spaces, 'public spaces', xoff);
  }
  else if(space.name === 'default' && spaceusername === 'nota'){
    //let public_spaces = await api_space.public_space_list();
    //addSpaceIndexFragments(public_spaces, 'public spaces');
  }
  popLoading();
}

window.onpopstate = function(event) {
  let target = event.explicitOriginalTarget || event.target;
  if(event.state && event.state.spacename && event.state.spaceusername) {
    console.log("loading space by names in history state: "
      + event.state.spacename + " of " + event.state.spaceusername);
    event.preventDefault();
    api_space.loadByName(event.state.spaceusername, event.state.spacename).then(function(success) {
      if(success) {
        let space = api_space.getSpace();
        initSpace(space, false);
      }
    });
  }
  else if(target.location.href === window.location.origin + '/' ){
    api_space.loadByName("nota", "default").then(function(success) {
      if(success) {
        let space = api_space.getSpace();
        initSpace(space, false);
      }
    });
  }
  else {
    let urlParams = new URLSearchParams(target.location.search)
    let spacename = urlParams.get('room');
    let spaceusername = urlParams.get('user');
    api_space.loadByName(spaceusername, spacename).then(function(success) {
      if(success) {
        let space = api_space.getSpace();
        initSpace(space, false);
      }
    });
  }
};

