/* 🕮
You are reading the 'positions module'

The positions module (positions.js) holds the fragments of a space
it administrates spatial relations of fragments and users

Directions for USER SCRIPTS:
you can only use functions and variables that have the keyword 'export' in front of them
Also: in your user scripts you always have to write "modules.positions.something"

So for example here is one line of code you can use, cause it has the 'export' in front:
export function goToCenter() {

in the user scripts you can use it like this:
modules.positions.goToCenter();

Some more examples:
modules.positions.getAll();
modules.positions.goToScene(x, y, scale);
modules.positions.getGlobalScaleLowPrec();

To find these examples in the code below, you can search for der their individual names 
for example use the browsers search function (Ctrl+f or Cmd+f) to search for 'goToScene'

Scroll through the code to find more
*/

// Relations to the other modules of nota

// From the 'settings module' positions imports an unusual type of number called 'Decimal'
// Decimal uses a wrong 'body' (a string) to bend the rules of Javascript and expand notas space
// Look in the settings module to learn more
import {Decimal} from './settings.js';
// From the 'main module' positions gets the p5.js library and more specifially the 'p5' renderer
// Question from the code-commenting team: Couldn't we exclusively import {getSketch, renderer} from './main.js'
// instead of importing the whole module?
import * as main from './main.js';
// From the 'base_fragment' module we get the BaseFragment Class (look in the base_fragments module to learn
// about fragments and 'Classes')
import {BaseFragment} from './fragment/base_fragment.js';
// From the 'api_fragments module' positions gets a list of fragments with their identification numbers
import * as api_fragments from './api/fragments.js';
// From the 'undo module' positions imports a function to reset/nullify the undo and redo history
// resetting this history is part of resetting the space, which is why the positions.reset() function
// is called by the 'space module' to reset all positions
import {reset as undo_reset} from './undo.js';
// Positions uses the localstore module to save and remember the user position
import * as localstore from './localstore.js';
// From the 'utilities module' positions imports a function to reverse a list of fragments
import {reverse} from './utilities/utilities.js';
// a matrix class to calculate 2D transformations
import {Matrix} from './utilities/matrix.js';
// From the 'input module' positions uses a function to reset the users movement when clicking 'center'
import {resetMovement} from './input.js';
import * as multiuser from './multiuser.js';

// MONSTER
// This function is just there to show the limit of the module
// We call it a monster function because monsters are also demonstrating the limits of systems
export function getFragmentPosition() {
  throw Error('the "positions module" only has a list of the fragments, fragments know their own positions');
}

/* 🕮
The code line below starting with 'let ...' sets up a kind of list (called array by programmers) to store all fragments of a space
For USER-SCRIPTS: There is a function in this module called getAll() to get this list
nota will read this list/array and then draw the fragments:
the first fragment in the array is drawn first and thus all the way in the background
the last fragment in the array is drawn last and thus in front of all others
nota will then read the array backwards and execute all existing user-scripts of the fragments
LINK to a demonstration of the array and on the gaps that emerge when you try to pack space into a list:
https://nota.space/?user=b&room=_fragments
*/
let _space_fragments = [];

function makeFragmentsUnique() {
  // fragments are sometimes duplicated during multiuser sync
  // this is a workaround to prevent such duplications and the
  // related errors
  const ids = new Set();
  _space_fragments.forEach(fragment => {
    if(ids.has(fragment.id)) {
      fragment.___positions_duplicate = true;
    }
    else if(fragment.id > -1) {
      ids.add(fragment.id);
    }
  });
  let before = _space_fragments.length;
  _space_fragments = _space_fragments.filter(fragment => {
    return fragment.___positions_duplicate !== true;
  });
  if(before !== _space_fragments.length) {
    console.warn('removed some duplicated fragments');
  }
}

// this line sets up the 'globalScale', which is the zoom level of the user in notas global coordinate system
// The globalScale is set to a *very* precise number (it has 100 digits) and makes it possible to zoom very far
// A normal Javascript number can only have 16 digits, that is why nota uses a special library that can handle
// these long numbers (learn more about it in the settings module)
// For USER-SCRIPTS: Search for the function getGlobalScaleLowPrec() to access this variable
let globalScale = new Decimal(1.000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000001);
let globalTranslation = {x:new Decimal(0), y:new Decimal(0)};
let _positionChanged = true;

let _frameGlobalMatrix = null;
/**
 * get the current transformation matrix which transforms
 * nota space coordinates to user screen coordinates
 *
 * @name getGlobalMatrix
 * @return Transformation matrix according to the current
 *         user position[(a,c,e) (b,d,f) (0,0,1)]
 */
export function getGlobalMatrix() {
  if(_frameGlobalMatrix !== null) {
    return _frameGlobalMatrix;
  }
  const globalMatrix = new Matrix();
  globalMatrix.translate(
    globalTranslation.x, globalTranslation.y
  );
  globalMatrix.scale(globalScale);
  _frameGlobalMatrix = globalMatrix;
  return _frameGlobalMatrix;
}

function setGlobalTranslation(val) {
  if(val.x !== globalTranslation.x && val.y !== globalTranslation.y) {
    setPositionChanged(true);
  }
  globalTranslation = val;
}

function getGlobalTranslation() {
  return globalTranslation;
}

function setGlobalScale(val) {
  if(val !== globalScale) {
    setPositionChanged(true);
  }
  globalScale = val;
}

function getGlobalScale() {
  return globalScale;
}

export function getGlobalScaleLowPrec() {
  let fc = main.renderer.frameCount;
  if(fc != _calcedGSFrame) {
    calcedGS = getGlobalScale().toNumber();
    _calcedGSFrame = fc;
  }
  return calcedGS;
}

export function getGlobalScalePrec() {
  return getGlobalScale();
}

export function getPositionChanged() {
  return _positionChanged;
}

export function setPositionChanged(val) {
  _positionChanged = val;
}

export function storeCamera() {
  localstore.setItem('translation_x', getGlobalTranslation().x);
  localstore.setItem('translation_y', getGlobalTranslation().y);
  localstore.setItem('scale', getGlobalScale());
}

// The goToScene() function will move the canvas to show you a specific position
// For USER SCRIPTS: You can use the x, y and scale values of fragment. However the way you have to
// apply the values, is not intuitive.
// "I managed to figure it out by myself, but is was really hard, as demonstrated here" (Birk):
// [TODO: link to screenrecording]
// Insert this code into a fragment and you will jump there:
/*
let scale = 1 / fragment.getIndividualScale()
let x = -fragment.x * scale;
let y = -fragment.y * scale;
modules.positions.goToScene(x, y, scale);
*/
export function goToScene(tx, ty, scale) {
  setGlobalScale( new Decimal(scale));
  setGlobalTranslation( {x:new Decimal(tx), y:new Decimal(ty)});
  storeCamera();
}

// The goToCenter() function is called by the 'input module' when the user clicks the 'center button'
// For USER SCRIPTS: This is function that calls two other functions:
// modules.input.resetMovement() you can find in the 'input module'
// modules.postions.goToScene() you can find in this module 
export function goToCenter() {
  //this is modules.input.resetMovement()
  resetMovement();
  goToScene(0, 0, 1)
}

// TODO should be same or something
export function resetCamera() {
  let tx = localstore.getItem('translation_x') || 0;
  let ty = localstore.getItem('translation_y') || 0;
  let sc = localstore.getItem('scale') || 1;
  goToScene(tx, ty, sc);
}

export function resetFragments() {
  let canv = document.getElementById('defaultCanvas0');
  let cont = document.getElementById('zoom-sketch');
  while (cont.firstChild) {
    cont.removeChild(cont.firstChild);
  }
  cont.appendChild(canv);
  _space_fragments = [];
}

export function resetFrame() {
  _frameGlobalMatrix = null;
}

export function reset() {
  resetFragments();
  resetCamera();
  // this is modules.undo.reset()
  undo_reset();
}

export function refreshFragmentsByIDs(fragment_ids, tab_id) {
  let fragmentsToUpdate = getAll().filter(
    frag => frag.id !== -1 && fragment_ids.includes(frag.id)
  );
  let fragments_by_id = {};
  fragmentsToUpdate.forEach((frag) => {
    fragments_by_id[frag.id] = frag;
  });
  api_fragments.get_list_by_ids(fragment_ids).then(function(pers){
    fragmentsToUpdate.forEach(function(frag) {
      let fragPers = pers[frag.id];
      if(fragPers) {
        fragPers.persistent = true;
        const oldValues = {
          scale: frag._scale,
          x: frag.x,
          y: frag.y
        };
        const initCall = false;
        const sync = false;
        Object.getPrototypeOf(frag).constructor.setFragmentData(
          frag, fragPers, initCall, sync
        );
        multiuser.animateChanges(frag, oldValues, tab_id);
      }
    });
  });
  if(fragmentsToUpdate.length != fragment_ids.length) {
    const missingFragmentsIDs = fragment_ids.filter(
      id => !Object.keys(fragments_by_id).includes(id)
    );
    loadNewFragmentsByIDs(missingFragmentsIDs, tab_id);
  }
}

export function loadNewFragmentsByIDs(fragment_ids, tab_id) {
  api_fragments.get_list_by_ids(fragment_ids).then(function(fragment_jsons) {
    let sketch = main.getSketch();
    fragment_jsons = Object.keys(fragment_jsons).map(key => fragment_jsons[key]);
    fragment_jsons.forEach(function(fragJson) {
      var fragment = BaseFragment.restore(sketch, fragJson);
      insertFragment(fragment, false);
      multiuser.indicateFragmentChange(fragment, tab_id);
    });
  });
}

export function removeFragmentsByIDs(fragment_ids, tab_id) {
  fragment_ids.forEach(frag_id => removeFragmentByID(frag_id, tab_id));
}
export function removeFragmentByID(fragment_id, tab_id) {
  let fragment = _space_fragments.find(f => f.id === fragment_id);
  if(typeof fragment !== 'undefined' && !fragment.grabbed) {
    // delete fragments from fragments list
    _space_fragments = _space_fragments.filter(
      f => f.id !== fragment_id
    );
    if(typeof fragment !== 'undefined') {
      fragment.onRemove();
    }
    else {
      console.warn("did not find fragment object before removing");
    }
  }
  if(typeof fragment !== 'undefined') {
    const position = {
      x: fragment.screenX(),
      y: fragment.screenY(),
      w: fragment.screenW(),
      h: fragment.screenH(),
    };
    multiuser.indicateFragmentDeletion(position, tab_id);
  }
}



// This function is supposed to return just the fragments on your screen but returns all fragments in the space
// The function is under construction, right now its just a reminder that we wanted to have this function
// HOWEVER: it seems like we already have that function under the name onScreenFragments()
export function getOnScreenFragments() {
  return _space_fragments;
}

/**
 * Add fragments according to their floating_z, if any; else on top
 */
export function insertFragments(fragmentsToInsert, sync = true) {
  fragmentsToInsert.forEach(f => insertFragment(f, sync));
}
export function repositionForFloatingZ(toInsert) {
  let idx = _space_fragments.findIndex(f => f.id === toInsert.id);
  if(idx < 0)
  {
    console.error('fragment should already be around before repositioning');
    return;
  }
  _space_fragments.splice(idx, 1);

  let fz = toInsert.floating_z;
  let didInsert = _space_fragments.some(function(frag, idx) {
    if(fz <= frag.floating_z) {
      _space_fragments.splice(idx, 0, toInsert);
      return true;
    }
  });
  if(!didInsert) {
    _space_fragments.push(toInsert);
  }
}
export function insertFragment(toInsert, sync = true) {
  let fz = toInsert.floating_z;
  let didInsert = _space_fragments.some(function(frag, idx) {
    if(fz <= frag.floating_z) {
      _space_fragments.splice(idx, 0, toInsert);
      return true;
    }
  });
  if(!didInsert) {
    addOnTop(toInsert, sync);
  }
  makeFragmentsUnique();
}
function addFragment(fragment, onTop, sync = true) {
  fragment.isDeleted = false;
  fragment.removed_from_space = false;
  if(onTop) {
    _space_fragments.push(fragment);
    sendFragmentToFront(fragment, sync);
  }
  else {
    _space_fragments.unshift(fragment);
    sendFragmentToBack(fragment, sync);
  }
  makeFragmentsUnique();
}
export function addFragmentsBelow(newFragments, sync = true) {
  newFragments.forEach(f => addBelow(f, sync));
}
export function unshift(fragment) {
  _space_fragments.unshift(fragment);
  makeFragmentsUnique();
}
export function addBelow(fragment, sync = true) {
  addFragment(fragment, false, sync);
}
export function removeFragments(removeFragments) {
  removeFragments.forEach(removeFragment);
}
export function removeFragment(fragment) {
  _space_fragments = _space_fragments.filter(
    // use ids for synced fragments, and objects for non-synced
    // fragments. we do not guarantee object identity for synced fragments
    // (see makeFragmentsUnique())
    f => f.id !== fragment.id || f.id < 0 && f !== fragment
  );
  fragment.onRemove();
}

export function addFragmentsOnTop(fragmentsToAdd, sync = true) {
  fragmentsToAdd.forEach(f => addOnTop(f, sync));
}
export function addOnTop(fragment, sync = true) {
  addFragment(fragment, true, sync);
}

export function getAll() {
  return _space_fragments;
}
export function getPersistentFragments() {
  return _space_fragments.filter(function(frag) {
    return frag.persistent;
  });
}

export function getFragmentsContainedByRect(screenRect) {
  let x = screenRect.x;
  let y = screenRect.y;
  let w = screenRect.w;
  let h = screenRect.h;
  // TODO
}
export function getFragmentAt(screenPoint) {
  let x = screenPoint.x;
  let y = screenPoint.y;
  let frag = null;
  reverse(onScreenFragments(), function(fragment) {
    var within = fragment.pointCollide(x, y);
    if(within) {
      frag = fragment;
    }
    return within;
  });
  return frag;
}
export function onScreenFragments() {
  return _space_fragments.filter(function(frag) {
    return frag.isOnScreen();
  });
}
// return bottom fragment, excluding non-persistent fragments
export function getBottomFragment() {
  let frags = getPersistentFragments();
  return frags[frags.length - 1];
}
// return top fragment, excluding non-persistent fragments
export function getTopFragment() {
  return getPersistentFragments()[0];
}
export function sendFragmentToFront(fragment, sync  = true) {
  let idx = _space_fragments.findIndex(
    frag => frag.id === fragment.id
  );
  if(idx !== -1) {
    var shiftElem = _space_fragments.splice(idx, 1);
    _space_fragments.push(shiftElem[0]);
    makeFragmentsUnique();
    let persistentFragments = getPersistentFragments();
    if(persistentFragments.length > 1) {
      let largest = persistentFragments[persistentFragments.length - 2].floating_z;
      fragment.floating_z =  largest + 1;
    }
    if(sync) {
      fragment.sync()
    }
  }
  else {
    console.error("Cannot send fragment to front if not in fragments array");
  }
}
export function sendFragmentToBack(fragment, sync = true) {
  let idx = _space_fragments.findIndex(
    frag => frag.id === fragment.id
  );
  if(idx !== -1) {
    var shiftElem = _space_fragments.splice(idx, 1);
    _space_fragments.unshift(shiftElem[0]);
    makeFragmentsUnique();
    let persistentFragments = getPersistentFragments();
    if(persistentFragments.length > 1) {
      fragment.floating_z = _space_fragments[1].floating_z - 1;
    }
    if(sync) {
      fragment.sync()
    }
  }
  else {
    console.error("Cannot send fragment to front if not in fragments array");
  }
}
export function moveToTop(fragstomove) {
  fragstomove.forEach(sendFragmentToFront);
}

let resetTimeout = null;
let warnTimeout = null;
export function changeTranslationBy(x, y) {
  setGlobalTranslation({
    x : getGlobalTranslation().x.add(x),
    y : getGlobalTranslation().y.add(y)
  });
  storeCamera();
}
export function getTranslation() {
  let translation =  {
    x: getGlobalTranslation().x.toNumber(),
    y: getGlobalTranslation().y.toNumber()
  };
  return translation;
}
export function getTranslationPrec() {
  return getGlobalTranslation();
}
let _calcedGSFrame = -1;
let calcedGS = 0;
function _zoomGlobalPrecision(mouse, factor) {
  let gtx = getGlobalTranslation().x;
  let gty = getGlobalTranslation().y;
  let gs = getGlobalScale();
  // let xSpace = mouse.x / globalScale - globalTranslation.x / globalScale;
  let xSpace = (new Decimal(mouse.x).div(gs)).sub(gtx.div(gs));
  //let ySpace = mouse.y / globalScale - globalTranslation.y / globalScale;
  let ySpace = (new Decimal(mouse.y).div(gs)).sub(gty.div(gs));
  //let newXSpace = xSpace / factor;
  let newXSpace = xSpace.div(factor);
  //let newYSpace = ySpace / factor;
  let newYSpace = ySpace.div(factor);

  //globalScale *= factor;
  gs = gs.mul(factor);
  setGlobalScale( gs);
  //globalScale = gs.toNumber();
  //let xt = (newXSpace - xSpace) * globalScale;
  let xt = (newXSpace.sub(xSpace)).mul(gs);
  //let yt = (newYSpace - ySpace) * globalScale;
  let yt = (newYSpace.sub(ySpace)).mul(gs);
  changeTranslationBy(xt, yt);
}
export function zoomGlobal(mouse, factor) {
  _zoomGlobalPrecision(mouse, factor);
  storeCamera();
}

export function moveFragmentByScreenDiff(fragment, screenX, screenY) {
  let xdif = new Decimal(screenX).div(getGlobalScale());
  fragment.x += xdif.toNumber();
  let ydif = new Decimal(screenY).div(getGlobalScale());
  fragment.y += ydif.toNumber();
}
export function moveFragment(fragment, dx, dy) {
  fragment.x += dx;
  fragment.y += dy;
}
/**
 * scaleFragment with scaling center at (screenX, screenY) by factor
 */
export function scaleFragment(fragment, factor, point) {
  let scaleOld = fragment.getIndividualScale(true);
  let newScale = factor * scaleOld;
  fragment.resetTransformationMatrix();
  let gm = getGlobalMatrix();
  let p_old = gm.inverse().mult_point(point);

  let p_local = fragment.transformationMatrix.inverse().mult_point(p_old);

  fragment.setIndividualScale(newScale);
  fragment.resetTransformationMatrix();

  let p_new = fragment.transformationMatrix.mult_point(p_local);
  let d = { x: (p_new.x - p_old.x), y: (p_new.y - p_old.y) };

  fragment.x -= d.x;
  fragment.y -= d.y;
  return d;
}
/**
 * Be careful this method is expensive on the  CPU
 */
export function screenToGlobalDiff(diff) {
  let xdif = new Decimal(diff.dx).div(getGlobalScale());
  let ydif = new Decimal(diff.dy).div(getGlobalScale());
  return {dx: xdif.toNumber(), dy: ydif.toNumber()};
}
/**
 * Be careful this method is expensive on the  CPU
 */
export function screenToGlobalCoords(point) {
  let x = new Decimal(point.x).div(getGlobalScale());
  x = x.sub(getGlobalTranslation().x.div(getGlobalScale()));
  let y = new Decimal(point.y).div(getGlobalScale());
  y = y.sub(getGlobalTranslation().y.div(getGlobalScale()));
  return {x: x.toNumber(), y: y.toNumber()};
}
export function globalToScreenCoords(point) {
  let sc = getGlobalScaleLowPrec();
  let t = getTranslation();
  return {
    x: point.x * sc + t.x,
    y: point.y * sc + t.y
  }
}
export function globalToScreenSize(size) {
  return {
    w: size.w * getGlobalScaleLowPrec(),
    h: size.h * getGlobalScaleLowPrec()
  }
}
export function getView(screenW, screenH) {
  let x = getGlobalTranslation().x;
  let y = getGlobalTranslation().y;
  let w = new Decimal(screenW).div(getGlobalScale());
  let h = new Decimal(screenH).div(getGlobalScale());
  return {x: x.toNumber(), y: y.toNumber(), w: w.toNumber(), h: h.toNumber()};
}

