import icons from './icons.js';

import {holdOverlay, releaseOverlay} from './events.js';
import * as login from '../api/login.js';
import * as api_space from '../api/space.js';
import * as api_permissions from '../api/permissions.js';
import * as messages from '../messages.js';
import {loadSpaceList} from './ui.js';

let s = {
  cont: {},
  tabs: {},
  modalStack: [],
};

function de(n, a) {
  let e = document.createElement(n);
  if(a) {
    for(let i in a)
    {
      if(i === 'textContent')
      {
        e.textContent = a[i];
      }
      else if(i === 'style')
      {
        let o = a[i];
        for(let j in o)
        {
          e.style[j] = o[j];
        }
      }
      else
      {
        e.setAttribute(i, a[i]);
      }
    }
  }
  return e;
}

function e_radio(a)
{
  let e = de('div');
  let checked = a.checked ? {checked: true} : {};
  let disabled = a.disabled ? {disabled: true} : {};
  let random = Math.floor(Math.random()*100)
  let e_inp = de('input', {
    type: "radio",
    id: a.id+random,
    name: a.name,
    value: a.value,
    ...checked,
    ...disabled
  });
  if(a.onchange)
  {
    e.onchange = () => a.onchange(a.value);
  }
  let e_label = de('label', {
    for: a.id+random,
    textContent: a.label,
    style: {
      fontSize: '.8333em',
    }
  });
  e.append(e_inp, e_label);
  return e;
}

function text_response(accepted)
{
  if(accepted === null) {
    return "response waiting.";
  } else if(accepted === true) {
    return "accepted";
  } else if(accepted === false) {
    return "declined";
  }
}

let PERMS = [
  ['room_admin', 'admin'],
  ['modify_room_fragments', 'monteur'],
  ['view_room_fragments', 'guest'],
  ['group_admin', 'admin'],
  ['group_user', 'participant'],
];


export function text_permission(ps)
{
  for(let perm of PERMS)
  {
    let [code, view] = perm;
    if(ps.indexOf(code) > -1)
    {
      return view;
    }
  }

  console.error('invalid permission list', ps)
  return 'cant do much';
}

function e_invite(inv, username)
{
  let e_r = de('div', { class: 'flex flex-column gap-5' });
  {
    let e_to = de('span', {class: 'text-gray-80'})
    if (inv.invite_to.type === 'space') {
      let e_link = de('a', {
        href: "/?user=" + inv.invite_to.username + "&room=" + inv.invite_to.name,
        class: 'standalone-icon hover-brighter',
        target: 'blank',
        style: {
          display: 'inline-block',
          width: '0.8em',
          height: '0.8em',
          marginRight: '0.2em',
          marginLeft: '0.2em',
        }
      });
      e_link.innerHTML = icons.openlink
      e_to.append(e_link, `${inv.invite_to.name} (${inv.invite_to.name})`);
    } else {
      e_to.append(`[group: ${inv.invite_to.name}]`);
    }

    let e = de('div');
    e.append("join ", e_to,
      " as ",
      de('span', {class: 'text-gray-80', textContent: text_permission(inv.granted_permission_codes)})
    );
    e_r.append(e);
  }
  {
    let e = de('div');
    if(inv.created_by !== username) {
      e.append("from: ", de('span', {class: 'text-gray-80', textContent: inv.created_by}));
    }
    if(inv.invitee !== username) {
      e.append(" to: ", de('span', {class: 'text-gray-80', textContent: inv.invitee}));
    }
    if(inv.invite_text) {
      e.append(" message: ", de('span', {class: 'text-gray-80', textContent: inv.invite_text}));
    }
    e_r.append(e);
  }

  if(inv.accepted === false && inv.invite_decline_text)
  {
    let e = de('div');
    e.append("decline: ", de('span', {class: 'text-gray-80', textContent: inv.invite_decline_text}));
    e_r.append(e);
  }

  return e_r;
}

function e_invite_row(inv, viewing_username, response_buttons)
{
  let e_row = de('div', { class: 'flex flex-row items-center justify-between gap-20 pl-20' });

  {
    let e = e_invite(inv, viewing_username);
    e_row.append(e);
  }

  if(!response_buttons || inv.accepted !== null)
  {
    let e = de('div', {
      textContent: text_response(inv.accepted),
      style: {
        color: '#ccc',
      }
    })
    e_row.append(e);
  }
  else
  {
    let e_buttons = de('div', { class: 'flex gap-10' });
    e_row.append(e_buttons);
    {
      let e = de('div', {
        class: 'surface-item',
        textContent: `accept`,
      })
      e.onclick = () => {
        api_permissions.respond_to_invitation(inv.id)
          .then(() => {
            inv.accepted = true
            e_row.parentElement.replaceChild(e_invite_row(inv, viewing_username, true), e_row)
          });
      }
      e_buttons.append(e);
    }
    {
      let e = de('div', {
        class: 'surface-item',
        textContent: `decline`,
      })
      e.onclick = show_decline_modal({invite: inv});
      e_buttons.append(e);
    }
  }
  return e_row;
}

function e_empty()
{
  let e = de('div', {
    class: 'standalone-icon',
    style: {
      marginTop: '20px',
      alignSelf: 'center',
      width: '30px',
      height: '30px',
      color: 'white',
    }
  });
  e.innerHTML = icons.empty;
  return e;
}

function e_loading()
{
  let e = de('div', {
    style: {
      alignSelf: 'center',
      fontSize: '2rem',
      color: 'white',
      animationName: 'color',
      animationDuration: '2s',
      animationIterationCount: 'infinite',
    }
  });
  e.innerHTML = "&bull; &bull; &bull;";
  return e;
}

function make_list_section(a)
{
  let section_state = {s: 'ready'};
  let e_section = de('div', { class: 'flex flex-column overflow-hidden', });

  function init_list()
  {
    section_state.list = [];
    let e = section_state.e_list;
    if(e) {
      e.parentElement.removeChild(e);
    }
    e = de('div', { class: 'flex flex-column gap-20 flex-1 y-scroll pb-40 bottom-mask' });
    e_section.append(e);
    section_state.e_list = e;

    {
      let e_button = de('button', {
        textContent: "load more",
        style: {
          padding: '5px',
          backgroundColor: '#ccc',
          display: 'none',
        }
      });
      e_button.onclick = () => {
        if(section_state.s !== 'ready') {
          return;
        }
        let list = section_state.list;
        if(list.length < 1) {
          return;
        }
        let last_id = list[list.length-1].id;
        load_list(last_id);
      };
      section_state.e_button = e_button;
      e.append(e_button);
    }
  }

  {
    // header, users of space
    let e_row = de('div', { class: 'flex flex-row justify-between items-center pl-10' });
    e_section.append(e_row);

    e_row.append(de('h3', { textContent: a.head }));

    if(a.refresh)
    {
      let e = de('div', {
        class: 'surface-item icon'
      });
      e.innerHTML = icons.rotate
      e.onclick = () => {
        init_list();
        load_list(0);
      };
      e_row.append(e);
    }
  }

  function load_list(after)
  {
    section_state.s = 'loading';
    section_state.e_loading = e_loading();
    let e_list = section_state.e_list;
    e_list.append(section_state.e_loading);
    a.req(after).then(res => {
      let list = res.json;
      if(list.length < a.page_size)
      {
        section_state.s = 'complete';
        section_state.e_button.style.display = 'none';
      }
      else
      {
        section_state.e_button.style.display = '';
        section_state.s = 'ready';
      }
      section_state.list.push(...list);

      if(section_state.list.length < 1)
      {
        e_list.append(e_empty());
      }
      else
      {
        for(let item of list)
        {
          e_list.append(a.e_item(item));
        }
      }
      e_list.append(section_state.e_button);
    }).finally(() => {
      e_list.removeChild(section_state.e_loading);
    });
  }
  init_list();
  load_list(0);
  return {e: e_section};
}

function handleEscape(e) {
  let close_modal = s.modalStack.pop();
  close_modal();
}

function stack_modal(f) {
  let sf = () => {
    s.modalStack = s.modalStack.filter(s => f !== s);
    f();
  }
  s.modalStack.push(f);
  return sf;
}

export function showShareSpaces () {
  holdOverlay(handleEscape);
  let close_modal = stack_modal(() => {
    releaseOverlay();
    document.body.removeChild(s.cont.oe);
    document.body.removeChild(s.cont.be);
    s.cont = {};
  });

  let oe = de('div', {
    style: {
      width: '100vw',
      height: '100vh',
      background: '#000000aa',
      top: '0',
      left: '0',
      position: 'fixed',
    }
  })
  document.body.append(oe);
  let be = de('div', {
    class: 'permissions-ui flex flex-column items-stretch',
    style: {
      width: '90vw',
      height: '90vh',
      background: 'black',
      position: 'fixed',
      left: '5vw',
      top: '5vh',
      border: '1px solid white',
      color: '#aaa',
      fontFamily: 'Arial',
      boxSizing: 'border-box',
    }
  })
  document.body.append(be);
  s.cont = {oe, be, close_modal};

  // close button
  {
    let e = de('div', {
      class: 'surface-item icon',
      style: {
        position: 'absolute',
        top: '-10px',
        left: '-10px',
      }
    });
    e.onclick = close_modal;
    e.innerHTML = icons.xmark;
    s.cont.be.append(e);
  }

  // close button
  {
    let e = de('div', {
      class: 'narrow-message flex flex-column justify-center items-center',
      textContent: 'Designed for a wider view.',
      style: {
        flexGrow: 1,
      }
    });
    s.cont.be.append(e);
  }

  s.tabs = {};
  set_tab('invitations');
}

function set_tab(tab)
{
  s.tabs.current = tab;
  {
    let e = s.tabs.e_top;
    if(e) {
      e.parentElement.removeChild(e);
    }

    e = view_tabs()
    s.tabs.e_top = e;

    s.cont.be.append(e);
  }

  {
    let view;
    if(tab === 'invitations') {
      view = view_invitations;
    } else if(tab === 'spaces') {
      view = view_spaces;
    } else if(tab === 'groups') {
      view = view_groups;
    } else {
      console.assert(view, 'unknown tab');
    }

    let e = s.tabs.e_current;
    if(e) {
      e.parentElement.removeChild(e);
    }

    e = view();
    s.tabs.e_current = e;

    s.cont.be.append(e);
  }
}

function button_tab(tab)
{
  let is_current_tab = s.tabs.current === tab;
  let e = de('div', {
    class: 'surface-item context',
    textContent: tab,
    style: {
      ...(is_current_tab ? {backgroundColor: '#999'} : {}),
    }
  });
  e.onclick = () => {
    set_tab(tab);
  }
  return e;
}

function view_tabs()
{
  let ev = de('div', { class: 'hide-on-narrow flex flex-row gap-20 self-center',
    style: {
      marginTop: '-20px',
    },
  });

  let tabs = ['invitations', 'spaces', 'groups'];
  for(let tab of tabs) {
    ev.append(button_tab(tab));
  }

  return ev;
}

function view_invitations()
{
  let e_wrap = de('div', { class: 'hide-on-narrow flex flex-column gap-10 items-stretch justify-between overflow-hidden',
    style: {
      flexGrow: '1',
      flexShrink: '1',
    }
  });
  let ev = de('div', { class: 'flex flex-row gap-20 items-stretch justify-center mt-20 pl-20 pr-20 overflow-hidden' })
  e_wrap.append(ev);
  let cred_a = login.getCreds();
  {
    let section = make_list_section({
      page_size: 5,
      refresh: true,
      head: `recieved invitations`,
      e_item: (inv) => e_invite_row(inv, cred_a.username, true),
      req: (after) => api_permissions.received_invitations(after),
    })
    section.e.style.width = '350px';
    ev.append(section.e);
  }

  {
    let section = make_list_section({
      page_size: 5,
      refresh: true,
      head: `sent invitations`,
      e_item: (inv) => e_invite_row(inv, cred_a.username, false),
      req: (after) => api_permissions.sent_invitations(after),
    })
    section.e.style.width = '350px';
    ev.append(section.e)
  }
  let e_footer = de('div', { class: 'flex justify-center items-center pt-10 pb-10' });
  e_footer.append(`Looking for public or shared spaces? You will still find them by clicking the 'spaces' button. For now, here is a shorcut:`);
  {
    let creds = login.getCreds();
    let e_link = de('a', {
      href: "/?user=" + creds.username + "&room=" + "default",
      class: 'surface-item icon large4 ml-10',
      target: 'blank',
    });
    e_link.innerHTML = icons.signspost
    e_footer.append(e_link);
  }
  e_wrap.append(e_footer);
  return e_wrap;
}

function view_spaces()
{
  let ev = de('div', { class: 'hide-on-narrow flex flex-row gap-20 flex-1 x-scroll mt-20 pl-20 pr-20' })

  {
    // left column - space selection list
    let e_column = de('div', { class: 'flex flex-column flex-300 gap-10 bottom-mask-step y-scroll' });
    ev.append(e_column);

    let section_state = {es: []};
    let e_section = de('div', { class: 'flex flex-column gap-10' });
    e_column.append(e_section);

    {
      let e = de('h3', { textContent: 'spaces you can admin' });
      e_section.append(e);
    }

    {
      let e = de('input', {
        placeholder: 'search',
        type: 'search',
        style: {
          padding: '10px',
          backgroundColor: '#222',
          color: '#ccc',
          border: 'none',
          outline: 'none',
        },
      });
      e.oninput = () => {
        console.log(e.value)
        let {es} = section_state;
        let hastest = e.value.length > 0;
        let regex = new RegExp(e.value);
        for(let i = 0; i < es.length; i++)
        {
          let e_space = es[i];

          if(!hastest || regex.test(e_space.textContent)) {
            e_space.style.display = '';
          } else {
            e_space.style.display = 'none';
          }
        }
      }
      e_section.append(e);
    }

    let active = {};
    let set_active = (e) => {
      if(active.e)
      {
        active.e.classList.remove('background-gray-6', 'pl-10', 'text-white')
      }
      active.e = e;
      active.e.classList.add('background-gray-6', 'pl-10', 'text-white')
    }

    let e_item = (space) => {
      let e_row = de('div', { class: 'flex flex-row items-center justify-between gap-20 pointer' });
      {
        let e = de('span', {
          textContent: `${space.name} (${space.username})`
        });
        e_row.append(e);
      }

      {
        let e = de('div', { class: 'surface-item icon' });
        e.innerHTML = icons.right_arrow;
        e_row.append(e)
      }
      return e_row;
    }

    let e_list = de('div', { class: 'flex flex-column gap-10 flex-1 bottom-mask-step pb-40 y-scroll' });
    e_column.append(e_list);
    section_state.e_loading = e_loading();
    e_list.append(section_state.e_loading);
    api_space.space_list(true /* admin_only */)
      .then(list => {
        if(list.length < 1)
        {
          e_list.append(e_empty());
        }
        else
        {
          let active_candidate;
          let current_space = api_space.getSpace()
          for(let i = 0; i < list.length; i++)
          {
            let space = list[i];
            let e = e_item(space);
            section_state.es.push(e);
            e.onclick = () => {
              set_active(e);
              select_space(space);
            }
            e_list.append(e);

            let is_current_space = current_space && space.username === current_space.username && space.name === current_space.name;
            if(!active_candidate || is_current_space)
            {
              active_candidate = {e, space};
            }
          }
          if(active_candidate)
          {
            set_active(active_candidate.e);
            select_space(active_candidate.space);
          }
        }
      }).finally(() => {
        e_list.removeChild(section_state.e_loading);
      });
  }
  let space_cols = [];

  function select_space(space)
  {
    for(let col of space_cols) {
      ev.removeChild(col);
    }

    space_cols = []

    {
      let e_column = de('div', { class: 'flex flex-column flex-350 pb-10 background-gray-8' });
      space_cols.push(e_column);

      {
        // header, users of space
        let e_row = de('div', { class: 'flex flex-row justify-between items-center pl-10 pr-10'
        });
        e_column.append(e_row);

        e_row.append(de('h3', { textContent: `options for ${space.name} (${space.username})` }));
      }

      let section_state = {
        anonymous_access: space.anonymous_access,
        listed: space.listed,
        grounded: space.grounded,
      };

      let update_form = () => {
        let {anonymous_access, listed, grounded,
          e_anon, e_listed, e_grounded, e_button} = section_state;
        let i = (b) => b ? icons.check : icons.circle;
        e_anon.innerHTML = i(anonymous_access);
        e_listed.innerHTML = i(listed);
        e_grounded.innerHTML = i(grounded);

        let any_change = (anonymous_access !== space.anonymous_access) ||
            (listed !== space.listed) || (grounded !== space.grounded);
        e_button.style.display = any_change ? '' : 'none';
      }

      {
        let e_form = de('div', { class: 'flex flex-column flex-1 gap-10 pt-10 pr-10 pb-20 pl-10 background-black ml-10 mr-10' });
        e_column.append(e_form);

        {
          let e_row = de('div', { class: 'flex flex-row items-center gap-5' });
          e_form.append(e_row);
          {
            let e = de('div', { class: 'surface-item icon' });
            e.innerHTML = icons.circle;
            e_row.append(e)
            e_row.classList.add('pointer');
            e_row.onclick = () => {
              section_state.anonymous_access = !section_state.anonymous_access;
              update_form();
            }
            section_state.e_anon = e;
          }
          {
            let e = de('span', { class: 'text-gray-80', textContent: 'public' });
            e_row.append(e);
          }
        }

        {
          let e_row = de('div', { class: 'flex flex-row items-center gap-5' });
          e_form.append(e_row);
          {
            let e = de('div', { class: 'surface-item icon' });
            e.innerHTML = icons.circle;
            e_row.append(e)
            e_row.classList.add('pointer');
            e_row.onclick = () => {
              section_state.listed = !section_state.listed;
              update_form();
            }
            section_state.e_listed = e;
          }
          e_row.append(
            de('span', { class: 'text-gray-80', textContent: 'listed,' }),
            de('span', { textContent: 'when public' })
          );
        }
        {
          let e_row = de('div', { class: 'flex flex-row items-center gap-5' });
          e_form.append(e_row);
          {
            let e = de('div', { class: 'surface-item icon' });
            e.innerHTML = icons.circle;
            e_row.append(e)
            e_row.classList.add('pointer');
            e_row.onclick = () => {
              section_state.grounded = !section_state.grounded;
              update_form();
            }
            section_state.e_grounded = e;
          }
          e_row.append(
            de('span', { class: 'text-gray-80', textContent: 'view only' }),
            de('span', { textContent: 'for guests' })
          );
        }
        {
          let e_row = de('div', {
            class: 'flex flex-row justify-center surface-item',
            style: {
              display: 'none',
            }
          });
          e_form.append(e_row);
          e_row.append(
            de('span', { textContent: 'save' }),
          );
          e_row.onclick = () => {
            api_space.set_options(space.id, {
              anonymous_access: section_state.anonymous_access,
              listed: section_state.listed,
              grounded: section_state.grounded
            }).then((res) => {
              if(res.res.ok) {
                space.anonymous_access = section_state.anonymous_access;
                space.listed = section_state.listed;
                space.grounded = section_state.grounded;
                select_space(space);
              } else {
                messages.error(res.json)
              }
            }, (err) => {
              console.log(err);
            });
          }
          section_state.e_button = e_row;
        }
      }
      update_form();
    }

    let permit_cols = view_space_permits(space, () => select_space(space))
    space_cols.push(...permit_cols)
    ev.append(...space_cols);
  }
  // END INVITE SECTION
  return ev;
}

function view_space_permits(space, refresh_space)
{
  let es = [];

  let cred_a = login.getCreds();

  let make_form = (permission_of) => (bag) =>
  {
    let waiting = false;
    function submit(space_perm)
    {
      let ids = [];
      for(const [id, permit] of bag.entries())
      {
        if(permit.permissions.indexOf(space_perm) < 0) {
          ids.push(id);
        }
      }
      if(ids.length < 1) {
        return;
      }

      if(waiting) {
        return;
      }
      waiting = true;

      api_permissions.change_permissions(
        space.id, permission_of, space_perm, ids
      ).then((res) => {
        if(res.res.ok) {
          refresh_space();
        } else {
          messages.error(res.json)
        }
      }, (err) => {
        console.error(err);
      }).finally(() => {
        waiting = false;
      });
    }

    let e_form = de('form', { class: 'flex flex-column' });

    let form_state = { selected_perm: '', e_submit: undefined };
    function select_perm(perm)
    {
      form_state.selected_perm = perm;
      let {e_submit} = form_state;
      e_submit.style.backgroundColor = '#39e470';
      e_submit.onclick = () => submit(form_state.selected_perm);
    }
    {
      let e_row = de('div', { class: 'flex flex-row items-center justify-end gap-10' });
      e_form.append(e_row);

      {
        let e = de('div', {
          class: 'surface-item icon background-red-80',
          style: {
            marginRight: 'auto',
          }
        });
        e.innerHTML = icons.removeuser;
        e.onclick = () => submit('none');
        e_row.append(e);
      }

      {
        let e = e_radio({
          label: "admin",
          id: "assign_perm_admin",
          name: "assign_perm",
          value: "room_admin",
          onchange: select_perm
        });
        e_row.append(e);
      }

      {
        let e = e_radio({
          label: "monteur",
          id: "assign_perm_mod",
          name: "assign_perm",
          value: "modify_room_fragments",
          onchange: select_perm
        });
        e_row.append(e);
      }

      {
        let e = e_radio({
          label: "guest",
          id: "assign_perm_view",
          name: "assign_perm",
          value: "view_room_fragments",
          onchange: select_perm
        });
        e_row.append(e);
      }

      {
        let e = de('div', {
          class: 'surface-item icon',
          style: {
            backgroundColor: '#777777',
          }
        });
        e.innerHTML = icons.check;
        form_state.e_submit = e;
        e_row.append(e);
      }
    }

    return e_form;
  }

  {
    let e_column = view_permits_list({
      page_size: 40,
      icon: 'user',
      can_change: true,
      head: `users of ${space.name} (${space.username})`,
      make_form: make_form('user'),
      invite_modal: {space, object_type: 'space', invite_type: 'user'},
      item_contents: (permit) => ([
        de('span', {class: 'text-gray-80', textContent: permit.username}),
        ' as ',
        de('span', {class: 'text-gray-80', textContent: text_permission(permit.permissions)})
      ]),
      is_selectable: (permit) => (space.created_by !== permit.id && cred_a.username !== permit.username),
      load_list: (after) => api_permissions.list_space_users(space.id, after),
    });
    es.push(e_column);
  }

  {
    let e_column = view_permits_list({
      page_size: 40,
      icon: 'group',
      can_change: true,
      head: `groups of ${space.name}(${space.username})`,
      make_form: make_form('group'),
      invite_modal: {space, object_type: 'space', invite_type: 'group'},
      item_contents: (permit) => ([
        de('span', {class: 'text-gray-80', textContent: `[group: ${permit.groupname}]`}),
        ' as ',
        de('span', {class: 'text-gray-80', textContent: text_permission(permit.permissions)})
      ]),
      is_selectable: (permit) => true,
      load_list: (after) => api_permissions.list_space_groups(space.id, after),
    });
    es.push(e_column);
  }

  return es;
}

function view_permits_list(a)
{
  // first column, users of space
  let e_column = de('div', { class: 'flex flex-column flex-350 background-gray-8' });

  let section_state = {s: 'ready', list: [],
    bag: new Map(),
    bag_e: new Map(),
    e_loadmore: undefined,
    e_edit: undefined,
  };

  function toggle_selection(permit, e)
  {
    let {bag, e_edit, e_count, e_form, bag_e, e_all} = section_state;

    if(bag.has(permit.id))
    {
      bag.delete(permit.id);
      e.innerHTML = icons.circle;
    }
    else
    {
      bag.set(permit.id, permit);
      e.innerHTML = icons.check;
    }

    e_count.textContent = bag.size;
    if(bag.size > 0)
    {
      e_edit.style.display = '';
      if(e_form)
      {
        let pe = e_form.parentElement;
        pe.removeChild(e_form)
        e_form = a.make_form(bag);
        pe.prepend(e_form)
        section_state.e_form = e_form;
      }
    }
    else
    {
      e_edit.style.display = 'none';
      if(e_form)
      {
        let pe = e_form.parentElement;
        pe.removeChild(e_form)
        section_state.e_form = undefined;
      }
    }

    if((bag.size > 0) && (bag_e.size > 1) && (bag_e.size !== bag.size))
    {
      e_all.style.display = '';
    }
    else
    {
      e_all.style.display = 'none';
    }
  }

  {
    // header, users of space
    let e_row = de('div', { class: 'flex flex-row justify-between items-center pl-10 pr-10'
    });
    e_column.append(e_row);

    e_row.append(de('h3', { textContent: a.head }));

    if(a.can_change)
    {
      let e = de('div', {
        class: 'surface-item icon ' + (a.icon === 'user' ? 'large2' : 'large6')
      });
      e.innerHTML = (a.icon === 'user' ? icons.userplus : icons.groupplus);
      e.onclick = show_invite_modal(a.invite_modal);
      e_row.append(e);
    }
  }

  let e_list = de('div', { class: 'flex flex-column gap-20 flex-1 y-scroll pt-10 pr-10 pb-20 pl-20 background-black ml-10 mr-10 bottom-mask' });
  e_column.append(e_list);

  let e_item = (permit) =>
  {
    let e_row = de('div', { class: 'flex flex-row items-center justify-between gap-20 minh-40' });
    {
      let e = de('div', {})
      e.append(
        ...a.item_contents(permit)
      );
      e_row.append(e);
    }

    if(a.can_change && a.is_selectable(permit))
    {
      let e = de('div', { class: 'surface-item icon radius-half' });
      e.innerHTML = icons.circle;
      e_row.append(e)
      e_row.classList.add('pointer');
      e_row.onclick = () => {
        toggle_selection(permit, e);
      }
      section_state.bag_e.set(permit.id, {permit, e});
      section_state.e_all.style.display = '';
    }
    return e_row;
  };


  function load_list(after)
  {
    section_state.s = 'loading';
    section_state.e_loading = e_loading();
    e_list.append(section_state.e_loading);
    a.load_list(after).then(res => {
      if(!res.res.ok) {
        messages.error(res.json)
        return;
      }
      let list = res.json;
      if(list.length < a.page_size)
      {
        section_state.s = 'complete';
        section_state.e_loadmore.style.display = 'none';
      }
      else
      {
        section_state.s = 'ready';
        section_state.e_loadmore.style.display = '';
      }
      section_state.list.push(...list);

      if(section_state.list.length < 1)
      {
        e_list.append(e_empty());
      }
      else
      {
        for(let item of list)
        {
          e_list.append(e_item(item));
        }
        e_list.append(section_state.e_loadmore)
      }
    }).finally(() => {
      e_list.removeChild(section_state.e_loading);
    });
  }

  {
    let e = de('button', {
      textContent: "load more",
      style: {
        display: 'none',
        padding: '5px',
        backgroundColor: '#ccc',
      }
    });
    e.onclick = () => {
      if(section_state.s !== 'ready') {
        return;
      }
      let list = section_state.list;
      if(list.length < 1) {
        return;
      }
      let last_id = list[list.length-1].id;
      load_list(last_id);
    };
    section_state.e_loadmore = e;
    e_list.append(e);
  }

  let e_bar = de('div', { class: 'flex flex-column gap-10 pr-10 pb-10 pl-10' });
  e_column.append(e_bar);

  if(a.can_change)
  {
    let e_row = de('div', { class: 'flex flex-row items-center gap-20 minh-40' });
    e_bar.append(e_row);
    {
      let e = de('div', { style: { marginRight: 'auto' } });
      let e_count = de('span', {class: 'text-gray-80', textContent: '0'});
      section_state.e_count = e_count;
      e.append(e_count, ' selected');
      e_row.append(e);
    }
    {
      let e = de('div', { class: 'surface-item icon',
        style: {
          display: 'none',
        }
      });
      e.innerHTML = icons.listcheck;
      e.onclick = () => {
        let {bag, bag_e} = section_state;
        for(const [id, o] of bag_e.entries())
        {
          if(!bag.has(id))
          {
            toggle_selection(o.permit, o.e);
          }
        }
      }
      section_state.e_all = e;
      e_row.append(e)
    }
    {
      let e = de('div', { class: 'surface-item icon',
        style: {
          display: 'none',
        }
      });
      e.innerHTML = icons.pen;
      e.onclick = () => {
        let {e_form, bag} = section_state;
        if(e_form)
        {
          e_bar.removeChild(e_form);
          section_state.e_form = undefined;
        }
        else
        {
          e_form = a.make_form(bag);
          e_bar.prepend(e_form);
          section_state.e_form = e_form;
        }
      }
      section_state.e_edit = e;
      e_row.append(e)
    }
  }
  load_list(0);
  return e_column;
}

function view_groups()
{
  let ev = de('div', { class: 'hide-on-narrow flex flex-row flex-1 gap-20 x-scroll mt-20 pl-20 pr-20 no-y-scroll' });
  let e_col = de('div', { class: 'flex flex-column flex-300 items-stretch overflow-hidden' });
  ev.append(e_col);
  {
    let waiting = false;
    let submit_create_group = (group_name) => {
      if(waiting) {
        return;
      }
      waiting = true;

      api_permissions.create_group(
        group_name
      ).then((res) => {
        if(res.res.ok) {
          set_tab('groups');
        } else {
          messages.error(res.json)
        }
      }, (err) => {
        console.error(err);
      }).finally(() => {
        waiting = false;
      });
    }

    let e_section = de('div', { class: 'flex flex-column' });
    e_col.append(e_section);

    e_section.append(de('h3', { textContent: 'create group' }));

    let e_form = de('form', { class: 'flex flex-column gap-10' });
    e_section.append(e_form);
    e_form.onsubmit = (e) => {
      e.preventDefault();
      let formData = new FormData(e.target);
      submit_create_group(formData.get('group_name'));
      return false;
    }

    {
      let e_label = de('label', {
        for: "group_name",
        textContent: "Group name:"
      });
      let e_inp = de('input', {
        id: "group_name",
        name: "group_name",
        placeholder: 'name',
        style: {
          padding: '10px',
          backgroundColor: '#222',
          color: '#ccc',
          border: 'none',
        }
      });
      e_form.append(e_label, e_inp);
    }
    {
      let e_button = de('button', {
        textContent: "create",
        style: {
          padding: '5px',
          backgroundColor: '#ccc',
        }
      });
      e_form.append(e_button);
    }
  }

  {
    let active = {};
    let set_active = (e) => {
      if(active.e)
      {
        active.e.classList.remove('background-gray-6', 'pl-10', 'text-white')
      }
      active.e = e;
      active.e.classList.add('background-gray-6', 'pl-10', 'text-white')
    }

    let e_item = (group) => {
      let e_row = de('div', { class: 'flex flex-row justify-between items-center' });
      {
        let e = de('span', { textContent: group.name });
        e_row.append(e);
      }

      let e = de('div', { class: 'surface-item icon' });
      e.innerHTML = icons.right_arrow;
      e_row.append(e)
      e_row.classList.add('pointer');
      e_row.onclick = () => {
        set_active(e_row);
        select_group(group);
      }
      if(!active.e) {
        e_row.onclick();
      }
      return e_row;
    }

    let section = make_list_section({
      page_size: 40,
      head: `groups`,
      e_item: e_item,
      req: (after) => api_permissions.list_groups(after)
    })
    e_col.append(section.e);
  }

  let group_cols = [];

  {
    let e_column = de('div', { class: 'flex flex-column items-center flex-1 justify-start gap-10' });
    ev.append(e_column);
    group_cols.push(e_column);

    e_column.append(de('h3', { textContent: 'select a group for administration' }));
  }

  let select_group = (group) => {
    for(let e of group_cols) {
      ev.removeChild(e);
    }
    group_cols = [];

    let is_group_admin = group.permissions.indexOf('group_admin') > -1;

    {
      let make_form = (bag) => {
        let waiting = false;
        function submit(group_perm)
        {
          let ids = [];
          for(const [id, permit] of bag.entries())
          {
            if(permit.permissions.indexOf(group_perm) < 0) {
              ids.push(id);
            }
          }
          if(ids.length < 1) {
            return;
          }

          if(waiting) {
            return;
          }
          waiting = true;
          api_permissions.change_group_permissions(
            group.id, group_perm, ids
          ).then((res) => {
            if(res.res.ok) {
              select_group(group);
            } else {
              messages.error(res.json)
            }
          }, (err) => {
            console.error(err);
          }).finally(() => {
            waiting = false;
          });
        }
        let e_form = de('form', { class: 'flex flex-column' });

        let form_state = { selected_perm: '', e_submit: undefined };
        function select_perm(perm)
        {
          form_state.selected_perm = perm;
          let {e_submit} = form_state;
          e_submit.style.backgroundColor = '#39e470';
          e_submit.onclick = () => submit(form_state.selected_perm);
        }
        {
          let e_row = de('div', { class: 'flex flex-row items-center gap-10' });
          e_form.append(e_row);

          {
            let e = de('div', { class: 'surface-item icon mr-auto background-red-80' });
            e.innerHTML = icons.removeuser;
            e.onclick = () => submit('none');
            e_row.append(e);
          }

          {
            let e = e_radio({
              label: "admin",
              id: "assign_perm_admin",
              name: "assign_perm",
              value: "group_admin",
              onchange: select_perm
            });
            e_row.append(e);
          }

          {
            let e = e_radio({
              label: "participant",
              id: "assign_perm_mod",
              name: "assign_perm",
              value: "group_user",
              onchange: select_perm
            });
            e_row.append(e);
          }

          {
            let e = de('div', {
              class: 'surface-item icon',
              style: {
                backgroundColor: '#777777',
              }
            });
            e.innerHTML = icons.check;
            form_state.e_submit = e;
            e_row.append(e);
          }
        }

        return e_form;
      }


      let cred_a = login.getCreds();
      let e_column = view_permits_list({
        page_size: 40,
        can_change: is_group_admin,
        icon: 'user',
        head: `users in group ${group.name}`,
        make_form: make_form,
        invite_modal: {group, object_type: 'group', invite_type: 'user'},
        item_contents: (permit) => ([
          de('span', {class: 'text-gray-80', textContent: permit.username}),
          ' as ',
          de('span', {class: 'text-gray-80', textContent: text_permission(permit.permissions)})
        ]),
        is_selectable: (permit) => (cred_a.username !== permit.username),
        load_list: (after) => api_permissions.list_group_users(group.id, after),
      });
      ev.append(e_column);
      group_cols.push(e_column);
    }

    {
      let e_column = de('div', { class: 'flex flex-column flex-350 background-gray-8' });
      ev.append(e_column);
      group_cols.push(e_column);

      let section_state = {
        bag_all: new Set(),
        bag: new Set()
      };

      let waiting = false;
      let remove_spaces_from_group = () => {
        let {bag} = section_state;
        if(waiting) {
          return;
        }
        waiting = true;
        api_permissions.assign_spaces_to_group(
          group.id,
          'none',
          [...bag]
        ).then((res) => {
          if(res.res.ok) {
            select_group(group);
          } else {
            messages.error(res.json)
          }
        }, (err) => {
          console.error(err);
        }).finally(() => {
          waiting = false;
        });
      }

      let show_or_hide_assignment_column = () => {
        if(group_cols.length < 3)
        {
          let e = spaces_list_for_group_assignment(group,
            section_state.bag_all,
            () => select_group(group));
          ev.append(e)
          group_cols.push(e);
          ev.scrollBy(400, 0);
        }
        else
        {
          ev.removeChild(group_cols.pop());
        }
      }

      let toggle_selection = (spaceid, e) => {
        let {bag, e_count, e_edit} = section_state;
        if(bag.has(spaceid))
        {
          bag.delete(spaceid);
          e.innerHTML = icons.circle;
        }
        else
        {
          bag.add(spaceid);
          e.innerHTML = icons.check;
        }

        e_count.textContent = bag.size;
        if(bag.size > 0) {
          e_edit.style.display = '';
        } else {
          e_edit.style.display = 'none';
        }
      }

      {
        // header, users of space
        let e_row = de('div', { class: 'flex flex-row justify-between items-center pl-10 pr-10' });
        e_column.append(e_row);

        {
          let e = de('h3', { textContent: `spaces of group ${group.name}` });
          e_row.append(e);
        }

        if(is_group_admin)
        {
          let e = de('div', { class: 'surface-item icon' });
          e.innerHTML = icons.plus;
          e.onclick = show_or_hide_assignment_column;
          e_row.append(e);
        }
      }

      {
        let e_list = de('div', { class: 'flex flex-column flex-1 gap-20 pl-20 pt-10 pr-10 ml-10 mr-10 background-black y-scroll bottom-mask' });
        e_column.append(e_list);

        let e_item = (permit) => {
          let e_row = de('div', { class: 'flex flex-row justify-between' });
          {
            let e = de('div', { class: 'flex flex-row items-center gap-5' });
            let e_link = de('a', {
              href: "/?user=" + permit.space.username + "&room=" + permit.space.name,
              class: 'standalone-icon hover-brighter',
              target: 'blank',
              style: {
                display: 'inline-block',
                width: '1em',
                height: '1em',
                marginRight: '0.2em',
              }
            });
            e_link.innerHTML = icons.openlink
            e.append(
              e_link,
              de('span', {class: 'text-gray-80', textContent: `${permit.space.name} (${permit.space.username})`}),
              ' as ',
              de('span', {class: 'text-gray-80', textContent: text_permission(permit.permissions)})
            );
            e_row.append(e);
          }

          if(is_group_admin)
          {
            let e = de('div', {
              class: 'surface-item icon radius-half',
            });
            e.innerHTML = icons.circle;
            e_row.append(e);
            e_row.classList.add('pointer');
            e_row.onclick = () => { toggle_selection(permit.space.id, e) }
          }
          return e_row;
        }

        section_state.e_loading = e_loading();
        e_list.append(section_state.e_loading)
        api_permissions.group_space_list(group.id)
          .then((res) => {
            if(!res.res || !res.res.ok) {
              console.error("Problem while loading list of spaces. Response object:");
              console.error(res);
              messages.error("Sorry, there was an error loading your list spaces");
              return [];
            }
            console.log("Got /room/group_permissions/");

            let permits = res.json
            for (let permit of permits)
            {
              section_state.bag_all.add(permit.space.id);
              e_list.append(e_item(permit));
            }
          })
          .finally(() => {
            e_list.removeChild(section_state.e_loading);
          });
      }

      let e_bar = de('div', { class: 'flex flex-column pl-10 pr-10 pb-10' });
      e_column.append(e_bar);

      if(is_group_admin)
      {
        let e_row = de('div', { class: 'flex flex-row items-center gap-20 justify-between minh-40' });
        e_bar.append(e_row);

        {
          let e = de('div', {});
          let e_count = de('span', {class: 'text-gray-80', textContent: '0'});
          section_state.e_count = e_count;
          e.append(e_count, ' selected');
          e_row.append(e);
        }
        {
          let e = de('div', {
            class: 'surface-item icon background-red-80',
            style: {
              display: 'none',
            }
          });
          e.innerHTML = icons.xmark;
          e.onclick = remove_spaces_from_group;

          section_state.e_edit = e;
          e_row.append(e)
        }
      }
    }
  }

  return ev;
}

function spaces_list_for_group_assignment(group, bag_group_spaces, refresh_group)
{
  let assign_form = {
    bag: new Set()
  }

  let waiting = false;
  function submit_space_assignments(assign_perm)
  {
    if(waiting) {
      return;
    }
    waiting = true;
    api_permissions.assign_spaces_to_group(
      group.id,
      assign_perm,
      [...assign_form.bag]
    ).then((res) => {
      if(res.res.ok) {
        refresh_group();
      } else {
        messages.error(res.json)
      }
    }, (err) => {
      console.error(err);
    }).finally(() => {
      waiting = false;
    });
  }

  function toggle_selection(spaceid, e)
  {
    let {bag, e_count, e_button} = assign_form;
    if(bag.has(spaceid))
    {
      bag.delete(spaceid);
      e.innerHTML = icons.circle;
    }
    else
    {
      bag.add(spaceid);
      e.innerHTML = icons.check;
    }

    if(bag.size > 0)
    {
      e_button.removeAttribute('disabled');
    }
    else
    {
      e_button.setAttribute('disabled', true);
    }
    e_count.textContent = bag.size;
  }

  let e_column = de('div', { class: 'flex flex-column flex-350 background-gray-8 pb-20' });

  {
    let e = de('h3', {
      class: 'ml-10',
      textContent: `assign spaces to [group: ${group.name}]`,
    });
    e_column.append(e);
  }

  let e_form = de('form', { class: 'flex flex-column ml-10 mr-10 gap-10' });
  e_column.append(e_form);
  e_form.onsubmit = (e) => {
    e.preventDefault();
    let formData = new FormData(e.target);
    submit_space_assignments(formData.get('assign_perm'));

    return false;
  }

  {
    let e_row = de('div', { class: 'flex flex-row justify-between items-center' });
    e_form.append(e_row);

    {
      let e = de('button', {
        textContent: "assign",
        disabled: 'true',
        style: {
          padding: '5px 10px',
          backgroundColor: '#ccc',
        }
      });
      e_row.append(e);
      assign_form.e_button = e;
    }

    {
      let e = e_radio({
        label: "admin",
        id: "assign_perm_admin",
        name: "assign_perm",
        value: "room_admin",
      });
      e_row.append(e);
    }

    {
      let e = e_radio({
        label: "monteur",
        id: "assign_perm_mod",
        name: "assign_perm",
        value: "modify_room_fragments",
      });
      e_row.append(e);
    }

    {
      let e = e_radio({
        label: "guest",
        id: "assign_perm_view",
        name: "assign_perm",
        value: "view_room_fragments",
        checked: true
      });
      e_row.append(e);
    }
  }

  {
    let e_row = de('div', { class: 'flex flex-row gap-10' });
    e_form.append(e_row);
    let e_count = de('span', {class: 'text-gray-80', textContent: '0'});
    assign_form.e_count = e_count;
    e_row.append(e_count, ' selected');
  }

  function e_item(space)
  {
    let e_row = de('div', { class: 'flex flex-row items-center justify-between gap-20 pointer' });

    {
      let e = de('span', { textContent: `${space.name} (${space.username})` });
      e_row.append(e);
    }

    {
      let e = de('div', { class: 'surface-item icon radius-half', });
      e.innerHTML = icons.circle;
      e_row.append(e)
      e_row.onclick = () => { toggle_selection(space.id, e); };
    }
    return e_row;
  }

  let e_list = de('div', { class: 'flex flex-column flex-1 gap-10 pt-10 pr-10 pb-20 pl-20 mt-10 ml-10 mr-10 y-scroll bottom-mask background-black' });
  e_column.append(e_list);

  assign_form.e_loading = e_loading();
  e_list.append(assign_form.e_loading);
  api_space.space_list(true)
    .then((list) => {
      for(let i = 0; i < list.length; i++)
      {
        let space = list[i];
        if(bag_group_spaces.has(space.id)) {
          continue;
        }
        e_list.append(e_item(space));
      }
    })
    .finally(() => {
      e_list.removeChild(assign_form.e_loading);
    });
  return e_column;
}

function show_invite_modal ({space, group, object_type, invite_type})
{
  return () =>
  {
    let waiting = false;
    function submit_create_invite(invite_perm, invite_invitee, invite_message)
    {
      if(waiting) {
        return;
      }
      waiting = true;

      let object_id;
      if(object_type === 'space') {
        object_id = space.id;
      } else if(object_type === 'group') {
        object_id = group.id;
      }

      api_permissions.create_invite(
        object_type,
        object_id,
        invite_perm,
        invite_type,
        invite_invitee,
        invite_message
      ).then((res) => {
        if(res.res.ok) {
          close_modal();
          messages.message('Invite sent!');
        } else {
          messages.error(res.json)
        }
      }, (err) => {
        console.error(err);
      }).finally(() => {
        waiting = false;
      });
    }

    let oe = de('div', {
      style: {
        zIndex: 5,
        width: '100vw',
        height: '100vh',
        background: '#000000aa',
        top: '0',
        left: '0',
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }
    })
    document.body.append(oe);
    let close_modal = stack_modal(() => {
      document.body.removeChild(oe);
    });

    let be = de('div', {
      class: 'permissions-ui',
      style: {
        background: '#FFFE',
        position: 'relative',
        border: '4px solid #000D',
        fontSize: '1.5rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '20px',
        color: '#000',
        fontFamily: 'Arial',
        boxSizing: 'border-box',
        padding: '3rem',
      }
    })
    oe.append(be);

    // close button
    {
      let e = de('div', {
        class: 'surface-item icon',
        style: {
          position: 'absolute',
          top: '-10px',
          left: '-10px',
        }
      });
      e.onclick = close_modal;
      e.innerHTML = icons.xmark;
      be.append(e);
    }

    {
      let t = '';
      if(object_type === 'space') {
        t = `invite to ${space.name}(${space.username})`;
      } else {
        t = `invite to [group: ${group.name}]`
      }
      let e = de('span', {
        textContent: t,
      });
      be.append(e);
    }

    let e_form = de('form', { class: 'flex flex-column gap-20'
    });
    be.append(e_form);

    e_form.onsubmit = (e) => {
      e.preventDefault();
      let formData = new FormData(e.target);

      submit_create_invite(
        formData.get('invite_perm'),
        formData.get('invite_invitee'),
        formData.get('invite_message')
      );

      return false;
    }


    {
      let e_inp = de('input', {
        id: "invite_invitee",
        name: "invite_invitee",
        placeholder: invite_type === 'user' ? 'username' : 'group name',
        style: {
          borderColor: 'black',
          borderStyle: 'solid',
          textAlign: 'center',
          fontSize: '1.5rem',
          padding: '0.5rem',
        }
      });
      e_form.append(e_inp);
    }

    {
      let e_inp = de('textarea', {
        id: "invite_text",
        rows: 3,
        name: "invite_message",
        maxlength: '140',
        placeholder: 'message...',
        style: {
          borderColor: 'black',
          borderStyle: 'solid',
          textAlign: 'center',
          fontSize: '1.5rem',
          padding: '0.5rem',
        }
      });
      e_form.append(e_inp);
    }

    let e_row = de('div', { class: 'flex flex-column self-center' });
    e_form.append(e_row);

    if(object_type === 'space')
    {
      {
        let e = e_radio({
          label: "admin",
          id: "invite_perm_admin",
          name: "invite_perm",
          value: "room_admin",
        });
        e_row.append(e);
      }

      {
        let e = e_radio({
          label: "monteur",
          id: "invite_perm_mod",
          name: "invite_perm",
          value: "modify_room_fragments",
        });
        e_row.append(e);
      }

      {
        let e = e_radio({
          label: "guest",
          id: "invite_perm_view",
          name: "invite_perm",
          value: "view_room_fragments",
          checked: true
        });
        e_row.append(e);
      }
    }
    else
    {
      {
        let e = e_radio({
          label: "admin",
          id: "invite_perm_admin",
          name: "invite_perm",
          value: "group_admin",
        });
        e_row.append(e);
      }

      {
        let e = e_radio({
          label: "participant",
          id: "invite_perm_user",
          name: "invite_perm",
          value: "group_user",
          checked: true,
        });
        e_row.append(e);
      }
    }


    {
      let e_button = de('button', {
        textContent: "send",
        style: {
          fontSize: '1.5rem',
          padding: '0.5rem',
          backgroundColor: '#39e470',
          color: 'black',
          alignSelf: 'center',
        }
      });
      e_form.append(e_button);
    }
  }
}

function show_decline_modal ({invite})
{
  return () =>
  {
    let oe = de('div', {
      style: {
        zIndex: 5,
        width: '100vw',
        height: '100vh',
        background: '#000000aa',
        top: '0',
        left: '0',
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }
    })
    document.body.append(oe);
    let close_modal = stack_modal(() => {
      document.body.removeChild(oe);
    });

    let waiting = false;
    function submit_invite_response(decline_message)
    {
      if(waiting) {
        return;
      }
      waiting = true;

      api_permissions.respond_to_invitation(invite.id, decline_message)
        .then((res) => {
          if(res.res.ok)
          {
            messages.message('Invite declined!');
            close_modal();
            set_tab('invitations');
          }
          else {
            messages.error(res.json)
          }
        }, (err) => {
          console.error(err);
        }).finally(() => {
          waiting = false;
        });
    }

    let be = de('div', {
      class: 'permissions-ui',
      style: {
        background: '#FFFE',
        position: 'relative',
        border: '4px solid #000D',
        fontSize: '1.5rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '20px',
        color: '#000',
        fontFamily: 'Arial',
        boxSizing: 'border-box',
        padding: '3rem',
      }
    })
    oe.append(be);

    // close button
    {
      let e = de('div', {
        class: 'surface-item icon',
        style: {
          position: 'absolute',
          top: '-10px',
          left: '-10px',
        }
      });
      e.onclick = close_modal;
      e.innerHTML = icons.xmark;
      be.append(e);
    }

    {
      let e = de('span', {
        style: {
          textAlign: 'center',
        }
      });
      e.append(`decline invitation`, de('br'), `to join ${invite.invite_to}`);
      be.append(e);
    }

    let e_form = de('form', { class: 'flex flex-column gap-20' });
    be.append(e_form);

    e_form.onsubmit = (e) => {
      e.preventDefault();
      let formData = new FormData(e.target);
      submit_invite_response(formData.get('decline_message'));
      return false;
    }

    {
      let e_inp = de('textarea', {
        id: "decline_text",
        rows: 3,
        name: "decline_message",
        maxlength: '140',
        placeholder: 'message...',
        style: {
          borderColor: 'black',
          borderStyle: 'solid',
          textAlign: 'center',
          fontSize: '1.5rem',
          padding: '0.5rem',
        }
      });
      e_form.append(e_inp);
    }

    {
      let e_button = de('button', {
        textContent: "decline",
        style: {
          fontSize: '1.5rem',
          padding: '0.5rem',
          backgroundColor: '#39e470',
          color: 'black',
          alignSelf: 'center',
        }
      });
      e_form.append(e_button);
    }
  }
}
