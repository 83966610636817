const automations = [];


export function loop() {
  const now = new Date().getTime();
  let i = automations.length
  while (i--) {
    const automation = automations[i];
    const progress = Math.min(
      1.0,
      (now - automation.startTime) / automation.duration
    );
    if(automation.variableName) {
      // do not automate grabbed objects (fragments)
      if(automation.object.grabbed) {
        automations.splice(i, 1);
      }
      else {
        const newValue = automation.startValue +
            progress * (automation.targetValue - automation.startValue);
        automation.object[automation.variableName] = newValue;
      }
    }
    else if(automation.callback) {
      const newValue = automation.startValue +
          progress * (automation.targetValue - automation.startValue);
      const shouldCancel = automation.callback(newValue);
      if(shouldCancel) {
        automations.splice(i, 1);
      }
    }
    const finished = progress >= 1.0;
    if (finished) {
      automations.splice(i, 1);
    }
  }
}

export function automateValue(object, variableName, duration, targetValue) {
  automations.unshift({
    object: object,
    variableName: variableName,
    duration: duration,
    targetValue: targetValue,
    startTime: new Date().getTime(),
    startValue: object[variableName]
  });
}

export function automateCallback(callback, duration, startValue, targetValue) {
  automations.unshift({
    callback: callback,
    duration: duration,
    startValue: startValue,
    targetValue: targetValue,
    startTime: new Date().getTime()
  });
}
