import * as api from './api.js';
import * as multiuser from '../multiuser.js';
import * as clipboard from '../clipboard.js';

let _authChangeCallback = undefined;
let _creds = null;

function authChange() {
  _creds = null;
  if(_authChangeCallback)
    _authChangeCallback();
  multiuser.sendAuth();
}

export function onAuthChange(fn) {
  _authChangeCallback = fn;
}

export let _credStore = window.localStorage;

export function getUsername() {
  return _credStore.getItem('nota_username');
}
export function getUserId() {
  return _credStore.getItem('nota_id');
}
export function getToken() {
  return _credStore.getItem('nota_token');
}
export function isStaff() {
  return _credStore.getItem('nota_user_is_staff');
}
export function getCreds() {
  if(_creds === null) {
    _creds = {};
    _creds.token = _credStore.getItem('nota_token') || "";
    _creds.username = _credStore.getItem('nota_username') || "b";
    _creds.id = _credStore.getItem('nota_id') || "1";
    _creds.is_staff = _credStore.getItem('nota_user_is_staff') || false;
  }
  return _creds;
}
export function setCreds(creds) {
  _credStore.setItem('nota_token', creds.token || "");
  _credStore.setItem('nota_username', creds.username || "");
  _credStore.setItem('nota_id', creds.id || "");
  _credStore.setItem('nota_user_is_staff', creds.is_staff || false);
  // reset cached creds object
  _creds = null;
}

export function loggedIn() {
  let creds = getCreds();
  return creds.token != '' &&
    creds.id != '1' &&
    creds.username !== 'AnonymousUser';
}
export function logout() {
  setCreds({
    token: '',
    username: 'AnonymousUser',
    id: 1
  });
  authChange();
  clipboard.clearClipboard();
}

export async function login(user, password) {
  console.log('log');
  try {
    const res = await api.post(
      'accounts/login/',
      {
        username: user,
        password: password
      }
    );
    if(res.res.ok) {
      let creds = await res.json;
      setCreds(creds);
    }
    authChange();
    return res;
  }
  catch(e) {
    throw e;
  }
}

export async function test() {
  let resp = await api.auth_post('accounts/test/', {});
  //msg.message(JSON.stringify(resp));
}

export async function change_password(current_password, new_password) {
  let response = await api.auth_post('accounts/change_password/', {
    current_password: current_password,
    new_password: new_password
  });
  return response;
}
