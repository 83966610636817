import * as automation from './automation.js';
import * as positions from './positions.js';
import * as main from './main.js';
import {renderer} from './main.js';
import {getUserId,getToken,loggedIn} from './api/login.js';

let sendPositionInterval;

let uid = Math.random() + "" + Math.random();
// this should be constant and the same for all clients at the moment
// otherwise each client would need to broadcast their interval,
// to enable interpolation
export const USER_POSITION_SEND_INTERVAL = 1000;
export let other_user_positions = {};
let other_user_positions_timeouts = {};
let sockURL = "";
export function setURL(spaceName) {
  let protocol = window.location.protocol === 'https:' ? 'wss' : 'ws';
  sockURL = protocol + '://'
      + window.location.host + '/'
      + 'ws/refresh/' + spaceName + '/';
}

let multiuserSocket = null;

export function init(spaceName) {
  setURL(spaceName);

  console.log("socket URL: " + sockURL);

  if(multiuserSocket !== null) {
    // use code 4000 to signal manual closing
    multiuserSocket.close(4000, "manual");
  }
  other_user_positions = {};
  other_user_positions_timeouts = {};
  
  initSocket();
}

export function sendAuth() {
  sendMessage({
    type: "auth",
  });
}

/*
 * send a json message via the websocket connection,
 * on failure re-attempt later
 */
function sendMessage(json) {
  if(loggedIn()) {
    json.token = getToken();
    json.user_id = getUserId();
  }
  else {
    // dont try to send messages that need auth
    // --> only allow connecting and sending view position
    if(json.type !== 'c' && json.content_type !== 'v') {
      return;
    }
  }
  try {
    if(multiuserSocket.readyState === multiuserSocket.OPEN) {
      multiuserSocket.send(JSON.stringify(json));
    }
  }
  catch(e) {
    console.error(e);
    setTimeout(()=>sendMessage(json), 1000);
  }
}

export function sendFragmentUpdatedInfo(fragments) {
  let ids = fragments.map(frag => frag.id).filter(id => id >= 0);
  sendFragmentUpdatedInfoByIDs(ids);
}
export function sendFragmentUpdatedInfoByIDs(fragment_ids) {
  sendMessage({
    type: "b",
    content_type: "u",
    tab_id: uid,
    fragment_ids: fragment_ids
  });
}
export function sendFragmentRemovedInfo(fragments) {
  let ids = fragments.map(frag => frag.id).filter(id => id >= 0);
  sendFragmentRemovedInfoByIDs(ids);
}
export function sendFragmentRemovedInfoByIDs(fragment_ids) {
  sendMessage({
    type: "b",
    content_type: "r",
    tab_id: uid,
    fragment_ids: fragment_ids
  });
}
export function sendFragmentAddedInfo(fragments) {
  let ids = fragments.map(frag => frag.id).filter(id => id >= 0);
  sendFragmentAddedInfoByIDs(ids);
}
export function sendFragmentAddedInfoByIDs(fragment_ids) {
  sendMessage({
    type: "b",
    content_type: "n",
    tab_id: uid,
    fragment_ids: fragment_ids
  });
}

export function refreshFragments(fragments) {
  api_fragments.get_list(fragments).then(function(pers){
    fragments.forEach(function(frag) {
      let fragPers = pers[frag.id];
      const initCall = false;
      const sync = false;
      if(fragPers) {
        fragPers.persistent = true;
        Object.getPrototypeOf(frag).constructor.setFragmentData(
            frag, fragPers, initCall, sync);
      }
    });
  });
}

function initSocket() {
  multiuserSocket = new WebSocket(sockURL);

  multiuserSocket.onopen = function(evt) {
    console.log("opened websocket");
    sendAuth();
    // send auth info periodically
    setInterval(sendAuth, 10000);
    sendMessage({
      type: "c",
      tab_id: uid
    });
  }
  
  multiuserSocket.onclose = function(evt) {
    console.log(
        "closed websocket, code: " + evt.code +
        " reason: '" + evt.reason + "'");
    // reconnect if not manually closed!
    if(evt.code !== 1000 && evt.code !== 4000) {
      setTimeout(function() {
        initSocket();
      }, 3000);
    }
  }

  function clearUserPositionTimeout(tab_id) {
    if(typeof other_user_positions_timeouts[tab_id] !== 'undefined') {
      clearTimeout(other_user_positions_timeouts[tab_id]);
      delete other_user_positions_timeouts[tab_id];
    }
  }
  function deleteUserPosition(tab_id) {
    delete other_user_positions[tab_id];
    clearUserPositionTimeout(tab_id);
    console.log("deleted");
  }
  multiuserSocket.onmessage = function(evt) {
    let json = JSON.parse(evt.data);
    let contentType = json.content_type;
    if(contentType === 'v' && json.tab_id !== uid) {
      // initialise empty user position object
      if(!other_user_positions[json.tab_id]) {
        other_user_positions[json.tab_id] = {};
      }
      // set new target position
      other_user_positions[json.tab_id].target = json.view;
      // when a new target position is received, the current drawing
      // position will be used for calculating how far the user position
      // animation should move to arrive at the new position
      if(other_user_positions[json.tab_id].current) {
        other_user_positions[json.tab_id].previous = {
          x: other_user_positions[json.tab_id].current.x,
          y: other_user_positions[json.tab_id].current.y,
          w: other_user_positions[json.tab_id].current.w,
          h: other_user_positions[json.tab_id].current.h
        };
      }
      else {
        other_user_positions[json.tab_id].previous = json.view;
        other_user_positions[json.tab_id].current = {
          x: json.view.x,
          y: json.view.y,
          w: json.view.w,
          h: json.view.h
        };
      }
      // if a timeout is set for that user, clear it
      clearUserPositionTimeout(json.tab_id);
      // set a new timeout, to remove the other user position if no new
      // position updated keep arriving
      other_user_positions_timeouts[json.tab_id] = setTimeout(function() {
        deleteUserPosition(json.tab_id);
      }, 7000);
    }
    else if(contentType === 'd' && json.tab_id !== uid) {
      deleteUserPosition(json.tab_id);
    }
    else if(contentType === 'u' && json.tab_id !== uid) {
      positions.refreshFragmentsByIDs(json.fragment_ids, json.tab_id);
    }
    else if(contentType === 'r' && json.tab_id !== uid) {
      positions.removeFragmentsByIDs(json.fragment_ids, json.tab_id);
    }
    else if(contentType === 'n' && json.tab_id !== uid) {
      positions.loadNewFragmentsByIDs(json.fragment_ids, json.tab_id);
    }
    else if(json.connections_count) {
      window.nota_storage.connections_count = json.connections_count;
    }
  }

  if(sendPositionInterval !== null) {
    clearInterval(sendPositionInterval);
  }
  sendPositionInterval = setInterval(function() {
    if(multiuserSocket.readyState === multiuserSocket.OPEN) {
      let view = positions.getView(renderer.width, renderer.height);
      let sc = positions.getGlobalScaleLowPrec();
      view.x = -view.x / sc;;
      view.y = -view.y / sc;
      sendMessage({
        type: "b",
        content_type: "v",
        tab_id: uid,
        view: view,
      });
    }
  }, USER_POSITION_SEND_INTERVAL);
}

export function animateChanges(fragment, oldValues, tab_id) {
  const rend = main.getSketch();
  const tabColor = tab_id.toHSL();
  automation.automateCallback(
    automatedValue => {
      rend.push();
      rend.colorMode(rend.HSL);
      rend.strokeWeight(3);
      rend.fill(tabColor.h, tabColor.s, tabColor.l, automatedValue * 0.4);
      rend.stroke(tabColor.h, tabColor.s, tabColor.l, automatedValue * 0.7);
      let x = fragment.screenX();
      let y = fragment.screenY();
      let w = fragment.screenW();
      let h = fragment.screenH();
      rend.translate(x, y);
      rend.rotate(fragment.rotation);
      rend.rect(0, 0, w, h);
      rend.pop();
    },
    1000,
    1,
    0
  );
  const newScale = fragment._scale;
  const newX = fragment.x;
  const newY = fragment.y;
  fragment._scale = oldValues.scale;
  automation.automateValue(
    fragment,
    '_scale',
    200,
    newScale
  );
  fragment.x = oldValues.x;
  automation.automateValue(
    fragment,
    'x',
    200,
    newX
  );
  fragment.y = oldValues.y;
  automation.automateValue(
    fragment,
    'y',
    200,
    newY
  );
}

export function indicateFragmentDeletion(position, tab_id) {
  const tabColor = tab_id.toHSL();
  const rend = main.getSketch();
  automation.automateCallback(
    automatedValue => {
      rend.push()
      rend.colorMode(rend.HSL);
      rend.strokeWeight(3);
      rend.fill(tabColor.h, tabColor.s, tabColor.l, automatedValue * 0.4);
      rend.stroke(tabColor.h, tabColor.s, tabColor.l, automatedValue * 0.7);
      rend.rect(
        position.x,
        position.y,
        position.w,
        position.h
      );
      rend.pop()
    },
    1000,
    1,
    0
  );
}

export function indicateFragmentChange(fragment, tab_id) {
  const tabColor = tab_id.toHSL();
  const rend = main.getSketch();
  automation.automateCallback(
    automatedValue => {
      rend.push()
      rend.colorMode(rend.HSL);
      rend.strokeWeight(3);
      rend.fill(tabColor.h, tabColor.s, tabColor.l, automatedValue * 0.4);
      rend.stroke(tabColor.h, tabColor.s, tabColor.l, automatedValue * 0.7);
      rend.rect(
        fragment.screenX(),
        fragment.screenY(),
        fragment.screenW(),
        fragment.screenH()
      );
      rend.pop()
    },
    1000,
    1,
    0
  );
}
