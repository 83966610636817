import {OngoingChange} from './OngoingChange.js';
import {_updateFragments} from './utilities.js';
import * as positions from '../positions.js';
import * as gained_ids from '../gained_ids.js';
import * as messages from '../messages';

/** MoveChange is combining ScaleChange and PositionChange as one OngoingChange
*/
export class CombinedChange extends OngoingChange {
  constructor(fragments, dx, dy, factor=null, point=null, angle=null, timeout=-1) {
    super(fragments, timeout);
    let self = this;
    this.hasScaled = false;
    // translation related
    // init
    this.dx = 0;
    this.dy = 0;
    // change
    if(dx != null && dy != null) {
      this.translate(dx, dy);
    }

    // scale related
    // init
    self.diff = {};
    let changeId = 0;
    fragments.forEach(function(frag) {
      let id = frag.id;
      self.diff[id] = {};
      self.diff[id].x = 0;
      self.diff[id].y = 0;
      self.diff[id].rotation = 0;
      self.diff[id].scale = 1;
    });

    // change
    if(factor != null && point != null) {
      self.scale(factor, point);
    }
    if(angle !== null) {
      this.rotate(angle)
    }
  }
  getAccumulatedDiff(id) {
    if(!this.diff[id]) {
      if(gained_ids.has(id))
      {
        let temp_id = gained_ids.get(id);
        this.diff[id] = this.diff[temp_id];
      }
      else
      {
        let err = 'can not deal with undo stack, please report to developers.';
        messages.error(err);
        throw err;
      }
    }
    return this.diff[id];
  }
  scale(factor, point) {
    let self = this;
    this.hasScaled = true;
    self._fragments.forEach(function(frag) {
      let diff = positions.scaleFragment(frag, factor, point);
      let d = self.getAccumulatedDiff(frag.id);
      d.x += diff.x;
      d.y += diff.y;
      d.scale *= factor;
    });
  }
  translate(dx, dy) {
    let globalDiff = positions.screenToGlobalDiff({dx: dx, dy: dy})
    let gdx = globalDiff.dx;
    let gdy = globalDiff.dy;
    this.dx += gdx;
    this.dy += gdy;
    this._fragments.forEach(function(fragment) {
      positions.moveFragment(fragment, gdx, gdy);
    });
  }
  rotate(angle) {
    let self = this;
    self._fragments.forEach(function(frag) {
      let d = self.getAccumulatedDiff(frag.id);
      d.rotation += angle;
      frag.rotation += angle;
    });
  }
  progress(dx=null, dy=null, factor=null, point=null, angle=null) {
    if(dx != null && dy != null) {
      this.translate(dx, dy);
    }
    if(factor !== null && point !== null) {
      this.scale(factor, point);
    }
    if(angle !== null)
      this.rotate(angle);
  }
  s_finalize(dx=null, dy=null) {
    // translate
    if(dx !== null && dy !== null) {
      this.translate(dx, dy);
    }
    // scale
    super.s_finalize();
    _updateFragments(this._fragments);
  }
  undo() {
    let self = this;
    this._fragments.forEach(function(frag) {
      // translate
      positions.moveFragment(frag, -self.dx, -self.dy);
      // scale
      let d = self.getAccumulatedDiff(frag.id);
      frag.setIndividualScale(frag.getIndividualScale() / d.scale);
      frag.x += d.x;
      frag.y += d.y;
      frag.rotation -= d.rotation;
    });
    _updateFragments(this._fragments);
  }
  redo() {
    let self = this;
    this._fragments.forEach(function(frag) {
      // translate
      positions.moveFragment(frag, self.dx, self.dy);
      // scale
      let d = self.getAccumulatedDiff(frag.id);
      frag.setIndividualScale(frag.getIndividualScale() * d.scale);
      frag.x -= d.x;
      frag.y -= d.y;
      frag.rotation += d.rotation;
    });
    _updateFragments(this._fragments);
  }
}
