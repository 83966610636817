import {BaseFragment} from './base_fragment.js';
import * as settings from '../settings.js';
import * as ui from '../userinterface/ui.js';
import * as space from '../space.js';

const RESOLUTIONS = [4000, 2000, 1000, 500, 250, 125];

class ImageFragment extends BaseFragment {
  constructor(p, x, y, type, data, persistent=false) {
    super(p, x, y, type, data, persistent);
    let self = this;
    this._disableTimeout = null;
    this._shrinked = false;
    this.resolutionLimited = true;
    this.url = data.url || this.url || '';
    this.filehashes = null;
    self._borderShown = false;
    self._hasBorder = false;

    this.sizeIsStored = false;
    this.invalidResolutions = {}
    //this._tintedImage = null;
    this._maskImage = null;
    this._fileExtension = "";
    this._hasImageTransparency = null; // We do not know yet if the image has transparency
    //this._tempTintedImage = null;
    this._lastCollosionResult = false;
  }
  calcRes() {
    let curW = Math.abs(this.screenW());
    let curH = Math.abs(this.screenH());
    let curMax = Math.max(curW, curH);
    // let max = Math.max(this.original_w, this.original_h);
    let r = null;

    for(let i = 0; i < RESOLUTIONS.length; i++) {
      let res = RESOLUTIONS[i];
      if(res > curMax) {
        r = res;
      }
    }

    let currentSpaceMaxRes = space.getMaxImageLength();
    if(currentSpaceMaxRes != null) {
      // set resolution to smallest valid resolution
      if(r === null || r > currentSpaceMaxRes) {
        r = 125;
        for(let i = 0; i < RESOLUTIONS.length; i++) {
          let res = RESOLUTIONS[i];
          if(res <= currentSpaceMaxRes) {
            r = res;
            break;
          }
        }
      }
    }

    return r;
  }

  debounceMipMapImage() {
    // TODO: re-check !!!
    let self = this;
    if(this._loading || this.disabled) {
      return;
    }
    if(!this.image && this.url !== '') {
      this._loading = true;
      let res = this.calcRes();
      this.curRes = res;
      this.doLoadImage(res, function(errorstring) {
        // maybe different resolutions are not available
        self.doLoadImage();
        self.invalidResolutions[res] = true;
      });
    }
    else if(this.url !== '') {
      let res = this.calcRes();
      if(this.plannedRes !== res) {
        this.plannedRes = res;
        if(this.loadTimer) {
          clearTimeout(this.loadTimer);
          this.loadTimer = null;
        }
        this.loadTimer = setTimeout(function() {
          self.doLoadImage(res, function(errorstring) {
            // maybe different resolutions are not available
            self.doLoadImage();
            self.invalidResolutions[res] = true;
          });
          self.curRes = res;
        }, 100);
      }
    }
  }
  setImages(url, width, height, filehashes) {
    let self = this;
    self.original_w = width;
    self.original_h = height;
    self.w = width;
    self.h = height;
    this.url = url;
    this.filehashes = filehashes;
    this.scaleFragmentToMax();
  }
  typeDisabledOnScreen() {
    return this.isOnScreen() && this.isSmall();
  }
  typeRed() {
    return this.image === null || this.image === undefined;
  }

  tintImage(r, g, b) {
    if(r === undefined || g === undefined || b === undefined){
      console.warn("tintImage requires 3 number as parameters.")
      return;
    }
    if(this.image_tint)
    {
      let [lr, lg, lb] = this.image_tint;
      if(lr === r && lg === g && lb === b)
        return;
    }
    this.image_tint = [r, g, b];
    if(this.storeHandle && this.storeHandle.ready()) {
      this.storeHandle.createTintedImage(r, g, b)
        .then((imageBitmap) => {
          this.image = imageBitmap;
        });
    }
  }
  doLoadImage(size = null, failureCb) {
    let self = this;
    // Load image
    self._loading = true;

    let [url, fileExtension] = findExtension(this.url, size);
    this._fileExtension = fileExtension;

    this.storeHandle = makeStoreHandle(url);

    let imageLoaded = ({img, width, height}) => {
      // success callback
      if(!self.w || !self.h) {
        self.w = width;
        self.h = height;
      }
      if(self.original_w) {
        self.w = self.original_w;
      }
      if(self.original_h) {
        self.h = self.original_h;
      }

      switch(fileExtension) {
        case "png": // TODO: Add all file types that support transparency
        case "webp":
          if(self._hasImageTransparency === null) {
            self._hasImageTransparency = self.storeHandle.checkImageForTransparency();
          }
          break;
        default:
          self._hasImageTransparency = false;
      }

      if(this.image_tint !== undefined && this.image_tint.length === 3) {
        let [r, g, b] = this.image_tint;
        this.storeHandle.createTintedImage(r, g, b)
          .then((imageBitmap) => {
            this.image = imageBitmap;
          });
      }
      else
      {
        self.image = img;
      }

      if(self._hasImageTransparency && self._maskImage === null) {
        this.storeHandle.createMaskImage().then((imageBitmap) => {
          self._maskImage = imageBitmap;
        })
      }
      // TODO
      // revoke it and reload it on copy if neccessary
      self._shrinked = false;
      self.pushRes();
      self._loading = false;
    }

    this.storeHandle.load(this.maxRes).then(imageLoaded, failureCb);
  }
  fragmentTypeDraw(x, y, w, h) {
    this.p.push();

    if(this.image === null || this.image === undefined) {
    }
    else {
      let img = this.image;
      let ctx = this.p.drawingContext;
      if(this.drawGray) {
        ctx.filter = 'grayscale(100%)';
      }
      ctx.drawImage(img, this.sx, this.sy, img.width, img.height, 0, 0, w, h);

      if(this._hasImageTransparency && this._tintStepLen !== null) {
        // highlight with fade animation
        ctx.globalAlpha = this._tintOpacity/255;
        let img = this._maskImage;
        ctx.drawImage(img, this.sx, this.sy, img.width, img.height, 0, 0, w, h);
        ctx.globalAlpha = 1;
      }
    }
    this.p.pop();
  }
  typeGetImage() {
    return this.image;
  }
  typeManageState() {
    let disabled = !this.isOnScreen() || this.disabledOnScreen();
    this.disabled = disabled;
    this.setDisabled(disabled)
    this.debounceMipMapImage();
  }
  setDisabled(disable) {
    if(!disable && this._disableTimeout !== null) {
      clearTimeout(this._disableTimeout);
      this._disableTimeout = null;
    }
    else if(disable && this.image && this._disableTimeout === null) {
      this._movedOn = false;
      this._disableTimeout = setTimeout(function() {
        if(this.image && this.image.canvas && this.image.canvas.parentNode) {
          let element = this.image.canvas;
          element.parentNode.removeChild(element);
        }
        this.image = null;
      }, 4000);
    }
  }
  typePointCollide(mouseX, mouseY) {
    return (
      this.within(mouseX, mouseY) &&
      !this.typeTransparentAt(mouseX, mouseY)
    );
  }
  typeTransparentAt(mouseX, mouseY) {
    if(this.image === undefined || this.image === null) {
      // not transparent, if image is not loaded (red)
      return false;
    } else if(this.p.pmouseX == mouseX && this.p.pmouseY == mouseY) {
      return this._lastCollosionResult;
    }

    if(!this.storeHandle.ready()) {
      return false;
    }

    let ip = this.accumulatedTransformationMatrix.inverse().mult_point({
      x: mouseX,
      y: mouseY
    })
    let x = ip.x / this.w;
    let y = ip.y / this.h;
    x = Math.round(x * this.image.width);
    y = Math.round(y * this.image.height);

    let imageData = this.storeHandle.getImageData();
    let pixels = imageData.data;
    let i = (y * this.image.width + x) * 4;
    this._lastCollosionResult = pixels[i+3] < 10;
    return this._lastCollosionResult;
  }
}
export default ImageFragment;

function findExtension(url, size) {
  let fileExtension;
  let urls = url.split('/');
  let filename = urls.pop();
  fileExtension = filename.split('.').pop();

  if(size !== null) {
    url = [...urls, `${size}.${filename}`].join('/');
  }

  return [settings.get_media_url() + url, fileExtension];
}


let imageStore = new Map();

function makeStoreHandle(src) {
  let k = src;
  if(imageStore.has(k)) {
    let weak = imageStore.get(k);
    let r = weak.deref();
    if(r) {
      return r;
    }
  }

  let r = new ImageStoreHandle(src);
  imageStore.set(k, new WeakRef(r));
  return r;
}

class ImageStoreHandle {
  constructor(src) {
    this.src = src;
  }

  load(maxRes) {
    let storeHandle = this;
    if(storeHandle.base) {
      return storeHandle.base;
    }

    let promise = new Promise(function (success, failure) {
      let img = new Image();
      img.onload = () => {
        let width = img.width;
        let height = img.height;
        if(width > maxRes) {
          height = (height / width) * maxRes;
          width = maxRes;
        }
        if(height > maxRes) {
          width = (width / height) * maxRes;
          height = maxRes;
        }
        storeHandle.img = img;
        storeHandle.width = width;
        storeHandle.height = height;
        success({img, width, height})
      }
      img.onerror = (err) => {
        // failure callback
        if(typeof failure === 'function') {
          failure("could not load image");
        }
        else {
          console.error("Error loading image.");
          console.error(err);
        }
      }
      img.src = storeHandle.src;
    });
    storeHandle.base = promise;
    return promise;
  }

  ready() {
    return !!this.img;
  }

  getImageData() {
    let storeHandle = this;

    if(storeHandle.imageData) {
      return storeHandle.imageData;
    }
    else {
      if(!storeHandle.img) {
        throw ('getImageData is only available after load completes.');
      }

      let {img, width, height} = storeHandle;
      let canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      let ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, width, height);
      let imageData = ctx.getImageData(0, 0, width, height);
      storeHandle.imageData = imageData;
      return imageData;
    }
  }

  checkImageForTransparency() {
    let storeHandle = this;
    if(storeHandle.transparencyChecked) {
      return storeHandle.hasTransparency;
    }
    let imageData = storeHandle.getImageData();
    storeHandle.hasTransparency = _checkImageForTransparency(imageData.data);
    storeHandle.transparencyChecked = true;
    return storeHandle.hasTransparency;
  }

  createMaskImage() {
    let storeHandle = this;
    if(storeHandle.maskImage) {
      return storeHandle.maskImage;
    }

    let maskImageData = _createMaskImage(storeHandle.getImageData());
    let promise = createImageBitmap(maskImageData);
    storeHandle.maskImage = promise;
    return promise;
  }

  // Tinted image is not cached, because the only use,
  // currently, is for creating variations.
  createTintedImage(r, g, b) {
    let storeHandle = this;
    let imageData = storeHandle.getImageData();
    let tintedImageData = _createTintedImage(imageData, r, g, b);
    return createImageBitmap(tintedImageData);
  }
}

function _checkImageForTransparency(imagePixels) {
  for(let i = 0; i < imagePixels.length; i +=4) {
    if(imagePixels[i+4] === 0) {
      return true;
    }
  }
  return false;
}

function _createTintedImage(imageData, r, g, b) {
  let tintedImageData = new ImageData(new Uint8ClampedArray(imageData.data), imageData.width, imageData.height);
  let data = tintedImageData.data;
  for (let i = 0; i < data.length; i+=4) {
    data[i] = data[i] * r;
    data[i + 1] = data[i + 1] * g;
    data[i + 2] = data[i + 2] * b;
  }
  return tintedImageData;
}

function _createMaskImage(imageData) {
  const r = settings.COLORS.TINT[0];
  const g = settings.COLORS.TINT[1];
  const b = settings.COLORS.TINT[2];
  let maskImageData = new ImageData(new Uint8ClampedArray(imageData.data), imageData.width, imageData.height);
  let data = maskImageData.data;
  for (let i = 0; i < data.length; i+=4) {
    data[i] = r;
    data[i + 1] = g;
    data[i + 2] = b;
  }
  return maskImageData;
}
