import {CopyChange} from './changes/CopyChange.js';
import {BaseFragment} from './fragment/base_fragment.js';
import * as positions from './positions.js';
import * as main from './main.js';
import { COLORS } from './settings.js';
import * as localstore from './localstore.js';
import * as selection from './selection.js';
import * as settings from './settings.js';

/**
 * clipboard stores fragments and position at time of copy
 * [{fragment, screenX, screenY, scale}, ...]
 */

let copyAnim = null;
let alphaOne = null;
let alphaTwo = null;
let stepper = null;
// this should be a representation of the clipboard in memory
// in other words the clipboard content (string) should always be
// the text generated from this array
let _clipboardMem = [];

const counter = () => {
  setTimeout(() => {
    // console.log("copy animation stoped after 1 secs");
    copyAnim = false;
  }, 1000)
}

// we need to load the clipboard content from localstore initially
// info: this does not work for OS clipboard yet
export function init() {
  _clipboardMem = JSON.parse(readFromLocalstore());
  if(settings.user.osClipboardEnabled) {
    // TODO
    // read CLIPBOARD_TYPE data, and if there are any check whether
    // they are newer than localstore clipboard (should be unified with
    // implementation in pasteListener below!)
    // currently there is an error stating:
    //  DOMException: Must be handling a user gesture to use custom clipboard
    /*
    navigator.clipboard.read().then((clipboardText) => {
      console.log(clipboardText);
    }).catch(err => {
      console.error('Failed to read clipboard contents: ', err);
    });
    */
  }
}

export function drawClipboard() {
  let renderer = main.renderer;

  if (getLength()  > 0 && copyAnim) {
    alphaOne -= 10 * (renderer.deltaTime / 50);
    alphaTwo -= 20 * (renderer.deltaTime / 50);
    stepper += 10 * (renderer.deltaTime / 50);

    renderer.noFill();
    renderer.stroke(...COLORS.COPY, alphaOne);
    renderer.strokeWeight(25);
    renderer.rect(0, 0, renderer.width, renderer.height);

    renderer.stroke(...COLORS.COPY, alphaTwo);
    renderer.strokeWeight(4);
    _clipboardMem.fragments.forEach(e => {
      let x1 = e.screenX - stepper;
      let y1 = e.screenY - stepper;
      let x2 = (e.fragment.width * e.scale * e.atGlobalScale) + stepper * 2;
      let y2 = (e.fragment.height * e.scale * e.atGlobalScale) + stepper * 2;
      renderer.rect(x1, y1, x2, y2);
    })
  } else {
    return
  }
}

export function drawPasteLocation() {
  console.log('come on');
}

function startAnimation() {
  copyAnim = true;
  counter();
  alphaOne = 200;
  alphaTwo = 255;
  stepper = 0;
}

export function copy(fragments) {
  if(fragments.length > 0) {
    _clipboardMem = createClipboardObject(fragments);
    const text = clipboardToText(_clipboardMem);
    storeClipboardToLocalstore(text);
    startAnimation();
  }
}

export function paste() {
  const clipboardFragments = JSON.parse(readFromLocalstore()).fragments;
  createFragmentsFromClip(clipboardFragments);
}

export function clearClipboard() {
  localstore.setItem('clipboard', '', true);
}

function storeClipboardToLocalstore(clipboardContent) {
  localstore.setItem('clipboard', clipboardContent, true);
}

function readFromLocalstore() {
  return localstore.getItem('clipboard', true)
}

function createClipboardObject(fragments) {
  const clipboardFragments = [];
  fragments.sort((a, b) => a.floating_z - b.floating_z);
  fragments.forEach(function(frag) {
    let screen_pt = positions.getGlobalMatrix()
      .mult_point({x: frag.x, y: frag.y});
    let clipFrag = {
      fragment: frag.getPersistence(),
      screenX: screen_pt.x,
      screenY: screen_pt.y,
      scale: frag.getIndividualScale(),
      atGlobalScale: positions.getGlobalScaleLowPrec()
    };
    clipboardFragments.push(clipFrag);
  });
  const notaClipboardContent = {
    fragments: clipboardFragments,
    time: new Date().getTime()
  };
  return notaClipboardContent;
}

function clipboardToText(notaClipboardContent) {
  const text = JSON.stringify(notaClipboardContent);
  return text;
}

function createFragmentsFromClip(clipboardFragments) {
  if(typeof clipboardFragments.forEach === 'function') {
    let frags = [];
    let len = clipboardFragments.length;
    for(let i = 0; i < len; i++)
    {
      let clipFrag = clipboardFragments[i];
      const fragment = BaseFragment.restore(
        main.getSketch(), clipFrag.fragment
      );
      frags.push(fragment);
    }
    try {
      let copyChange = new CopyChange(frags, 0, 0);
      for(let i = 0; i < len; i++) {
        let clipFrag = clipboardFragments[i];
        let coords = positions.screenToGlobalCoords({
          x: clipFrag.screenX,
          y: clipFrag.screenY
        });
        const scaleMult = clipFrag.atGlobalScale
          / positions.getGlobalScaleLowPrec();

        let idx = i;
        copyChange._fragments[idx].id = crypto.randomUUID();
        copyChange._fragments[idx].x = coords.x;
        copyChange._fragments[idx].y = coords.y;
        copyChange._fragments[idx].setIndividualScale(
          clipFrag.scale * scaleMult
        );
      }
      copyChange.finalize(0, 0);
    }
    catch(err) {
      console.error('Error pasting fragment:');
      console.error(err);
    }
  }
}

export function getLength() {
  return _clipboardMem.fragments ? _clipboardMem.fragments.length : 0;
}

////
//OS clipboard
document.addEventListener('copy', copyListener);
document.addEventListener('paste', pasteListener);

const CLIPBOARD_TYPE = 'text';
function pasteListener(event) {
  if(!settings.user.osClipboardEnabled || event.target !== document.body) {
    return;
  }
  const clipboardJson = JSON.parse(
    event.clipboardData.getData(CLIPBOARD_TYPE)
  );
  const localstoreJson = JSON.parse(readFromLocalstore());
  let fragmentsText = clipboardJson.fragments;
  if(!fragmentsText) {
    fragmentsText = localstoreJson.fragments;
  }
  if(clipboardJson.time < localstoreJson.time) {
    fragmentsText = localstoreJson.fragments;
  }
  createFragmentsFromClip(fragmentsText);
};

function copyListener(event) {
  if(!settings.user.osClipboardEnabled || event.target !== document.body) {
    return;
  }
  const tmpClipboardMem = createClipboardObject(selection.getSelectedFragments());
  const text = clipboardToText(tmpClipboardMem);
  if(text.length > 0) {
    event.clipboardData.setData(CLIPBOARD_TYPE, text);
    storeClipboardToLocalstore(text);
    _clipboardMem = tmpClipboardMem;
    startAnimation();
    event.preventDefault();
  }
};
////
