import {Change} from './Change.js';
import {_updateFragments} from './utilities.js';
import * as positions from '../positions.js';

export class ReorderFragmentChange extends Change {
  constructor(fragment, over_or_under) {
    super([fragment]);
    this.fragment = fragment;
    this.over_or_under = over_or_under;
    this.last_z = fragment.floating_z;
  }
  progress() {
  }
  s_finalize() {
    this.redo();
  }
  undo() {
    let sync = true;
    this.fragment.floating_z = this.last_z;
    positions.repositionForFloatingZ(this.fragment, sync);
    _updateFragments(this._fragments, true);
  }
  redo() {
    if(this.over_or_under === 'OVER')
      positions.sendFragmentToFront(this.fragment);
    else if(this.over_or_under === 'UNDER')
      positions.sendFragmentToBack(this.fragment);

    _updateFragments(this._fragments);
  }
}
