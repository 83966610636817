import {Decimal} from '../settings.js';

export class Matrix {

  /*
   * transformation matrix:
   * a c e
   * b d f
   * 0 0 1
   *
   * also see:
   * https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/transform
   */
  a = null;
  c = null;
  e = null;
  b = null;
  d = null;
  f = null;
  p_a = null;
  p_c = null;
  p_e = null;
  p_b = null;
  p_d = null;
  p_f = null;
  isReset = false;
  static PI = Decimal.acos(-1);

  /**
   * @name Matrix.rotate
   * @parameter{float} a
   * @parameter{float} c
   * @parameter{float} e
   * @parameter{float} b
   * @parameter{float} d
   * @parameter{float} f
   * @return Transformation matrix [(a,c,e) (b,d,f) (0,0,1)]
   */
  constructor(a=1, c=0, e=0, b=0, d=1, f=0) {
    this.set(a, c, e, b, d, f);
  }

  set_from_matrix(otherMatrix) {
    this.a = otherMatrix.a;
    this.c = otherMatrix.c;
    this.e = otherMatrix.e;
    this.b = otherMatrix.b;
    this.d = otherMatrix.d;
    this.f = otherMatrix.f;
  }
  /**
   * @name Matrix.set
   * @parameter{float or Matrix} a
   * @parameter{float} c
   * @parameter{float} e
   * @parameter{float} b
   * @parameter{float} d
   * @parameter{float} f
   * @return null
   */
  set(a=1, c=0, e=0, b=0, d=1, f=0) {
    this.a = a;
    this.c = c;
    this.e = e;
    this.b = b;
    this.d = d;
    this.f = f;
  }

  /**
   * @name Matrix.times
   * @parameter{Object} transformation matrix with a,c,e,b,d,f
   * @return null
   */
  times(matrix) {
    // this = this * matrix
    const a = this.a * matrix.a + this.c * matrix.b;
    const c = this.a * matrix.c + this.c * matrix.d;
    const e = this.a * matrix.e + this.c * matrix.f + this.e;
    const b = this.b * matrix.a + this.d * matrix.b;
    const d = this.b * matrix.c + this.d * matrix.d;
    const f = this.b * matrix.e + this.d * matrix.f + this.f;
    this.a = a;
    this.c = c;
    this.e = e;
    this.b = b;
    this.d = d;
    this.f = f;
  }

  p_times(matrix) {
    const a = (this.a.times(matrix.a)).plus(
      this.c.times(matrix.b)
    );
    const c = (this.a.times(matrix.c)).plus(
      this.c.times(matrix.d)
    );
    const e = (this.a.times(matrix.e)).plus(
      this.c.times(matrix.f)
    ).plus(this.e);

    const b = (this.b.times(matrix.a)).plus(
      this.d.times(matrix.b)
    );
    const d = (this.b.times(matrix.c)).plus(
      this.d.times(matrix.d)
    );
    const f = (this.b.times(matrix.e)).plus(
      this.d.times(matrix.f)
    ).plus(this.f);
    this.a = a;
    this.c = c;
    this.e = e;
    this.b = b;
    this.d = d;
    this.f = f;
  }

  switch_times(matrix) {
    const a = matrix.a * this.a + matrix.c * this.b;
    const c = matrix.a * this.c + matrix.c * this.d;
    const e = matrix.a * this.e + matrix.c * this.f + matrix.e;
    const b = matrix.b * this.a + matrix.d * this.b;
    const d = matrix.b * this.c + matrix.d * this.d;
    const f = matrix.b * this.e + matrix.d * this.f + matrix.f;
    this.a = a;
    this.c = c;
    this.e = e;
    this.b = b;
    this.d = d;
    this.f = f;
  }

  p_switch_times(matrix) {
    // this = matrix * this
    const a = (matrix.a.times(this.a)).plus(
      matrix.c.times(this.b)
    );
    const c = (matrix.a.times(this.c)).plus(
      matrix.c.times(this.d)
    );
    const e = (matrix.a.times(this.e)).plus(
      matrix.c.times(this.f)
    ).plus(matrix.e);

    const b = (matrix.b.times(this.a)).plus(
      matrix.d.times(this.b)
    );
    const d = (matrix.b.times(this.c)).plus(
      matrix.d.times(this.d)
    );
    const f = (matrix.b.times(this.e)).plus(
      matrix.d.times(this.f)
    ).plus(matrix.f);
    this.a = a;
    this.c = c;
    this.e = e;
    this.b = b;
    this.d = d;
    this.f = f;
  }

  mult(matrix) {
    return new Matrix(
      this.a * matrix.a + this.c * matrix.b,
      this.a * matrix.c + this.c * matrix.d,
      this.a * matrix.e + this.c * matrix.f + this.e,
      this.b * matrix.a + this.d * matrix.b,
      this.b * matrix.c + this.d * matrix.d,
      this.b * matrix.e + this.d * matrix.f + this.f,
    );
  }

  p_mult(matrix) {
    const newMatrix = new Matrix(
      //a
      (this.a.times(matrix.a)).plus(
        this.c.times(matrix.b)
      ),
      //c
      (this.a.times(matrix.c)).plus(
        this.c.times(matrix.d)
      ),
      //e
      (this.a.times(matrix.e)).plus(
        this.c.times(matrix.f)
      ).plus(this.e),
      //b
      (this.b.times(matrix.a)).plus(
        this.d.times(matrix.b)
      ),
      //d
      (this.b.times(matrix.c)).plus(
        this.d.times(matrix.d)
      ),
      //f
      (this.b.times(matrix.e)).plus(
        this.d.times(matrix.f)
      ).plus(this.f)
    );
    return newMatrix;
  }

  mult_point(point) {
    const x = this.a * point.x + this.c * point.y + this.e;
    const y = this.b * point.x +this.d * point.y +  this.f;
    return {x: x, y: y};
  }

  /**
   * @name Matrix.rotate
   * @parameter{float} degree, clockwise
   * @return null
   */
  translate(translate_x, translate_y) {
    this.e = this.a * translate_x + this.c * translate_y + this.e;
    this.f = this.b * translate_x + this.d * translate_y + this.f;
  }

  p_translate(translate_x, translate_y) {
    /*
    const translationMatrix = new Matrix(
      1, 0, translate_x,
      0, 1, translate_y,
    );
    */
    this.e = (this.a.times(translate_x)).plus(
        this.c.times(translate_y)
      ).plus(this.e);
    this.f = (this.b.times(translate_x)).plus(
        this.d.times(translate_y)
      ).plus(this.f);
  }

  /**
   * @name Matrix.rotate
   * @parameter{float} degree, clockwise
   * @return null
   */
  rotate(degree_clockwise) {
    const rad_rotation = degree_clockwise / 180 * Math.PI;
    const cos = Math.cos(rad_rotation);
    const sin = Math.sin(rad_rotation);

    const rotationMatrix = new Matrix(
      cos, -sin, 0,
      sin, cos, 0
    );
    this.times(rotationMatrix);
  }

  /**
   * @name Matrix.p_rotate
   * @parameter{float} degree, clockwise
   * @return null
   */
  p_rotate(degree_clockwise) {
    const precise_radians = new Decimal(degree_clockwise)
      .div(new Decimal(180)).times(Matrix.PI);
    const cos = precise_radians.cos();
    const sin = precise_radians.sin();

    const a = cos;
    const c = sin.times(-1);
    const e = new Decimal(0);
    const b = sin;
    const d = cos;
    const f = new Decimal(0);
    const rotationMatrix = new Matrix(a, c, e, b, d, f);
    this.p_times(rotationMatrix);
  }

  /**
   * @name Matrix.scale
   * @param{float} factor
   * @return null
   */
  scale(factor) {
    this.times(new Matrix(
      factor, 0, 0,
      0, factor, 0
    ));
  }
  p_scale(factor) {
    const precise_factor = new Decimal(factor);
    const a = new Decimal(precise_factor);
    const c = new Decimal(0);
    const e = new Decimal(0);
    const b = new Decimal(0);
    const d = new Decimal(precise_factor);
    const f = new Decimal(0);
    const scaleMatrix = new Matrix(a, c, e, b, d, f);
    this.p_times(scaleMatrix);
  }
  axis_scale(factorX, factorY) {
    this.times(new Matrix(
      factorX, 0, 0,
      0, factorY, 0
    ));
  }

  apply(p5js) {
    p5js.applyMatrix(
      this.a, this.b, this.c,
      this.d, this.e, this.f
    );
  }

  p_apply(p5js) {
    p5js.applyMatrix(
      this.a.toNumber(), this.b.toNumber(), this.c.toNumber(),
      this.d.toNumber(), this.e.toNumber(), this.f.toNumber()
    );
  }

  inverse() {
      const dt = (this.a * this.d - this.b * this.c);
      return new Matrix(
        this.d / dt,
        -this.c / dt,
        (this.c * this.f - this.d * this.e) / dt,
        -this.b / dt,
        this.a / dt,
        -(this.a * this.f - this.b * this.e) / dt
      );
  }
}
