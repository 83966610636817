/* 🕮
You are reading the 'main module'
The main module (main.js) initiates the processing (p5.js) code to render the fragments on the html canvas element
The general treedigram of this moodule is as following:

main.js
│
├─ Imports:
│  ├─ p5.js module
│  └─ other local/coded modules
│
├─ Font related exports:
│  ├─ getFontName()
│  └─ getFont()
│
├─ Mobile screen related exports:
│  ├─ fullscreenMobile()
│  └─ isMobile()
│
├─ HTML overlay related exports:
│  ├─ getHTMLOverlay()
│  └─ setHTMLOverlay()
│
├─ Sketch exports (created by p5):
│  ├─ p as renderer variable
│  └─ p as getSketch() function
│
┌─ Main p5.js function body: (sketchInit(p) used by the p5() constructor at the end)
│  ├─ intiating UI (initUI(p) function)
│  │
│  ├─ setup function (p.setup)
│  │  ├─ font
│  │  ├─ canvas (create, size, and resize listeners)
│  │  ├─ frameRate
│  │  └─ background
│  │
│  └─ draw/looping function (p.draw)
│     ├─ input.loop()
│     ├─ updating UI
│     ├─ fragment hovering update
│     └─ ...
│
└─ finally initiating p5 (the p5() constructor: https://p5js.org/reference/#/p5/p5)

More about p5.js:
p5.js is a version of Processing that is based on JavaScript which is the programming language of the Web
Processing and p5.js are free graphical libraries for creative coding , electronic arts, new media art, visual design, etc.
For more information check: https://p5js.org

*/


/* 🕮
Directions for USER SCRIPTS:
you can only use functions and variables that have the keyword 'export' in front of them in your user scripts you
always have to write "modules.main.something"

Here are some examples:
modules.main.getArea();
modules.main.isMobile();
modules.positions.getSketch();

However, the essential functionality from this module (sketch as p5 sketch) can be accessed just by typing 'sketch'
in the userscript. For example:
'sketch.rect(fragment.screenX(), fragment.screenY(), fragment.screenW(), fragment.screenH());'
would draw a rectangle around its refering fragment

Scroll through the code to find more
*/


/// following statements are used to import/bring other pieces of code to the current module
/// the syntax is: import someCodeName from "module-name"
/// "module-name" can be an installed package (e.g. p5.js library) or another Javascript module/file that we code
/// for more on importing modules: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/import
/// here the main module (main.js) imports p5.js library as a module to use it
/// as p5.js library is an installed module/package (meaning we didn't write it)
/// to impoer it we don't need to declare its local address/path explicitly, so we can just:
import p5 from 'p5';


/// unlike above the following modules are being imported by declaring their local address/directory path
/// e.g. from "./src/userinterface/ui.js"
import './img/favicon.ico';
import * as input from './input.js';
import * as positions from './positions.js';
import * as space_module from './space.js';
import * as selection from './selection.js';
import * as settings from './settings.js';
import * as messages from './messages.js';
import {reverse} from './utilities/utilities.js';
import {initUI} from './userinterface/ui.js';
import {text_permission} from './userinterface/permissions.js';
import * as ui from './userinterface/ui.js';
import * as text_module from './userinterface/text.js';
import * as clipboard from './clipboard.js'
import * as automation from './automation.js';
import * as api_space from './api/space.js';
import * as api_login from './api/login.js';
import * as api_admin from './api/admin.js';
import * as api_fragments from './api/fragments.js';

// expose global object to allow interaction from javascript console
// this should only be used for testing, not in userscripts, as we
// will not maintain a stable interface
// DANGER not stable
window.nota_code = {};
nota_code.api_admin = api_admin;
// global storage for javascript console testing
// DANGER not stable
window.nota_storage = {};

let myFont = null;
export function getFontName() {
  // TODO  SVG building related
  // let name = MyFont.split('.')[0];
  // return name;
  return "Arial";
}
export function getFont() {
  return myFont;
}
export function fullscreenMobile() {
  if(/Android|webOS/i.test(navigator.userAgent)) {
    return true;
  }
  else {
    return false;
  }
}

export function isMobile() {
  if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    return true;
  }
  else {
    return false;
  }
}

let textElementHTMLOverlays = null;

/// "export" statement makes this functions and variables accessible for the other modules to import and use
/// This is a [exported] function that provides our customized p5 program as "sketch"
export function getSketch() {
  return sketch;
}
let sketch = null;
/// This is a [exported] variable that provides our costomized p5 program as "renderer"
export let renderer = null;

var $zoomPage = document.getElementById('zoom-page');
export let divWidth = $zoomPage.clientWidth;

export function getArea() {
  return renderer.width * renderer.height;
}

/// this prevents default scrolling action ("onwheel" listener's default event e.g. page scrolling)
document.onwheel = function() {
  return false;
}


export function setHTMLOverlay(overlay) {
  textElementHTMLOverlays = overlay;
}
export function getHTMLOverlay(overlay) {
  return textElementHTMLOverlays;
}

// check if this is used! or can it be deleted?
function downloadObject(name, obj) {
  var stringData = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(obj));
  var downloadElement = document.getElementById('downloadElement');
  downloadElement.setAttribute("href",     stringData);
  downloadElement.setAttribute("download", name + ".json");
  downloadElement.click();
}

/// This is the main p5.js function
/// This function includes two fundamental blocks of code named "setup" and "draw"
/// These two special functions are the ‘skeleton’ of a p5.js program
function sketchInit(p) {
  sketch = p;
  renderer = p;
  initUI(p);
  
  p.preload = function() {
    // TODO to choose arbitrary font, it must somehow be made available
    // to text -> SVG building process
    // p.loadFont(myFont);
    myFont = 'Arial';
  };

  /// --- p5 setup function ---
  /// The setup() function runs at the beginning of a p5.js program.
  /// It typically contains code that defines the initial state of the p5 program,
  /// such as create the drawing canvas and its size, background color, and initial values of global
  /// variables, which mean you can set options like colors used for drawing.
  p.setup = function() {
    renderer.noSmooth();
    p.angleMode(p.DEGREES);
    p.textFont(getFont());
    let searchParams = (new URL(document.location)).searchParams;
    let username = searchParams.get("user");
    let spacename = searchParams.get("room");
    if(username !== null && spacename !== null) {
      space_module.loadThenInitSpace(username, spacename, false);
    }
    else {
      space_module.loadThenInitSpace("nota", "default", false);
    }
    renderer.frameRate(30);
    // TODO load data from backend

    /// This sets up the canvas/sketch background at the beginning in the setup function
    let background = settings.COLORS.BACKGROUND;
    renderer.background(background[0], background[1], background[2], 255);

    let height = $zoomPage.clientHeight;
    let width = $zoomPage.clientWidth;
    positions.changeTranslationBy($zoomPage.offsetLeft, $zoomPage.offsetTop)
    renderer.createCanvas(width, height);
    function resizeCanvas() {
      var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
      var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
      renderer.resizeCanvas(w, h);
    }
    window.addEventListener('orientationchange', function() {
      resizeCanvas();
    }, false);
    window.addEventListener('resize', function() {
      resizeCanvas();
    }, false);
    input.initInputModule(p);
    clipboard.init();
    document.getElementById('loadinganimation').remove()
    /// --- setup end ---
  };

  var FR = null;
  let cliplength = null;
  let pastInit = false;


  /// --- p5 draw function ---
  /// The draw() function is p5's looping code block that is automatically called after the setup function
  /// It loops infinitely meaning it runs the code block inside its body from top to bottom
  /// It runs over and over again as long as the sketch is running, controling how the sketch should evolve over time,
  /// drawing notas spaces and fragments a number of times a second (frameRate) onto the canvas
  /// For a demonstration of the canvas see this space: https://nota.space/?user=stine&room=backstage
  /// In the space drag around the text fragment saying 'what'
  p.draw = function() {
    positions.resetFrame();
    // reset cursor to arrow at the beginning of each frame
    p.cursor(p.ARROW);
    if(space_module.firstFrameFinished() && !space_module.firstFrameFinishedHandled()) {
      // refresh text after first frame, to load changes from user scripts
      text_module.shouldRefreshText(true);
      space_module.firstFrameFinishedHandled(true);
    }
    let shouldRefreshText = text_module.shouldRefreshText();
    if(space_module.spaceIsLoading) {
      p.text("LOADING", 200, 200);
    }

    renderer.noSmooth();
    p.textFont(getFont());
    // if input module not loaded yet, do nothing in this main loop
    // probably this can be removed now
    if(input === null) {
      messages.error("input is null, set main.js");
      return;
    }
    input.loop();
    
    /// This updates the canvas/sketch background via this looping function
    /// It means it is possible to change the sketch background properties while nota is running
    let background = settings.COLORS.BACKGROUND;
    renderer.background(background[0], background[1], background[2], 255);

    renderer.fill(255);

    // collide mouse
    var hovered = false;
    var hoveredFragment = null;
    let potentialHover = input.shouldContinueAsHover();
    reverse(positions.getAll(), function(fragment, idx) {
      fragment.precalc(potentialHover);
      fragment.manageState(potentialHover);
      if(fragment.grabbed) {
        potentialHover = false;
        if(hoveredFragment) {
          hoveredFragment.hovered = false;
          hoveredFragment.isWithinBorder = false;
        }
        hoveredFragment = fragment;
        fragment.hovered = true;
      }
      else if(fragment.hovered /*|| fragment.isWithinBorder*/) {
        hoveredFragment = fragment;
        fragment.hovered = true;
        potentialHover = false;
      }
    });
    if(hoveredFragment !== null) {
      // needs to happen AFTER manageState for all fragments is done
      // because that sets steady false!
      selection.setSteadyForHovered(hoveredFragment);
    }

    // draw
    // global transform
    renderer.push();
    const globalMatrix = positions.getGlobalMatrix();
    positions.getOnScreenFragments().forEach(function(fragment, idx) {
      if(shouldRefreshText) {
        if(fragment.getTypename() === 'text') {
          fragment.buildTextSvg();
        }
      }
      fragment.draw(globalMatrix);
    });

    renderer.pop()

    /// This code draws the selection (of mouse/trackpad) frame of nota fragments
    selection.drawFrame();

    if(FR === null) {
      FR = renderer.frameRate();
    }
    else {
      var alph = 0.9;
      FR = alph * FR + (1-alph) * renderer.frameRate();
    }
    ui.updateFPS(Math.round(FR));

    /// The following statements (code blocks, methods, functions etc.) are updating/drawing the nota UI elements
    /// lines that involve "ui." are to do with nota's UI module that is imported at the beginning of this module
    if(space_module.isLoading())
    {
      ui.updateSelection(`0`);
      ui.updateSpace({
        name: "loading...",
        username: "",
        permission: "",
      });
      ui.updateFragmentCount(0);
      pastInit = false;
    }
    else
    {
      if(!pastInit)
      {
        pastInit = true;
        if(localStorage.getItem('noLoop') === 'yes')
        {
          renderer.noLoop();
        }

        ui.groundedSurface(space_module.isGrounded());

        let space = api_space.getSpace()
        if(space)
        {
          let perm = '';
          if (space.permissions.length > 0)
          {
            perm = text_permission(space.permissions);
          }
          else
          {
            perm = space.anonymous_access ? 'public' : 'none';
          }
          ui.updateSpace({
            name: space.name,
            username: space.username,
            permission: perm,
          });
        }
      }
      let selected = selection.getSelectedFragments().length;
      ui.updateSelection(`${selected}`);

      let all = positions.getAll().length;
      ui.updateFragmentCount(all);
    }
    ui.updateLoading(space_module.isLoading(), renderer.isLooping());

    /// Through clipbard module, this code causes drawing of copying and pasting feedback rectangles
    /// for more detail on drawing code (using p5 methods) please check the function inside clipboard module
    clipboard.drawClipboard();

    cliplength = clipboard.getLength();
    ui.updateClipboard(cliplength);
    ui.updateUndo();

    ui.updateBrakesInfo();
    ui.updateScriptsInfo();

    let pushed = api_fragments.isPushed();
    ui.updateStatusInfo(pushed);
    ui.respondToScreenWidth(window.innerWidth);

    automation.loop();
    ui.drawUserPositions();

    // drawing done
    // var drawTime = p.millis() - timer;
    // timer = p.millis();

    // var miscTime = p.millis() - timer;
    positions.setPositionChanged(false);
    // only reset this flag, if it is the same as at the beginning of the frame,
    // to allow >= one full frame with reset true
    if(shouldRefreshText && text_module.shouldRefreshText()) {
      text_module.shouldRefreshText(false);
    }
    if(space_module.isInitialized() && !space_module.firstFrameFinishedHandled()) {
      space_module.firstFrameFinished(true);
    }
  }; /// --- draw end ---
};

/// The following initiates all the mentioned code meaning runing/starting a nota space like turning it on
var activated = false;
if(!activated) {
  p5.disableFriendlyErrors = true;
  /// sketchInit function that is the main function running everything related to p5, loading and updating nota spaces
  /// is being "called" in this peice of code to initiate a p5/processing program
  /// meaning "sketchInint() is getting passed as an argument to p5 constructor"
  /// for more on p5 constructor see https://p5js.org/reference/#/p5/p5 for more on this topic
  /// this module initiates p5.js sketch (or fundation of nota frontend code) at the very end of this module
  new p5(sketchInit, 'zoom-sketch');
  activated = true;
}

