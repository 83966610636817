export function distPointPoint(p1, p2) {
  return Math.sqrt(
    Math.pow((p2.x - p1.x), 2) + Math.pow(p2.y - p1.y, 2)
  );
}
export function linePoint(v1, v2, p) {
  const dist1 = distPointPoint(v1, p);
  const dist2 = distPointPoint(v2, p);
  const lineLength = distPointPoint(v1, v2);
  // margin for numeric tolerance
  const margin = 0.05;
  if(dist1 + dist2 >= lineLength - margin && dist1 + dist2 <= lineLength + margin) {
    return true;
  }
  else {
    return false;
  }
}
export function pointRect(point, rectX, rectY, rectW, rectH) {
  // left, right, top, bottom coordinates
  const l = rectX;
  const r = l + rectW;
  const t = rectY;
  const b = t + rectH;
  const within = point.x > l && point.x < r && point.y > t && point.y < b;
  return within;
}
// given points 1, 2, 3, and 4 that define lines 12 and 34, check for collision
export function lineLine(v1, v2, v3, v4) {
  const ua = ((v4.x - v3.x) * (v1.y - v3.y) - (v4.y - v3.y) * (v1.x - v3.x))
    / ((v4.y - v3.y) * (v2.x - v1.x) - (v4.x - v3.x) * (v2.y - v1.y));
  const ub = ((v2.x - v1.x) * (v1.y - v3.y) - (v2.y - v1.y) * (v1.x - v3.x))
    / ((v4.y - v3.y) * (v2.x - v1.x) - (v4.x - v3.x) * (v2.y - v1.y));
  return ua >= 0 && ua <= 1 && ub >= 0 && ub <= 1;
}
// given points 1, 2, 3, and 4 that define lines 12 and 34, check for collision
// and return the section vertex, if crossing
export function lineLineVert(v1, v2, v3, v4) {
  const ua = ((v4.x - v3.x) * (v1.y - v3.y) - (v4.y - v3.y) * (v1.x - v3.x))
    / ((v4.y - v3.y) * (v2.x - v1.x) - (v4.x - v3.x) * (v2.y - v1.y));
  const ub = ((v2.x - v1.x) * (v1.y - v3.y) - (v2.y - v1.y) * (v1.x - v3.x))
    / ((v4.y - v3.y) * (v2.x - v1.x) - (v4.x - v3.x) * (v2.y - v1.y));
  // if crossing
  if(ua >= 0 && ua <= 1 && ub >= 0 && ub <= 1) {
    return {
      x: v1.x + (ua * (v2.x - v1.x)),
      y: v1.y + (ua * (v2.y - v1.y))
    }
  }
  else {
    return null;
  }
}
export function lineLineInfiniteVert(v1, v2, v3, v4) {
  const denominator = (v1.x - v2.x) * (v3.y - v4.y) -
    (v1.y - v2.y) * (v3.x - v4.x);
  if(denominator === 0) {
    // lines are parallel
    return null;
  }
  else {
    const xNumerator = (v1.x*v2.y - v1.y*v2.x) * (v3.x - v4.x)
      -
      (v1.x - v2.x) * (v3.x*v4.y - v3.y*v4.x);
    const yNumerator = (v1.x*v2.y - v1.y*v2.x) * (v3.y - v4.y)
      -
      (v1.y - v2.y) * (v3.x*v4.y - v3.y*v4.x);
    return {
      x: xNumerator / denominator,
      y: yNumerator / denominator
    };
  }
}
export function lineRect(v1, v2, rectX, rectY, rectW, rectH) {
  // check collision for left || right || top || botton line
  return lineLine(v1, v2, {x: rectX, y: rectY}, {x:rectX, y: rectY+rectH})
    || lineLine(v1, v2, {x: rectX+rectW, y: rectY}, {x: rectX+rectW, y: rectY+rectH})
    || lineLine(v1, v2, {x: rectX, y: rectY}, {x: rectX+rectW, y: rectY})
    || lineLine(v1, v2, {x: rectX, y: rectY+rectH}, {x: rectX+rectW, y: rectY+rectH});
}
// return vertices of crossing points of a line and a rectangle
export function lineRectVerts(v1, v2, rectX, rectY, rectW, rectH) {
  // left, right, top, bottom lines
  const l = lineLineVert(v1, v2, {x: rectX, y: rectY}, {x:rectX, y: rectY+rectH});
  const r = lineLineVert(v1, v2, {x: rectX+rectW, y: rectY}, {x: rectX+rectW, y: rectY+rectH});
  const t = lineLineVert(v1, v2, {x: rectX, y: rectY}, {x: rectX+rectW, y: rectY});
  const b = lineLineVert(v1, v2, {x: rectX, y: rectY+rectH}, {x: rectX+rectW, y: rectY+rectH});
  const candidates = [];
  // above rectangle
  if(v1.y < rectY) {
    candidates.push(t);
    if(v1.x < rectX) {
      // check left first
      candidates.push(l);
      candidates.push(r);
    }
    else {
      // if v1 is not left of rectangle, it is either in the middle of the
      // rectangle, in which case the l/r order does not matter, or to the
      // right, in which case right needs to be checked first
      candidates.push(r);
      candidates.push(l);
    }
    candidates.push(b);
  }
  // vertically in rectangle
  else if(v1.y > rectY && v1.y < rectY + rectH) {
    if(v1.x < rectX) {
      // v1 is left of rectangle, the cut with left must be the first, if any
      // exist, and there can be only one other cut, so the order of the
      // remaining rectangle sides does not matter
      candidates.push(l);
      candidates.push(t);
      candidates.push(r);
      candidates.push(b);
    }
    else {
      // v1 is either in the rectangle, then there can be only one cut and the
      // order does not matter, or to the right
      candidates.push(r);
      candidates.push(l);
      candidates.push(t);
      candidates.push(b);
    }
  }
  // below rectangle, similar to above
  else {
    candidates.push(b);
    if(v1.x < rectX) {
      candidates.push(l);
      candidates.push(r);
    }
    else {
      candidates.push(r);
      candidates.push(l);
    }
    candidates.push(t);
  }

  const verts = [];
  // only use non-null verts (actual intersections)
  candidates.forEach(candidate => {
    if(candidate !== null) {
      verts.push(candidate);
    }
  });
  return verts;
}
// given array of a convex polygon's vertices and rectangle's
// top-left x,y + width,height, check for collision
export function rectPoly(vertices, rectX, rectY, rectW, rectH) {
  let vertWithin = vertices.some(vert => {
    if(pointRect(vert, rectX, rectY, rectW, rectH)) {
      return true;
    }
  });
  if(vertWithin) {
    return true;
  }
  for(let i=0; i < vertices.length; i++) {
    const v1idx = i;
    const v2idx = (i === vertices.length - 1) ? 0 : i + 1;
    const v1 = vertices[v1idx];
    const v2 = vertices[v2idx];
    if(lineRect(v1, v2, rectX, rectY, rectW, rectH)) {
      return true;
    }
  }
  // if all edges and vertices are outside the rectangle, we can check the
  // vertices that define the intersecting area between the polygon and
  // the rectangle
  const insideVertices = getInsideAreaVerts(vertices, rectX, rectY, rectW, rectH);
  return insideVertices.length > 2;
}
// given a list of vertices that define a rectangle,
// calculate a list of vertices that define the polygon
// of the area intersecting with the screen
export function getInsideAreaVerts(verts, rectX, rectY, rectW, rectH) {
  const polygonVertices = [];
  for(let i = 0; i < verts.length; i++) {
    const previousVert = verts[(i - 1 + verts.length) % verts.length];
    const vert = verts[i % verts.length];
    const edge = [previousVert, vert];
    const inside = pointRect(vert, rectX, rectY, rectW, rectH);
    const previousInside = pointRect(previousVert, rectX, rectY, rectW, rectH);
    const edgeVertices = [];
    // vertices at crosssection(s) of edge and screen rectangle
    const edgeScreenCuts = lineRectVerts(
      previousVert, vert, rectX, rectY, rectW, rectH
    );
    // is edge (partially) on screen
    if(edgeScreenCuts.length > 0) {
      edgeScreenCuts.forEach(v=>edgeVertices.push(v));
    }
    if(!previousInside || !inside) {
      // is the edge crossing a screen diagonal outside? if yes, add the corner
      // of the screen that the edge passes
      const outsideCrossingScreenDiagonals = [];
      const center = {x: rectX + rectW/2, y: rectY + rectH/2};
      // top right corner
      const tr = {x: rectX + rectW, y: rectY};
      // bottom right corner
      const br = {x: rectX + rectW, y: rectY + rectH};
      let centerTr = lineLineInfiniteVert(previousVert, vert, center, tr);
      let centerBr = lineLineInfiniteVert(previousVert, vert, center, br);
      // only accept the crossings, if they are on the currect edge segment
      if(centerTr !== null && !linePoint(previousVert, vert, centerTr)) {
        centerTr = null;
      }
      if(centerBr !== null && !linePoint(previousVert, vert, centerBr)) {
        centerBr = null;
      }
      const candidates = [];
      if(centerTr !== null && centerBr !== null) {
        const distCTr = distPointPoint(previousVert, centerTr);
        const distCBr = distPointPoint(previousVert, centerBr);
        if(distCTr < distCBr) {
          candidates.push(centerTr);
          candidates.push(centerBr);
        }
        // center-bottom-right line is crossed first
        else {
          candidates.push(centerBr);
          candidates.push(centerTr);
        }
      }
      else if(centerTr !== null) {
        candidates.push(centerTr);
      }
      else if(centerBr !== null) {
        candidates.push(centerBr);
      }
      if(candidates.length > 0) {
        candidates.forEach(function(v) {
          const outside = !pointRect(v, rectX, rectY, rectW, rectH);
          if(outside) {
            edgeVertices.push({
              x: Math.min(rectX + rectW, Math.max(rectX, v.x)),
              y: Math.min(rectY + rectH, Math.max(rectY, v.y))
            })
          }
        });
      }
    }
    if(edgeVertices.length > 0) {
      edgeVertices.sort((vert1, vert2) => {
        // > 0 -> vert1 after vert2
        const dist1 = distPointPoint(previousVert, vert1);
        const dist2 = distPointPoint(previousVert, vert2);
        return dist1 - dist2;
      });
      edgeVertices.forEach(vert => polygonVertices.push(vert));
    }
    // vert inside?
    if(inside) {
      // not for second iteration of first vert
      // (we only check the edge for this one)
      if(i < verts.length) {
        polygonVertices.push(vert);
      }
    }
  }
  if(polygonVertices.length > 2) {
    return polygonVertices;
  }
  else {
    return [];
  }
}
