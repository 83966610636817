import {BaseFragment} from './base_fragment.js';
import * as settings from '../settings.js';
import * as sketch from '../main.js';
import * as audio from '../audio.js';

class VideoFragment extends BaseFragment {
  constructor(p, x, y, type, data, persistent=false) {
    super(p, x, y, type, data, persistent);
    let self = this;
    self._borderShown = true;
    self._hasBorder = false;
    this.setVideo(data.url || this.url || '');
    this.loadingstarted=false;
    this._loadingbeg = null;
    this.playRequested = false;
    this.loadFun = null;
    this.sizeIsStored = false;
    this._disableTimeout = null;
  }
  setVideo(url, filehashes, callback) {
    this.url = url;
    this.filehashes = filehashes;
    if(callback) {
      this.loadFun = callback;
    }
  }
  typeRed() {
    return this.video === null || this.video === undefined;
  }
  typeCalcSmall() {
    return Math.max(Math.abs(this.screenW()), Math.abs(this.screenH())) < 50;
  }
  typeManageState() {
    this.setDisabled(this.isSmall() || !this.isOnScreen());
    if(this.isOnScreen() && this.video && this.video.elt && !this.video.elt.muted) {
      let rar = this.getRelativeOnScreenArea();
      if(0 <= rar && rar <= 1) {
        this.video.elt.volume = rar;
      }
      else {
        console.warn("Tried to set video volume outside of [0,1]");
        console.warn(rar);
        this.video.elt.volume = Math.max(0, Math.min(1, rar));
      }
    }
    else if(this.video && this.video.elt) {
      this.video.elt.volume = 0;
    }
  }
  typeDisabledOnScreen() {
    return this.isOnScreen() && 
      (!this.isReady() || this.video === undefined || this.video === null);
  }
  callLoadCallback() {
    if(this.loadFun !== null) {
      this.loadFun();
    }
    this.loadFun = null;
  }
  typeOnRemove() {
    this.doDisable();
  }
  doDisable() {
    this.setReady(false);
    if(this.video) {
      if(this.video.elt) {
        if(this.video.elt.parentNode) {
          let element = this.video.elt;
          element.parentNode.removeChild(element);
          // remove the callback passed to p5.createVideo()
          this.video.elt.oncanplaythrough = null;
          this.video.elt.onloadedmetadata = null;
        }
      }
    }
    this._loading = false;
    this.loadingstarted = false;
    this.p._elements.splice(this.p._elements.indexOf(this.video), 1);
    this.video = null;
    this.playRequested = false;
  }
  setDisabled(disable) {
    let self = this;
    if(!disable && this._disableTimeout !== null) {
      clearTimeout(this._disableTimeout);
      this._disableTimeout = null;
    }
    if (
      !this.video &&
      (!disable || !this.sizeIsStored)
    ) {
      this.loadVideo();
    }
    else if(!disable) {
      this.loop();
    }
    else if(disable && this.sizeIsStored && this._disableTimeout === null) {
      this.pause();
      this._disableTimeout = setTimeout(function() {
        self.doDisable();
      }, 4000);
    }
  }
  videoResolutionKnownCallback(self) {
    // might already have been removed
    // TODO is it possible to abort loading?
    // TODO remove the callback before setting video to null
    if(self.video === null)  {
      return;
    }
    else if(self.video.elt.width === 0 || self.video.elt.height === 0) {
      return;
    }
    else {
      self._loading = false;
      self.loadingstarted = false;
      let v = self.video;
      self.w = v.elt.width;
      self.h = v.elt.height;
      self.setReady(true);
      self.callLoadCallback();
      self.pushRes();
    }
  }
  loadVideo() {
    let url;
    if(!this.url) {
      return;
    }
    if(this._loading) {
      if((new Date()).getTime() - this.loadingstarted > 10000) {
        console.log(this.id);
      }
      return;
    }
    let self = this;
    self._loading = true;
    self.loadingstarted = (new Date()).getTime();
    url = settings.get_media_url() + this.url;

    let v;
    v = this.p.createVideo(url, function() {
    });
    self.video = v;
    v.elt.setAttribute('loop', true);
    v.autoplay(true);
    if(sketch.isMobile()) {
      v.elt.muted = true;
    }
    v.elt.style.display = 'none';
    v.elt.webkitPlaysinline = true;
    v.elt.playsinline = true;
    v.elt.playsInline = true;
    v.elt.style['text-align'] = 'inherit';
    v.elt.controls = true;
    // prevents drag / drop strangeness in chrome
    v.elt.addEventListener('loadedmetadata', function() {
      function checkResKnown() {
        if(v.elt.width === 0 || v.elt.height === 0) {
          setTimeout(200, checkResKnown);
        }
        else {
          self.videoResolutionKnownCallback(self);
        }
      }
      checkResKnown();
    });

    v.elt.onplaying = function() {
      self.setPlaying(true);
      // this stops videos on iOS
      //v.elt.muted = false;
    };
    v.elt.onpause = function() {
      self.setPlaying(false);
    };
  }
  typeLoop() {
    if(this.video) {
      if(this.video.elt.paused) {
        let self = this;
        this.video.elt.play().then(function(){
        }).catch(function(err) {
          // mute to allow autoplay
          self.video.elt.muted = true;
        });
        // unmute on click
        if(audio.getContext().state !== 'suspended') {
          self.video.elt.muted = false;
        }
      }
    }
  }
  typePause() {
    if(this.video) {
      if(!this.video.paused) {
        this.video.elt.pause();
      }
    }
  }
  typeIsPlaying() {
    return this.video !== null &&
           this.video !== undefined &&
           this.video.elt !== null &&
           this.video.elt !== undefined &&
           !this.video.elt.paused;
  }
  typeWheel(dir) {
    if(this.video && this.video.elt) {
      var vol = this.video.elt.volume;
      if(dir < 0) {
        vol = vol * 1.2;
        if(vol === 0) {
          vol = 0.02;
        }
      }
      else {
        vol = vol / 1.2;
      }
      vol = Math.max(vol, 0);
      vol = Math.min(vol, 1);
      this.video.elt.volume = vol;
    }
  }
  typeClick() {
    if(this.video) {
      if(this.video.elt) {
        if(this.video.elt.paused) {
          this.typeLoop();
        }
        else {
          this.typePause();
        }
      }
    }
  }
  typeDrawRed(x, y, w, h) {
    if(!this._loading) {
      this.p.fill(settings.COLORS.LOADING);
      this.p.noStroke(255);
      this.p.rect(x, y, w, h);
      this._loadingbeg = null;
    }
    else {
      if(this.loadingbeg === null) {
        this._loadingbeg = this.p.millis();
      }
      let t = this.p.millis() - this._loadingbeg;
      let loading_color = settings.COLORS.LOADING;
      let off = this.id * 777 % 333;
      let r = loading_color[0];
      let g = loading_color[1];
      let b = loading_color[2];
      let a = loading_color[3];
      let rr = 0.5 * (255 - r);
      let gr = 0.5 * (255 - g);
      let br = 0.5 * (255 - b);
      let ar = 1 * (255 - a);
      r = r + Math.abs(Math.sin(t*0.0011)) * rr * (0.5+Math.abs(this.p.noise(0.0004 * t+off))*0.5);
      g = g + Math.abs(Math.sin(t*0.0012)) * gr * (0.5+Math.abs(this.p.noise(0.0004 * t+off))*0.5);
      b = b + Math.abs(Math.sin(t*0.0013)) * br * (0.5+Math.abs(this.p.noise(0.0004 * t+off))*0.5);
      a = 50 + 155 * Math.abs(Math.sin(0.001 * t));
      this.p.fill(r,g,b,a);
      this.p.noStroke(255);
      this.p.rect(x, y, w, h);
    }
  }
  fragmentTypeDraw(x, y, w, h) {
    let drawX = 0;
    let drawY = 0;
    if(this.video === undefined || this.video === null) {
      return;
    }
    try {
      this.p.image(
        this.video,
        drawX,
        drawY,
        w,
        h
      );
    }
    catch(e) {
      console.error(e);
    }
  }
}

export default VideoFragment;
