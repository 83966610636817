export let map = new Map();
export function new_gain(temporary_id, gained_id) {
  map.set(gained_id, temporary_id);
}

export function has(gained_id) {
  return map.has(gained_id);
}

export function get(gained_id) {
  return map.get(gained_id);
}
