import * as api from './api.js';

export function list_group_users(id, after)
{
  return api.auth_get('groups/'+id+'/users/', {after: after});
}

export function change_group_permissions(id, new_perm, ids)
{
  return api.auth_put('groups/'+id+'/permissions/', {perm_code: new_perm, ids});
}

export function create_group(name)
{
  return api.auth_post('groups/', {name: name});
}

export function list_groups(after)
{
  return api.auth_get('groups/', {after: after});
}

export function list_space_users(space_id, after)
{
  return api.auth_get('room/permissions/'+space_id, {of:'users', after: after});
}

export function list_space_groups(space_id, after)
{
  return api.auth_get('room/permissions/'+space_id, {of:'groups', after: after});
}

export function group_space_list(id) {
  return api.auth_get('room/group_permissions/', {id});
}

export function assign_spaces_to_group(groupid, perm_code, spaceids)
{
  let data = {groupid, perm_code, spaceids};
  return api.auth_put('room/group_permissions/', data);
}

export function change_permissions(spaceid, of, perm_code, ids)
{
  let data = {of, perm_code, ids};
  return api.auth_put('room/permissions/'+spaceid, data);
}

export function respond_to_invitation(id, decline_text)
{
  let data = {};
  if(decline_text !== undefined)
  {
    data.accepted = false;
    data.invite_decline_text = decline_text;
  }
  else
  {
    data.accepted = true;
  }

  return api.auth_put('invites/'+id+'/accept/', data);
}

export function received_invitations(after)
{
  return api.auth_get('invites/list/to/', {after: after});
}

export function sent_invitations(after)
{
  return api.auth_get('invites/list/from/', {after: after});
}

export function create_invite(object_type, objectid, perm, invite_type, invitee, message)
{
  let data = {
    object_type: object_type,
    object_id: objectid,
    granted_permission_code: perm,
    invite_text: message,
  };

  if(invite_type === 'user')
    data = {...data, invite_type: 'user', username: invitee };
  else if(invite_type === 'group')
    data = {...data, invite_type: 'group', groupname: invitee };
  else
    throw new Error('invite_type must be user or group, given: ' + invite_type);

  return api.auth_post('invites/', data);
}
