let overlayActive = null;
let current_escape_delegate;

export function holdOverlay(escape_delegate)
{
  overlayActive = true;
  current_escape_delegate = escape_delegate;
}

export function delegateEscape()
{
  if(current_escape_delegate)
    current_escape_delegate();
}

export function releaseOverlay()
{
  current_escape_delegate = undefined;
  overlayActive = false;
}

export function checkOverlay()
{
  return !!overlayActive;
}
