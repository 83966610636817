import * as api from './api.js';
import * as msg from '../messages.js';
import * as login from './login.js';
import * as space_module from '../space.js';
import * as input from '../input.js';
import * as main from '../main.js';

let space = null;
export let _spaceStore = window.localStorage;

export function getSpaceId() {
  return space.id;
}

function setSpaceName(spacename) {
  _spaceStore.setItem('room_name', spacename);
}
export function getSpaceName() {
  // null if not present
  if(space !== null) {
    return space.name;
  }
  else {
    return "";
  }
}

export function getSpace() {
  return space;
}

// user id is anon by default
export async function loadByName(
  user_name,
  space_name=getSpaceName()
) {
  space_module.addLoading();
  input.resetMovement();
  setSpaceName(space_name);
  let url = 'room/by_name/';
  url += user_name + '/';
  url += space_name + '/';
  function spaceError(e) {
    console.error(e);
    space_module.popLoading()
    return false;
  }
  function spaceSuccess(j_space) {
    space = j_space;
    space.fragment_set.forEach(function(frag) {
      if(frag.files) {
        let hashes = [];
        frag.files.forEach(function(file) {
          hashes.push(file.hash);
        });
        frag.filehashes = hashes;
      }
      frag.persistent = true;
    });
    console.log("space loaded: " + space.name);
    space_module.popLoading()
    return true;
  }
  try {
    let res = await api.auth_get(url);
    if(res.res.ok) {
      return spaceSuccess(res.json);
    }
    else {
      return spaceError(res);
    }
  }
  catch(e) {
    return spaceError(e);
  }
}

export async function create(name, anon) {
  let username = login.getCreds().username;
  let id = login.getCreds().id;
  let res = await api.auth_post('room/create/', {
    name: name,
    anonymous_access: anon,
    created_by: id,
    admin: id
  });
  if(res.res.ok) {
    return { ok: true };
  }
  else {
    return { ok: false, msg: res.res.status + ': ' + res.res.statusText };
  }
}

export async function public_space_list() {
  let res = await api.get('room/public_list/');
  if(!res.res || !res.res.ok) {
    console.error("Problem while loading list of spaces. Response object:");
    console.error(res);
    msg.error("Sorry, there was an error loading your list spaces");
    return [];
  }
  else {
    console.log("Got /room/public_list/");
  }
  return res.json;
}

export async function space_list(admin_only) {
  let res = await api.auth_get('room/list/', admin_only ? {admin_only: true} : {});
  if(!res.res || !res.res.ok) {
    console.error("Problem while loading list of spaces. Response object:");
    console.error(res);
    msg.error("Sorry, there was an error loading your list spaces");
    return [];
  }
  else {
    console.log("Got /room/list/");
  }
  return res.json;
}

export async function set_access(users_to_add, can_read, can_write, can_delete) {
  let res = await api.auth_post('room/set_access/', {
    room_id: getSpaceId(),
    users_to_add: users_to_add,
    can_read: can_read,
    can_write: can_write,
    can_delete: can_delete
  });
  if(!res.res || !res.res.ok) {
  }
  else {
  }
}

export async function set_access_for_space(
  user_name, space_name,
  users_to_add,
  can_read, can_write, can_delete
) {
  let res = await api.auth_post('room/set_access_for_room/', {
    user_name: user_name,
    room_name: space_name,
    users_to_add: users_to_add,
    can_read: can_read,
    can_write: can_write,
    can_delete: can_delete
  });
  if(!res.res || !res.res.ok) {
  }
  else {
  }
}

export async function set_options(space_id, opts) {
  return api.auth_post('room/set_options/', {
    room_id: space_id,
    anonymous_access: opts.anonymous_access,
    listed: opts.listed,
    grounded: opts.grounded,
  });
}
